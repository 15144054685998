import Axios from "axios";
import AnalysisAction from "MetaComponent/actions/Analysis";
import { decode } from "@msgpack/msgpack";
import GenericApi from "Api";

/**
 * A class created to prepare data and perform http operations
 * related to a meta component design's analysis.
 */
export default class AnalysisApi {
  /**
   * it generates an analysis job url
   * @param {Number} jobId - the analysis job id
   */
  static getAnalysisJobUrl = (jobId = "") =>
    `${GenericApi.getBaseUrl()}/analysisjobs/${jobId}`;

  /**
   * it generates an url to get the progress of an analysis job
   */
  static getAnalysisJobProgressUrl = analysisJobId =>
    `${this.getAnalysisJobUrl(analysisJobId)}/progress`;

  /**
   * it generates a url to export the zipped raw data of an analysis
   * @param {Number} id - the analysis id
   * @returns {String} the url
   */
  static exportAnalysisUrl = id => `${this.getAnalysisJobUrl(id)}/data`;

  /**
   * it generates an url to get the result of an analysis job with
   * the possibility to add arguments to the url
   */
  static getAnalysisJobResultUrl = (analysisJobId, variableValues) => {
    const resultsUrl = `${this.getAnalysisJobUrl(analysisJobId)}/results`;
    const args = Object.keys(variableValues).map(
      key => `${key}=${variableValues[key]}`
    );
    if (!args.length) {
      return resultsUrl;
    }
    return `${resultsUrl}?${args.join("&")}`;
  };

  /**
   * it generates an url to get the cross section of an analysis job with
   * the possibility to add arguments to the url
   */
  static getAnalysisCrossSectionUrl = (
    analysisJobId,
    variableValues,
    cross_section_wf,
    cross_section_orientation,
    cross_section_index,
    nfwf_cross_section_type
  ) => {
    const resultsUrl = `${this.getAnalysisJobUrl(analysisJobId)}/cross_section`;
    const args = Object.keys(variableValues).map(
      key => `${key}=${variableValues[key]}`
    );
    // add args
    args.push(
      `cross_section_wf=${cross_section_wf}`,
      `orientation=${cross_section_orientation}`,
      `index=${cross_section_index}`,
      `nfwf_cross_section_type=${nfwf_cross_section_type}`
    );
    if (!args.length) {
      return resultsUrl;
    }
    return `${resultsUrl}?${args.join("&")}`;
  };

  /**
   * it makes a request to start an analysis
   * @param {Number} analysisJob - the design job id
   * @returns {Function} a function that receives and uses the redux dispatcher
   */
  static start = analysisJob => {
    return dispatch => {
      return GenericApi.runApiCall(
        Axios.post(AnalysisApi.getAnalysisJobUrl(), analysisJob),
        "Failed to start analysis job"
      )
        .then(response => response.data)
        .then(analysisJob => {
          dispatch(AnalysisAction.upsertJobs([analysisJob]));
          return analysisJob.id;
        });
    };
  };

  /**
   * it makes a request to restart an analysis
   * @param {Number} analysisJobId - the design job id
   * @param {Object} properties - the properties that will be changed
   * @returns {Function} a function that receives and uses the redux dispatcher
   */
  static restart = (analysisJobId, properties) => {
    return dispatch => {
      return GenericApi.runApiCall(
        Axios.put(AnalysisApi.getAnalysisJobUrl(analysisJobId), properties),
        "Failed to update analysis job"
      )
        .then(response => response.data)
        .then(analysisJob =>
          dispatch(AnalysisAction.upsertJobs([analysisJob]))
        );
    };
  };

  /**
   * it makes a request to the analysis jobs endpoint to get an specific analysis or all
   * @returns {Function} a function that receives and uses the redux dispatcher
   */
  static fetchAnalysisJobs = (jobId = "") => {
    return dispatch => {
      const url = AnalysisApi.getAnalysisJobUrl(jobId);
      return Axios.get(url)
        .then(response => response.data)
        .then(analysisJobs => {
          dispatch(AnalysisAction.set(analysisJobs));
        });
    };
  };

  /**
   * it makes a request to the job progress backend endpoint
   * @param {Number} analysisJobId - the analysis job id
   * @returns {Promise} the response
   */
  static getAnalysisJobProgress = analysisJobId => dispatch => {
    const url = AnalysisApi.getAnalysisJobProgressUrl(analysisJobId);
    return GenericApi.runApiCall(Axios.get(url), "Analysis does not progress.")
      .then(res => res.data)
      .then(jobProgress => {
        dispatch(AnalysisAction.upsertJobs([jobProgress]));
        return jobProgress;
      });
  };

  static getSimpleAnalysisJobProgress = analysisJobId => {
    const url = AnalysisApi.getAnalysisJobProgressUrl(analysisJobId);
    return GenericApi.runApiCall(Axios.get(url), "Analysis does not progress.");
  };

  static getAnalysisJobResult = (analysisJobId, variableValues = {}) => {
    const url = AnalysisApi.getAnalysisJobResultUrl(
        analysisJobId,
        variableValues
      ),
      config = {
        responseType: "blob"
      };
    return Axios.get(url, config)
      .then(res => res.data.arrayBuffer())
      .then(arrayBuffer => decode(arrayBuffer))
      .then(wavefront => {
        return wavefront;
      });
  };

  static getAnalysisCrossSectionData = (
    analysisJobId,
    variableValues = {},
    cross_section_wf,
    cross_section_orientation,
    cross_section_index,
    nfwf_cross_section_type
  ) => {
    if (cross_section_orientation != null && cross_section_index != null) {
      const url = AnalysisApi.getAnalysisCrossSectionUrl(
        analysisJobId,
        variableValues,
        cross_section_wf,
        cross_section_orientation,
        cross_section_index,
        nfwf_cross_section_type
      );
      return GenericApi.runApiCall(
        Axios.get(url),
        "Seems to be a problem, fetching cross section of this analysis."
      );
    }
  };

  /**
   * it makes a get request to the export the zipped raw data of an analysis
   * @param {Number} id - the material id
   * @returns {Promise} - the promise response
   */
  static exportAnalysis = id =>
    GenericApi.runApiCall(
      Axios({
        url: this.exportAnalysisUrl(id),
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-Type": "application/octet-stream"
        }
      }).then(res => res.data),
      "Failed to export analysis zip file."
    );

  /**
   * @param {Number} jobId - the id of the analysis job
   * @returns {Function} a function that receives the redux dispatcher
   */
  static deleteAnalysisJob = jobId => dispatch => {
    const url = this.getAnalysisJobUrl(jobId);
    return Axios.delete(url).then(() =>
      dispatch(AnalysisAction.deleteJobs([jobId]))
    );
  };

  /**
   * @param {Number[]} jobsIds - the id of the analysis jobs
   * @returns {Function} a function that receives the redux dispatcher
   */
  static deleteAnalysisJobs = jobIds => dispatch => {
    const url = this.getAnalysisJobUrl("");
    return Axios.delete(url, { data: jobIds }).then(() =>
      dispatch(AnalysisAction.deleteJobs(jobIds))
    );
  };

  /**
   * it stops a running analysis job
   * @param {Number} jobId - the job id
   */
  static stopAnalysisJob = jobId => {
    const url = `${this.getAnalysisJobUrl(jobId)}/stop`;
    return Axios.post(url);
  };
}
