import React, { PureComponent } from "react";
import IncidentLightsTable from "./components/IncidentLightsTable";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import {
  withStyles,
  TextField,
  Input,
  Paper,
  Grid,
  Typography
} from "@material-ui/core";
import IncidentLightIllustration from "MetaCell/containers/IncidentLightCanvas/components/IncidentLightIllustration";
import OptimizerParametersTable from "./components/OptimizerParametersTable";
import OutputSettingsTable from "./components/OutputSettingsTable";
import { default as image } from "MetaCell/containers/IncidentLightCanvas/MetaStructures.txt";

const styles = theme => ({
  basicPaper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  textField: {
    marginBottom: 5
  },
  title: {
    float: "left"
  }
});

export class SimulationTargetDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      focusedIncidentLight: null
    };
  }

  focusOnIncidentLight = id => {
    const { simulationIncidentLightSettings } = this.props;
    const focusedIncidentLight = simulationIncidentLightSettings.find(
      incidentLight => incidentLight.id == id
    );
    this.setState({
      focusedIncidentLight: focusedIncidentLight
    });
  };

  saveIncidentLightData = data => {
    const { saveIncidentLight } = this.props;
    return new Promise((resolve, reject) => {
      saveIncidentLight(data)
        .then(() => {
          this.setState({
            focusedIncidentLight: data
          });
          resolve();
        })
        .catch(error => reject());
    });
  };

  saveTargetOptimizerConfiguration = configuration => {
    const { selectedSimulationTarget, onSimulationTargetUpdate } = this.props;
    const updatedTarget = {
      ...selectedSimulationTarget,
      optimizer_configuration: configuration
    };
    return onSimulationTargetUpdate(updatedTarget);
  };

  getIncidentLightOutputSettings = incidentLightId => {
    if (incidentLightId === null) {
      return [];
    }
    const { outputSettings } = this.props;
    return Object.values(outputSettings.byId).filter(
      outputSetting => outputSetting.incident_light === incidentLightId
    );
  };

  createOutputSetting = newData => {
    const { createOutputSetting } = this.props;
    newData.incident_light = this.state.focusedIncidentLight.id;
    return createOutputSetting(newData);
  };

  render() {
    const {
      classes,
      simulationIncidentLightSettings,
      selectedSimulationTarget,
      saveIncidentLight,
      fetchIncidentLightsAction,
      onSimulationTargetUpdate,
      targetOptimizerSettings,
      updateOutputSetting,
      deleteOutputSetting,
      fetchOutputSettingsAction
    } = this.props;
    const { focusedIncidentLight } = this.state;
    return (
      <>
        <Paper className={classes.basicPaper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h6" component="h6">
                {`${selectedSimulationTarget.name} details`}
              </Typography>
            </Grid>
            {selectedSimulationTarget &&
              selectedSimulationTarget.optimizer != null && (
                <Grid item xs={12}>
                  <OptimizerParametersTable
                    optimizerParameters={targetOptimizerSettings}
                    optimizerConfiguration={
                      selectedSimulationTarget.optimizer_configuration
                    }
                    onSaveOptimizerConfiguration={
                      this.saveTargetOptimizerConfiguration
                    }
                  />
                </Grid>
              )}
            <Grid item xs={12}>
              <IncidentLightsTable
                incidentLights={simulationIncidentLightSettings}
                focusOnIncidentLight={this.focusOnIncidentLight}
                saveIncidentLight={this.saveIncidentLightData}
                fetchIncidentLightsAction={fetchIncidentLightsAction}
              />
            </Grid>
            {focusedIncidentLight && (
              <Grid item xs={12} container>
                <Grid item xs style={{ padding: 20 }}>
                  <div style={{ margin: "auto", maxWidth: 250 }}>
                    <img
                      src={image}
                      alt={"DefinitionOfAngles"}
                      style={{ maxWidth: 250 }}
                    />
                  </div>
                </Grid>
                <Grid item xs style={{ padding: 20 }}>
                  <IncidentLightIllustration
                    incidentLight={focusedIncidentLight}
                  />
                </Grid>
              </Grid>
            )}
            {focusedIncidentLight && (
              <Grid item xs={12}>
                <OutputSettingsTable
                  outputSettings={this.getIncidentLightOutputSettings(
                    focusedIncidentLight.id
                  )}
                  updateOutputSetting={updateOutputSetting}
                  fetchOutputSettingsAction={fetchOutputSettingsAction}
                  deleteOutputSetting={deleteOutputSetting}
                  createOutputSetting={this.createOutputSetting}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </>
    );
  }
}

export default withErrorBoundary(withStyles(styles)(SimulationTargetDetails));
