import React, { PureComponent } from "react";
import ModeAnalysisJobPlot from "MetaCell/containers/ModeAnalysisCanvas/components/ModeAnalysisJobPlot/ModeAnalysisJobPlot";
import { Paper, Typography } from "@material-ui/core";
import PlotActions from "components/PlotActions/PlotActions";
import SimulationResultHelper from "MetaCell/helper/SimulationResult";
import HelperUtils from "MetaCell/helper/HelperUtils";

export class ModeAnalysisJobPlots extends PureComponent {
  getSweptVariables() {
    const { configuration } = this.props;
    if (configuration) {
      return configuration.swept_variables.map(({ name, values }) => ({
        variableName: name,
        values
      }));
    }
    return [];
  }

  getSortedLayers() {
    const { configuration } = this.props;
    if (configuration) {
      const sortedLayers = configuration.structure.sort(
        (a, b) => a.correct_order - b.correct_order
      );
      return sortedLayers.map(
        ({ name, thickness, discretized, used_materials, correct_order }) => ({
          name,
          thickness,
          discretized: discretized.length ? JSON.stringify(discretized) : null,
          used_materials,
          index: correct_order
        })
      );
    }
    return [];
  }

  getProbePosition = sweepIndex => {
    const { configuration } = this.props;
    if (configuration) {
      const position = configuration.probe_parameters.probe_position;
      if (isNaN(position)) {
        const varAssignmentOrMathExpression = position.substring(1);
        const varName = HelperUtils.extractVariableName(
          varAssignmentOrMathExpression,
          configuration.swept_variables.map(({ name }) => name)
        );
        const variable = configuration.swept_variables.find(
          ({ name }) => name === varName
        );
        const sweepPointValue = variable.values[sweepIndex];
        return HelperUtils.resolveMathExpression(
          varAssignmentOrMathExpression,
          { [varName]: sweepPointValue }
        );
      }
      return position;
    }
    return 0;
  };

  getLayerColors = layerIndex => {
    const { configuration } = this.props;
    if (configuration) {
      const sortedLayers = this.getSortedLayers();
      const layer = sortedLayers[layerIndex];
      return layer.used_materials.map(
        ({ name }) => configuration.materials[name].color
      );
    }
    return [];
  };

  getModeAnalysisCSV = (plot, projectName, simulationName, username) => {
    const { configuration } = this.props;
    let probeAlias =
      configuration?.probe_parameters?.probe_name || "Probe Position";
    return SimulationResultHelper.getModeAnalysisCSVData(
      plot,
      projectName,
      simulationName,
      username,
      probeAlias
    );
  };

  getProbeAlias() {
    const probe_position = this.props.configuration?.probe_parameters
      ?.probe_position;
    if (probe_position && String(probe_position).includes("=")) {
      return probe_position.split("=")[1];
    }
    return null;
  }

  getHeader() {
    const { configuration, plotboxes, selectedJob } = this.props;
    if (
      !selectedJob ||
      (selectedJob &&
        (selectedJob.status === "FAILED" || selectedJob.status === "STOPPED"))
    ) {
      return "";
    }
    const sweptVariables = configuration.swept_variables;
    const singleValueVariables = sweptVariables.filter(
      ({ values }) => values.length === 1
    );
    if (singleValueVariables.length) {
      const formattedVariableValues = singleValueVariables.map(
        ({ name, values }) => ` ${name} = ${values[0]}`
      );
      const sweepPointString = `for sweep point:${formattedVariableValues.join(
        ";"
      )}.`;
      if (plotboxes.length) {
        return `Plots ${sweepPointString}`;
      }
      return `Simulation completed ${sweepPointString} Once you add a plot it will be shown here.`;
    }
    return plotboxes.length
      ? ""
      : `Simulation completed. Once you add a plot it will be shown here.`;
  }

  render = () => {
    const { plotboxes, configuration } = this.props;
    if (configuration && plotboxes) {
      return (
        <>
          <Typography
            style={{
              textAlign: "center",
              paddingTop: 30
            }}
            variant="subtitle1"
            component="h4"
          >
            {this.getHeader()}
          </Typography>
          {plotboxes.map(plot => (
            <div key={plot.id}>
              <Paper
                style={{
                  padding: 5,
                  paddingBottom: 10,
                  marginBottom: 5,
                  overflow: "hidden"
                }}
              >
                <PlotActions
                  plotboxes={plotboxes}
                  id={plot.id}
                  deletePlotbox={this.props.deletePlotbox}
                  getCSV={this.getModeAnalysisCSV}
                  username={this.props.username}
                  projectName={this.props.projectName}
                  simulationName={this.props.simulationName}
                />
                <ModeAnalysisJobPlot
                  data={plot}
                  showErrorsAndWarnings={this.props.showErrorsAndWarnings}
                  onRangeValueChange={this.props.onRangeValueChange}
                  onPlotBoxChange={this.props.onPlotBoxChange}
                  sweptVariables={this.getSweptVariables()}
                  sortedLayers={this.getSortedLayers()}
                  getProbePosition={this.getProbePosition}
                  probeAlias={this.getProbeAlias()}
                  getLayerColors={this.getLayerColors}
                  handleCrossSection={this.props.handleCrossSection}
                />
              </Paper>
            </div>
          ))}
        </>
      );
    } else {
      return null;
    }
  };
}

export default ModeAnalysisJobPlots;
