import Axios from "axios";
import MaterialAction from "MetaCell/actions/Material";
import GenericApi from "Api";

/**
 * A class created to prepare data and perform http operations
 * related to the material entity.
 * @author Akira Kotsugai
 */
export default class MaterialApi {
  /**
   * it generates the url to the material api
   * @param {Number} id - the material id if there is one
   * @return {String} - the generated material endpoint url
   */
  static materialsUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/materials/${id}`;

  /**
   * it generates a url to export a material as a json
   * @param {Number} id - the material id
   * @returns {String} the url
   */
  static exportMaterialUrl = id => `${MaterialApi.materialsUrl(id)}/export`;

  /**
   * it generates the url to the material api to manipulate the given material
   *@param id - the material's id
   */
  static updateMaterialsUrl = id =>
    `${GenericApi.getBaseUrl()}/materials/${id}`;

  /**
   * it can fetch all the existing materials in the api's database
   * @return {Function} a function that receive a dispatcher to redux
   */
  static fetchMaterials = (id = "") => {
    return dispatch => {
      const url = MaterialApi.materialsUrl(id);
      return Axios.get(url)
        .then(res => {
          const materials = res.data;
          const onlyOneMaterial = id !== "";
          if (onlyOneMaterial) {
            dispatch(MaterialAction.upsertMaterials([materials]));
          } else {
            dispatch(MaterialAction.upsertMaterials(materials));
          }
          return materials;
        })
        .catch(error => {
          console.log("Materials error from API: ", error.message);
        });
    };
  };

  /**
   * it makes a get request to the export material endpoint and returns a promISE
   * @param {Number} id - the material id
   * @returns {Promise} - the promise response
   */
  static exportMaterial = id =>
    GenericApi.runApiCall(
      Axios({
        url: MaterialApi.exportMaterialUrl(id),
        method: "GET",
        responseType: "blob"
      }),
      "Failed to export material file."
    );

  /**
   * it makes a request to create a material in the database
   * @param {Object} material - the material object
   * @returns {Function} a function that receives the redux dispatcher
   */
  static createMaterial = material => {
    return dispatch => {
      return Axios.post(MaterialApi.materialsUrl(), material)
        .then(response => response.data)
        .then(createdMaterial =>
          dispatch(MaterialAction.upsertMaterials([createdMaterial]))
        )
        .catch(error =>
          console.log("Materials error from API: ", error.message)
        );
    };
  };

  /**
   * it makes a request to update a material in the database
   * @param {Object} material - the material object
   * @returns {Function} a function that receives the redux dispatcher
   */
  static updateMaterial = material => {
    return dispatch => {
      const { id, ...rest } = material;
      return Axios.patch(MaterialApi.materialsUrl(id), rest)
        .then(response => response.data)
        .then(updatedMaterial =>
          dispatch(MaterialAction.upsertMaterials([updatedMaterial]))
        );
    };
  };

  /**
   * it makes a request to delete a material from the database
   * @param {Number} materialId - the material id
   * @returns {Function} a function that receives the redux dispatcher
   */
  static deleteMaterial = materialId => {
    return dispatch => {
      return Axios.delete(MaterialApi.materialsUrl(materialId)).then(() =>
        dispatch(MaterialAction.deleteMaterial(materialId))
      );
    };
  };
}
