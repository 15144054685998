import React, { PureComponent } from "react";
import DirectoryExplorer from "components/DirectoryExplorer/DirectoryExplorer";
import { connect } from "react-redux";
import Api from "MetaCell/api/DirectoryExplorer";
import UserApi from "BaseApp/api/User";
import Selector from "MetaCell/selectors/DirectoryExplorer";
import Action from "MetaCell/actions/DirectoryExplorer";
import UserSelector from "BaseApp/selectors/User";
import { itemType } from "components/DirectoryExplorer/components/ExplorerTree/ExplorerTree";
import SimulationApi from "MetaCell/api/Simulation";
import GenericApi from "Api";

/**
 * a component to display meta cell projects and simulations.
 * it connects to redux, and manage all the data that is passed
 * to the generic directory explorer
 * @author Akira Kotsugai
 */
export class Explorer extends PureComponent {
  /**
   * it is supposed to be passed all the way down to simulations to allow simulation items
   * to open the corresponding simulation, it also saves the open simulation as the last viewed
   * @callback
   * @param {Number} id - the simulation id
   */
  openSimulation = id => {
    this.props.openSimulation(id);
    this.props.postUserSettings({ lastSimulation: id });
  };

  /**
   * it fetches projects and simulation.
   * it is supposed to be passed to the directory explorer
   * @callback
   */
  fetchData = () => {
    const { fetchProjectsAndSimulationsAction, userSettings } = this.props;
    fetchProjectsAndSimulationsAction(userSettings.lastSimulation);
  };

  /**
   * it fetches projects and simulation.
   * it is supposed to be used as the import success callback in the directory explorer
   * @callback
   */
  onImportSuccess = () => {
    this.props.fetchProjectsAndSimulationsAction();
  };

  /**
   * it generates a url to import a simulation based on the selected item
   * @return {String} the url
   */
  getImportUrl = () => {
    const { selectedItem, simulations } = this.props;
    let projectId = 0;
    if (selectedItem && selectedItem.type === itemType.PROJECT) {
      projectId = selectedItem.id;
    } else if (selectedItem && selectedItem.type === itemType.SIMULATION) {
      const simulation = simulations.byId[selectedItem.id];
      projectId = simulation.project;
    }
    return `${GenericApi.getBaseUrl()}/projects/${projectId}/import_simulation`;
  };

  /**
   * it updates the simulation name
   * @param {Number} id - the simulation to be updated
   * @param {String} name - the new name
   * @callback
   */
  updateSimulation = (id, name) => {
    this.props.updateSimulation(id, { name });
  };

  /**
   * it updates the project name
   * @param {Number} id - the project to be updated
   * @param {String} name - the new name
   * @callback
   */
  updateProject = (id, name) => {
    this.props.updateProject(id, { name });
  };

  render() {
    const {
      saveEditings,
      addProjectItem,
      addSimulationItem,
      form,
      selectedItem,
      projects,
      simulations,
      editItem,
      updateForm,
      openSimulationId,
      updateSelectedItem,
      updateEditingValue,
      editingItem,
      deleteProjectsAndSimulations
    } = this.props;
    return (
      <DirectoryExplorer
        saveEditings={saveEditings}
        addProjectItem={addProjectItem}
        addSimulationItem={addSimulationItem}
        form={form}
        selectedItem={selectedItem}
        projects={projects}
        simulations={simulations}
        grouperType={"project"}
        finalItemType={"simulation"}
        deleteProjectsAndSimulations={deleteProjectsAndSimulations}
        editItem={editItem}
        openSimulation={this.openSimulation}
        updateForm={updateForm}
        fetchData={this.fetchData}
        getImportUrl={this.getImportUrl}
        onImportSuccess={this.onImportSuccess}
        openSimulationId={openSimulationId}
        exportSimulation={SimulationApi.export}
        updateSimulation={this.updateSimulation}
        updateProject={this.updateProject}
        updateSelectedItem={updateSelectedItem}
        updateEditingValue={updateEditingValue}
        editingItem={editingItem}
        selectedItem={selectedItem}
      />
    );
  }
}

const mapStateToProps = state => ({
  projects: Selector.getProjects(state),
  simulations: Selector.getSimulations(state),
  selectedItem: Selector.getSelectedItem(state),
  form: Selector.getForm(state),
  userSettings: UserSelector.getUserSettings(state),
  openSimulationId: Selector.getSimulationOpenId(state),
  selectedItem: Selector.getSelectedItem(state),
  editingItem: Selector.getEditingItem(state)
});

const mapDispatchToProps = dispatch => {
  return {
    addProjectItem: (item, selectedItem) =>
      dispatch(Api.addProject(item, selectedItem)),
    addSimulationItem: (item, selectedItem) =>
      dispatch(Api.addSimulation(item, selectedItem)),
    editItem: id => dispatch(Action.editItem(id)),
    openSimulation: simulationId =>
      dispatch(Action.openSimulation(simulationId)),
    postUserSettings: data => dispatch(UserApi.postUserSettings(data)),
    updateForm: value => dispatch(Action.updateForm(value)),
    fetchProjectsAndSimulationsAction: simulationOpenId =>
      dispatch(Api.fetch(simulationOpenId)),
    updateSimulation: (id, properties) =>
      dispatch(Api.updateSimulation(id, properties)),
    updateProject: (id, properties) =>
      dispatch(Api.updateProject(id, properties)),
    updateSelectedItem: itemType =>
      dispatch(Action.updateSelectedItem(itemType)),
    updateEditingValue: value => dispatch(Action.updateEditingValue(value)),
    deleteProjectsAndSimulations: items =>
      dispatch(Api.deleteProjectsAndSimulations(items))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Explorer);
