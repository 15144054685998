import React, { Component, Fragment } from "react";
import debounce from "lodash.debounce";
import {
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Grid
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import ContainerDimensions from "react-container-dimensions";
import { PlotboxFormDrilldowns } from "./PlotboxFormDrilldowns/PlotboxFormDrilldowns";
import Helper from "MetaCell/helper/Plotbox";
import SimulationAction from "MetaCell/actions/Simulation";
import { connect } from "react-redux";
import SimulationSelector from "MetaCell/selectors/Simulation";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import Slider from "components/Slider/Slider";
import Simulation3DPlot from "components/Simulation3DPlot/Simulation3DPlot";
import NoDataPlot from "components/NoDataPlot/NoDataPlot";
import PlotMenu from "components/PlotMenu/PlotMenu";
import LinePlot from "components/LinePlot/LinePlot";
import HelperUtils from "MetaCell/helper/HelperUtils";
import ScatterPlot from "../../../../components/ScatterPlot/ScatterPlot";

const styles = {
  bottom: {
    paddingLeft: 70,
    paddingRight: 20
  },
  option: {
    marginRight: "14px !important",
    marginBottom: "20px !important",
    marginTop: "14px !important"
  },
  options: {
    display: "flex",
    justifyContent: "center"
  },
  labels: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%"
  },
  plot: {
    position: "relative"
  },
  yLabelText: {
    transform: "rotate(-90deg)",
    textAnchor: "middle"
  },
  sliderLabel: {
    textAlign: "center",
    paddingBottom: 10
  },
  buttonWrapper: {
    position: "relative",
    marginTop: 10
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  emptyPlot: {
    display: "flex",
    color: "#a1a8b0",
    backgroundColor: "#e5e7eb",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6
  },
  plotButtons: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center"
  },
  tooltip: {
    fontSize: "16px !important"
  }
};

/**
 * @constant
 * @typedef {String[]} PlotOutputParam
 * the plot output parameter consists of multiple parameters. these are the types of parameters it consists of
 */
export const plotOutputParams = Object.freeze([
  "DIRECTION",
  "DIFF_ORDER",
  "POLARIZATION",
  "QUANTITY",
  "REPRESENTATION"
]);

/**
 * @constant
 * @typedef {Object} PlotOutputParamLabel
 * @property {String} DIRECTION - the label of the direction output param
 * @property {String} DIFF_ORDER - the label of the difraction order output param
 * @property {String} POLARIZATION - the label of the polarization output param
 * @property {String} QUANTITY - the label of the quantity output param
 * @property {String} REPRESENTATION - the label of the representation output param
 * @global
 * These are the labels of all output parameters.
 */
export const plotOutputParamLabels = Object.freeze({
  DIRECTION: "Direction",
  DIFF_ORDER: "Diffraction Order",
  POLARIZATION: "Polarization",
  QUANTITY: "Quantity",
  REPRESENTATION: "Representation"
});

/**
 * @constant
 * @typedef {Object} PlotOutputParamDescription
 * @property {String} DIRECTION - the direction param description
 * @property {String} DIFF_ORDER - the direction param description
 * @property {String} POLARIZATION - the direction param description
 * @property {String} QUANTITY - the direction param description
 * @property {String} REPRESENTATION - the direction param description
 * @global
 * These are the descriptions of all output parameters.
 */
export const plotOutputParamDescriptions = Object.freeze({
  DIRECTION:
    "Which direction should be plotted, reflected or Transmitted light",
  DIFF_ORDER:
    "Which diffraction order (x-direction, y-direction) should be plotted",
  POLARIZATION: "which polarization of the selected quantity should be plotted",
  QUANTITY: `field_coeff: amplitude coeficient of reflection transmission (complex)\u000d
    power_coeff: power coefficient of reflection or transmission\u000d
    E[x/y/z]:  electric field components\u000d
    H[x/y/z]: components of the magnetic field\u000d
    S[x/y/z]: components of the poynting vector;`,
  REPRESENTATION:
    "Display the real, imaginary part, amplitude or phase of complex numbers"
});

export const getOutputSettingsRules = () => {
  let outputSettingsRules = {};

  // Direction rules
  outputSettingsRules[plotOutputParamLabels.DIRECTION] = [
    {
      values: ["A"], // values for which we apply the rule
      fields: {},
      description: "Absorption summed over all directions and polarizations."
    }
  ];
  outputSettingsRules[plotOutputParamLabels.DIRECTION][0].fields[
    plotOutputParamLabels.DIFF_ORDER
  ] = null; // null will be "None" in the request body
  outputSettingsRules[plotOutputParamLabels.DIRECTION][0].fields[
    plotOutputParamLabels.POLARIZATION
  ] = null;
  outputSettingsRules[plotOutputParamLabels.DIRECTION][0].fields[
    plotOutputParamLabels.QUANTITY
  ] = null;
  outputSettingsRules[plotOutputParamLabels.DIRECTION][0].fields[
    plotOutputParamLabels.REPRESENTATION
  ] = null;

  // Diffraction Order rules
  outputSettingsRules[plotOutputParamLabels.DIFF_ORDER] = [
    {
      values: ["sum"],
      fields: {},
      description:
        "Only power coeffienct and poynting vector can be calulated when summing diffraction orders"
    }
  ];
  outputSettingsRules[plotOutputParamLabels.DIFF_ORDER][0].fields[
    plotOutputParamLabels.QUANTITY
  ] = ["power_coeff", "Sx", "Sy", "Sz"]; // we show only certain values for some selected fields
  outputSettingsRules[plotOutputParamLabels.DIFF_ORDER][0].fields[
    plotOutputParamLabels.REPRESENTATION
  ] = ["Abs", "Re"];

  // Polarization rules
  outputSettingsRules[plotOutputParamLabels.POLARIZATION] = [
    {
      values: ["Stokes1", "Stokes2", "Stokes3"],
      fields: {},
      description:
        "Quantity choice is already determined for Stokes parameters."
    },

    {
      values: ["total"],
      fields: {},
      description:
        "Only power coeffienct and poynting vector can be calulated for “total“ polarization"
    }
  ];
  outputSettingsRules[plotOutputParamLabels.POLARIZATION][0].fields[
    plotOutputParamLabels.QUANTITY
  ] = null;
  outputSettingsRules[plotOutputParamLabels.POLARIZATION][0].fields[
    plotOutputParamLabels.REPRESENTATION
  ] = ["Abs", "Re"];
  outputSettingsRules[plotOutputParamLabels.POLARIZATION][0].fields[
    plotOutputParamLabels.DIFF_ORDER
  ] = [["sum"]]; // show all values except "sum"
  outputSettingsRules[plotOutputParamLabels.POLARIZATION][1].fields[
    plotOutputParamLabels.REPRESENTATION
  ] = ["Abs", "Re"];
  outputSettingsRules[plotOutputParamLabels.POLARIZATION][1].fields[
    plotOutputParamLabels.QUANTITY
  ] = ["power_coeff", "Sx", "Sy", "Sz"];

  // Quantity rules
  outputSettingsRules[plotOutputParamLabels.QUANTITY] = [
    {
      values: ["power_coeff"],
      fields: {},
      description: "Power coefficient is is real valued"
    },
    {
      values: [
        "field_coeff",
        "Sx",
        "Sy",
        "Sz",
        "Hx",
        "Hy",
        "Hz",
        "Ex",
        "Ey",
        "Ez"
      ],
      fields: {},
      description: plotOutputParamDescriptions[plotOutputParamLabels.QUANTITY]
    }
  ];
  outputSettingsRules[plotOutputParamLabels.QUANTITY][0].fields[
    plotOutputParamLabels.REPRESENTATION
  ] = ["Abs", "Re"];

  outputSettingsRules[plotOutputParamLabels.QUANTITY][1].fields[
    plotOutputParamLabels.REPRESENTATION
  ] = ["Abs", "Phase", "Re", "Im"];

  return outputSettingsRules;
};

export class Plotbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drilldowns: [],
      plotType: null,
      rangeValue: [],
      enableTooltip: false,
      init: false,
      tooltipError: "",
      scatterOptions: {
        x: "output",
        y: "output",
        color: null,
        plot_best: true
      }
    };
    this.onRangeChangeDebounced = debounce(this.onRangeChangeDebounced, 1000, {
      trailing: true
    });
  }

  componentDidMount = () => {
    const { data } = this.props;
    this.init(data);
  };

  setDrillDownsData = (drilldowns, params, options) => {
    let valueArr = [];
    if (!params) return;
    drilldowns.forEach(drilldown => {
      valueArr = (drilldown.value && drilldown.value.split("/")) || [];
      if (drilldown.isSweep) {
        drilldown.data = [];
        drilldown.data.push(
          options.map(val => ({
            text:
              val === "all" && !params[1][drilldown.name] ? "all/best" : val,
            isSelected: valueArr[0] && valueArr[0] === val ? true : false,
            next: val === "fixed" ? true : false,
            disabled: val === "fixed" && !params[1][drilldown.name]
          }))
        );
        drilldown.data.push(
          params[1][drilldown.name]?.map(val => ({
            text: val,
            isSelected:
              valueArr[1] && valueArr[1].toString() === val.toString()
                ? true
                : false
          }))
        );
      } else {
        const outputSelectionOptionsPerField = params[0],
          outputFieldIndex = Object.values(plotOutputParamLabels).findIndex(
            outputField => outputField === drilldown.label
          ),
          selectionOptions = outputSelectionOptionsPerField[outputFieldIndex];

        drilldown.data = [
          selectionOptions.map(option => ({
            text: option,
            isSelected: valueArr[0] && valueArr[0] === option ? true : false,
            next: false
          }))
        ];
      }
    });
  };

  filterOutputDrilldownValues = drilldowns => {
    const { params, sweptVarNames } = this.props;
    const { plotType, scatterOptions } = this.state;
    const options = this.getVariableAxisOptions(
      plotType,
      sweptVarNames,
      scatterOptions
    );
    const outputSettingsRules = getOutputSettingsRules();

    let newDrilldowns = JSON.parse(JSON.stringify(drilldowns));
    this.setDrillDownsData(newDrilldowns, params, options);

    // search for a matching rule
    let matchedRule = null;
    newDrilldowns.forEach(drilldown => {
      if (!drilldown.isSweep) {
        const rules = outputSettingsRules[drilldown.label];
        if (rules) {
          rules.forEach(rule => {
            if (!matchedRule && rule.values.indexOf(drilldown.value) !== -1) {
              matchedRule = Object.assign({}, rule);
              matchedRule["name"] = drilldown.name;
              drilldown.description = matchedRule.description;
            }
          });
        }
        //drilldown.disabled = false;
      }
    });

    // Update the drilldowns values
    if (matchedRule) {
      Object.keys(matchedRule.fields).forEach(key => {
        const fieldValues = matchedRule.fields[key];
        const drilldown = newDrilldowns.filter(
          drilldown => !drilldown.isSweep && drilldown.label === key
        )[0];
        const allMacthedDrilldowns = newDrilldowns.filter(
          drilldown =>
            !drilldown.isSweep &&
            drilldown.label === key &&
            drilldown.name === matchedRule.name
        );
        let newValues;
        if (drilldown && fieldValues === null) {
          drilldown.disabled = true; // disable the drilldown
        } else if (drilldown && typeof fieldValues[0] !== "string") {
          newValues = drilldown.data[0].filter(
            option => fieldValues[0].indexOf(option.text) < 0 // show only excluded values
          );
          drilldown.data[0] = newValues;
        } else if (drilldown) {
          newValues = drilldown.data[0].filter(
            option => fieldValues.indexOf(option.text) > -1 // show only included values
          );
          drilldown.data[0] = newValues;
        }

        // select the first dropdown option by default if an invalid setting is present
        if (
          drilldown &&
          !drilldown.data[0].filter(option => drilldown.value === option.text)
            .length
        ) {
          drilldown.value = drilldown.data[0][0].text;
          drilldown.data[0].map(option => (option.isSelected = false));
          drilldown.data[0][0].isSelected = true;
        }
      });
    }

    return newDrilldowns;
  };

  getVariableAxisOptions(plotType, sweptVars, scatterOptions) {
    let options = [];
    if (plotType === "SC")
      options = [this.isOptimizationSimulation() ? "all/best" : "all"];
    options.push("x");
    if (plotType === "SC" && scatterOptions.x !== "sweep") {
      options = [this.isOptimizationSimulation() ? "all/best" : "all"];
    }
    if (
      plotType === "3D" ||
      (plotType == "SC" && scatterOptions.y === "sweep")
    ) {
      options.push("y");
    }
    if (
      (plotType === "2D" && sweptVars.length > 1) ||
      (plotType === "3D" && sweptVars.length > 2) ||
      (plotType === "SC" && sweptVars.length > 0)
    ) {
      // options.push("slider", "fixed");
      if (plotType !== "SC") {
        options.push("slider");
      }
      options.push("fixed");
    }

    if (plotType === "SC" && scatterOptions?.color === "sweep") {
      options.push("color");
    }
    return options;
  }

  init = ({
    plotType,
    xName,
    yName,
    zName,
    sliderName,
    fixed,
    scatterOptions,
    colorVar
  }) => {
    const { params, configuration, sweptVarNames } = this.props,
      { polarization } = Array.isArray(configuration.incident_light)
        ? configuration.incident_light[0]
        : configuration.incident_light,
      drilldowns = [],
      options = this.getVariableAxisOptions(
        plotType,
        sweptVarNames,
        scatterOptions
      );
    let fixedObj = {};
    // Check if fixed property is null
    if (!fixed) {
      fixed = [];
    }
    // Create drilldowns for x, y, z & slider
    if (plotType === "2D") {
      drilldowns.push(
        ...Helper.createOutputDrillDowns("y", yName, polarization)
      );
    } else if (plotType === "3D") {
      drilldowns.push(
        ...Helper.createOutputDrillDowns("z", zName, polarization)
      );
    } else {
      drilldowns.push(
        ...Helper.createOutputDrillDowns(
          "output_x",
          scatterOptions.x === "output" ? xName : null,
          polarization
        )
      );
      drilldowns.push(
        ...Helper.createOutputDrillDowns(
          "output_y",
          scatterOptions.y === "output" ? yName : null,
          polarization
        )
      );
      drilldowns.push(
        ...Helper.createOutputDrillDowns(
          "output_color",
          scatterOptions.color === "output" ? zName : null,
          polarization
        )
      );
    }
    if (
      (xName && xName !== "" && plotType !== "SC") ||
      (plotType == "SC" && scatterOptions.x == "sweep")
    ) {
      drilldowns.push({
        name: xName,
        label: xName,
        value: "x",
        data: null,
        error: "",
        isSweep: true
      });
    }
    if (
      (plotType === "3D" && yName && yName !== "") ||
      (plotType == "SC" && scatterOptions.y == "sweep")
    ) {
      drilldowns.push({
        name: yName,
        label: yName,
        value: "y",
        data: null,
        error: "",
        isSweep: true
      });
    }
    if (sliderName && sliderName !== "") {
      sliderName.split(",").forEach(slider =>
        drilldowns.push({
          name: slider,
          label: slider,
          value: "slider",
          data: null,
          error: "",
          isSweep: true
        })
      );
    }
    // Create drilldowns for fixed values
    fixed.forEach(val => {
      const [name, value] = val.split("/");
      fixedObj[name] = value;
    });
    sweptVarNames
      .filter(
        val =>
          xName !== val && yName !== val && !sliderName.split(",").includes(val)
      )
      .forEach(name => {
        if (name === colorVar) {
          drilldowns.push({
            name: name,
            label: name,
            value: "color",
            data: null,
            error: "",
            isSweep: true
          });
        } else {
          drilldowns.push({
            name: name,
            label: name,
            value: !fixedObj[name]
              ? plotType === "SC"
                ? this.isOptimizationSimulation()
                  ? "all/best"
                  : "all"
                : ""
              : `fixed/${fixedObj[name]}`,
            data: null,
            error: "",
            isSweep: true
          });
        }
      });

    // Set drilldowns data
    this.setDrillDownsData(drilldowns, params, options);
    const notSweepDrilldowns = drilldowns.filter(({ isSweep }) => !isSweep);
    const sortedSweepDrilldowns = HelperUtils.sortByName(
      drilldowns.filter(({ isSweep }) => isSweep)
    );
    this.setState({
      plotType,
      scatterOptions,
      drilldowns: [...notSweepDrilldowns, ...sortedSweepDrilldowns],
      init: true,
      tooltipError: ""
    });
  };

  /**
   * it is passed all the way down to the drilldown components to be used on close.
   * it updates the drilldown state with the new drilldown values
   * @callback
   * @param {String} value - the drilldown value
   * @param {Object[]} data - the drilldown data
   * @param {Number} i - the index of the drilldown inside its group
   * @param {Boolean} isSweep - whether it belongs to the drilldown group of sweep variables
   */
  onDrilldownClose = (
    value,
    data,
    i,
    isSweep,
    scatter_output_direction = null
  ) => {
    const { drilldowns } = this.state,
      drilldownGroupStartingIndex = drilldowns.findIndex(drilldown => {
        if (scatter_output_direction) {
          return (
            drilldown.isSweep === isSweep &&
            drilldown.name === scatter_output_direction
          );
        } else {
          return drilldown.isSweep === isSweep;
        }
      }),
      drilldownIndex = i + drilldownGroupStartingIndex;
    drilldowns[drilldownIndex].value = value;
    drilldowns[drilldownIndex].data = data;
    // find next drilldown if exists, and check if it affects
    if (drilldowns[drilldownIndex + 1]) {
      const currentDrilldown = drilldowns[drilldownIndex];
      const opSettingRules = getOutputSettingsRules();
      const opRule = opSettingRules[currentDrilldown.label]?.find(opRule =>
        opRule.values?.includes(value)
      );
      let k = drilldownIndex;
      var nextDrilldown = null;
      while (k < drilldownIndex + 4 - i) {
        nextDrilldown = drilldowns[k + 1];
        if (
          opRule &&
          Object.keys(opRule.fields).includes(nextDrilldown.label)
        ) {
          if (
            !opRule.fields[nextDrilldown.label]?.includes(nextDrilldown.value)
          ) {
            nextDrilldown.value = opRule.fields[nextDrilldown.label]
              ? opRule.fields[nextDrilldown.label][0]
              : null;
          }
        }
        k += 1;
      }
    }
    this.setState({ drilldowns }, () => {
      this.setState({ drilldowns: this.validate() });
    });
  };

  validate = () => {
    const { drilldowns, plotType, scatterOptions } = this.state;
    const { configuration } = this.props;
    let arr = [],
      error = "",
      xCount = 0,
      yCount = 0,
      colorCount = 0,
      sliderCount = 0;
    drilldowns.forEach(drilldown => {
      if (drilldown.isSweep) {
        if (drilldown.value === "x") {
          if (
            plotType !== "SC" ||
            (plotType === "SC" && scatterOptions.x === "sweep")
          )
            xCount++;
        } else if (drilldown.value === "y") {
          if (
            plotType !== "SC" ||
            (plotType === "SC" && scatterOptions.y === "sweep")
          )
            yCount++;
        } else if (drilldown.value === "slider") {
          sliderCount++;
        } else if (drilldown.value === "color") {
          colorCount++;
        }
      }
    });
    let invalidSliders = [];
    if (sliderCount > 0) {
      invalidSliders = configuration?.swept_variables
        ?.filter(sweptVar => sweptVar.values.length < 2)
        .map(sweptVar => sweptVar.name);
    }
    drilldowns.forEach(drilldown => {
      error = "";
      if (drilldown.value === "") {
        // if (plotType !== "SC")
        error = "This field is required";
      } else if (drilldown.isSweep) {
        arr = drilldown.value ? drilldown.value.split("/") : [];
        if (arr[0] === "fixed" && !arr[1]) {
          error = "Select a fixed value";
        } else if (arr[0] === "x" && xCount > 1) {
          error = "x can be selected only once";
        } else if (arr[0] === "y" && yCount > 1) {
          error = "y can be selected only once";
        } else if (arr[0] === "slider" && sliderCount > 5) {
          error = "number of sliders cannot be more than 5";
        } else if (
          arr[0] === "slider" &&
          invalidSliders?.includes(drilldown.name)
        ) {
          error =
            "sweep variables with single values are not allowed as sliders";
        } else if (arr[0] === "color" && colorCount > 1) {
          error = "color can be selected only once";
        }
      }
      drilldown.error = error;
    });
    return drilldowns;
  };

  onUpdateButtonClick = () => {
    const { plotType, scatterOptions } = this.state,
      { drilldowns } = this.state;
    let x,
      y,
      color,
      tooltipError = "";
    // xDrillDownName = plotType === "SC" && scatterOptions.x === "output" ? "output_x" : "x",
    // yDrillDownName = plotType === "SC" && scatterOptions.y === "output" ? "output_y" : "y";
    if (plotType === "SC" && scatterOptions.x === "output")
      x = drilldowns.some(
        ({ isSweep, name }) => !isSweep && name === "output_x"
      );
    else x = drilldowns.some(({ isSweep, value }) => isSweep && value === "x");
    if (plotType === "3D" || plotType === "SC") {
      if (plotType === "SC" && scatterOptions.y === "output")
        y = drilldowns.some(
          ({ isSweep, name }) => !isSweep && name === "output_y"
        );
      else
        y = drilldowns.some(({ isSweep, value }) => isSweep && value === "y");
      if (!y && !x) {
        tooltipError = "x and y are required";
      } else if (!y) {
        tooltipError = "y is required";
      } else if (!x) {
        tooltipError = "x is required";
      }
    } else if (!x) {
      tooltipError = "x is required";
    }
    if (plotType === "SC" && scatterOptions.color === "sweep") {
      color = drilldowns.some(
        ({ isSweep, value }) => isSweep && value === "color"
      );
      if (!color) {
        tooltipError = "color is required";
      }
    }
    this.setState({ tooltipError, drilldowns: this.validate() }, this.update);
  };

  buildNewPlotParams = (
    plotType,
    drillDownX,
    drilldowns,
    sliderName,
    scatterOptions
  ) => {
    let matchedRuleX = null;
    let matchedRuleY = null;
    let matchedRuleZ = null;
    const outputSettingsRules = getOutputSettingsRules();
    const drillDownYName =
      plotType === "SC" && scatterOptions.y === "output" ? "output_y" : "y";
    const drillDownZName =
      plotType === "SC" && scatterOptions.color === "output"
        ? "output_color"
        : "z";
    const colorVar =
      plotType === "SC" && scatterOptions.color === "sweep"
        ? drilldowns.find(({ isSweep, value }) => isSweep && value === "color")
            ?.name
        : null;
    const newPlotParams = {
      plotType,
      scatterOptions,
      colorVar,
      // xName: (drillDownX && drillDownX.name) || "",
      xName:
        plotType === "SC" && scatterOptions.x === "output"
          ? drilldowns
              .filter(({ isSweep, name }) => !isSweep && name === "output_x")
              .map(xDrillDown => {
                const rules = outputSettingsRules[xDrillDown.label];
                if (rules) {
                  rules.forEach(rule => {
                    if (
                      !matchedRuleX &&
                      rule.values.indexOf(xDrillDown.value) !== -1
                    ) {
                      matchedRuleX = Object.assign({}, rule);
                      matchedRuleX.name = xDrillDown.name;
                    }
                  });
                }

                if (
                  matchedRuleX &&
                  matchedRuleX.fields[xDrillDown.label] === null
                ) {
                  return "None";
                } else {
                  return xDrillDown.value;
                }
              })
              .join("/")
          : (drillDownX && drillDownX.name) || "",
      yName:
        plotType === "2D" ||
        (plotType === "SC" && scatterOptions.y === "output")
          ? drilldowns
              .filter(
                ({ isSweep, name }) => !isSweep && name === drillDownYName
              )
              .map(yDrillDown => {
                const rules = outputSettingsRules[yDrillDown.label];
                if (rules) {
                  rules.forEach(rule => {
                    if (
                      !matchedRuleY &&
                      rule.values.indexOf(yDrillDown.value) !== -1
                    ) {
                      matchedRuleY = Object.assign({}, rule);
                    }
                  });
                }

                if (
                  matchedRuleY &&
                  matchedRuleY.fields[yDrillDown.label] === null
                ) {
                  return "None";
                } else {
                  return yDrillDown.value;
                }
              })
              .join("/")
          : drilldowns.find(({ isSweep, value }) => isSweep && value === "y")
              .name,
      zName:
        plotType === "3D" ||
        (plotType === "SC" && scatterOptions.color === "output")
          ? drilldowns
              .filter(
                ({ isSweep, name }) => !isSweep && name === drillDownZName
              )
              .map(zDrillDown => {
                const rules = outputSettingsRules[zDrillDown.label];
                if (rules) {
                  rules.forEach(rule => {
                    if (
                      !matchedRuleZ &&
                      rule.values.indexOf(zDrillDown.value) !== -1
                    ) {
                      matchedRuleZ = Object.assign({}, rule);
                    }
                  });
                }

                if (
                  matchedRuleZ &&
                  matchedRuleZ.fields[zDrillDown.label] === null
                ) {
                  return "None";
                } else {
                  return zDrillDown.value;
                }
              })
              .join("/")
          : plotType === "SC" && scatterOptions.color === "sweep"
          ? drilldowns.find(
              ({ isSweep, value }) => isSweep && value === "color"
            ).name
          : "",
      sliderName:
        (sliderName && sliderName.map(slider => slider.name).join(",")) || "",
      fixed: drilldowns
        .filter(({ isSweep, value }) => {
          // const arr = value.split("/");
          return isSweep && value?.split("/")[0] === "fixed";
        })
        .map(({ name, value }) => {
          const arr = value.split("/");
          return `${name}/${arr[1]}`;
        })
    };
    return newPlotParams;
  };

  update = () => {
    const { onPlotboxChange } = this.props,
      { drilldowns, plotType, tooltipError, scatterOptions } = this.state,
      drillDownX = drilldowns.find(
        ({ isSweep, value }) => isSweep && value === "x"
      ),
      sliderName = drilldowns.filter(
        ({ isSweep, value }) => isSweep && value === "slider"
      );
    if (tooltipError === "" && !drilldowns.some(({ error }) => error !== "")) {
      onPlotboxChange(
        this.buildNewPlotParams(
          plotType,
          drillDownX,
          drilldowns,
          sliderName,
          scatterOptions
        )
      );
    }
  };

  onRangeChange = (rangeValue, index) => {
    let range = [...this.state.rangeValue];
    range[index] = rangeValue;
    this.setState(
      { rangeValue: range },
      this.onRangeChangeDebounced(rangeValue, index)
    );
  };

  onRangeChangeDebounced = (rangeValueDebounced, rangeIndex) => {
    const { onRangeValueChange } = this.props;
    onRangeValueChange(rangeValueDebounced, rangeIndex);
  };

  getYData = rangeValue => {
    const { yData } = this.props.data;
    var finalYData = yData;
    if (rangeValue?.length > 0 && finalYData) {
      rangeValue.forEach(value => {
        finalYData = finalYData[value - 1];
      });
      return finalYData;
    }
    return finalYData ? finalYData[0] : [];
  };

  onPlotTypeChange = e => {
    let { drilldowns, plotType, scatterOptions } = this.state,
      { params, sweptVarNames } = this.props,
      newPlotType = e.target.value;
    let newScatterOptions =
      Object.keys(scatterOptions ?? {}).length === 0
        ? {
            x: "output",
            y: "output",
            color: "null"
          }
        : scatterOptions;
    if (plotType !== newPlotType) {
      const newDrilldowns = Helper.convertDrilldownsPlotType(
        drilldowns,
        newPlotType,
        scatterOptions
      );
      this.setDrillDownsData(
        newDrilldowns,
        params,
        this.getVariableAxisOptions(
          newPlotType,
          sweptVarNames,
          newScatterOptions
        )
      );
      this.setState({
        drilldowns: newDrilldowns,
        plotType: newPlotType,
        scatterOptions: newScatterOptions
      });
    }
  };

  onScatterOptionsChange = (scatterOptionTarget, value) => {
    var { scatterOptions, drilldowns } = this.state;
    scatterOptions[scatterOptionTarget] = value;
    drilldowns.forEach(drilldown => {
      if (drilldown.isSweep) {
        if (drilldown.value === "y" && scatterOptions.y !== "sweep") {
          drilldown.value = "all";
        }
        if (drilldown.value === "x" && scatterOptions.x !== "sweep") {
          drilldown.value = "all";
        }
        if (drilldown.value === "color" && scatterOptions.color !== "sweep") {
          drilldown.value = "all";
        }
        drilldown.error = "";
      }
    });
    this.setState({ scatterOptions, drilldowns });
  };

  onTooltipButtonClick = () => {
    this.setState({ enableTooltip: !this.state.enableTooltip });
  };

  getTooltip = rect => (
    <Fragment>
      <div>
        x: <b>{rect.xVal}</b>
      </div>
      <div>
        y: <b>{rect.yVal}</b>
      </div>
      <div>
        z: <b>{rect.zVal}</b>
      </div>
    </Fragment>
  );

  getAxisName = (label, unit) =>
    !unit || unit === "" ? label : `${label} (${unit})`;

  getPlotSelectedPoints = plotboxId => {
    const selectedPoints = this.props.selectedPoints.filter(
      plot => plot.plotId == plotboxId
    );
    return selectedPoints;
  };

  isOptimizationSimulation = () => {
    const { configuration } = this.props;
    if (
      configuration.simulation_targets &&
      configuration.simulation_targets[0]?.optimizer
    ) {
      return true;
    }
    return false;
  };

  render = () => {
    const { enableTooltip, drilldowns, init, tooltipError } = this.state,
      {
        classes,
        data: {
          id,
          plotType,
          xData,
          yData,
          zData,
          sliderData,
          sliderName,
          xName,
          yName,
          zName,
          polling,
          lineData,
          rangeValue,
          status,
          progress,
          sliderUnit,
          xUnit,
          yUnit,
          zUnit,
          yAxisRange,
          xAxisRange,
          zDataRange
        },
        showErrorsAndWarnings,
        sweptVarNames,
        disablePlotBoxUpdate,
        onGetUnwrappedOutputData
      } = this.props,
      tooltipBtnLabel = enableTooltip ? "Disable tooltip" : "Enable tooltip",
      isUpdateButtonDisabled =
        drilldowns.some(({ error }) => error !== "") || disablePlotBoxUpdate,
      hasData =
        plotType === "3D" &&
        xData.length > 0 &&
        yData.length > 0 &&
        zData.length > 0
          ? true
          : plotType === "2D" &&
            xData.length > 0 &&
            yData.length > 0 &&
            lineData.length > 0
          ? true
          : plotType == "SC" && xData.length > 0 && yData.length > 0;
    if (!init) {
      return null;
    }
    return (
      <div style={{ width: "100%" }}>
        {/* <Backdrop
          style={{ zIndex: 1 }}
          open={polling}
        >
        </Backdrop> */}
        <div style={{ width: "40%", float: "left", marginLeft: 5 }}>
          <Grid container>
            <Grid item xs={2}>
              <Grid item xs={12}>
                <FormControl
                  test-data="plotTypeForm"
                  disabled={disablePlotBoxUpdate}
                  classes={{ root: classes.option }}
                >
                  <FormLabel style={{ color: "#000000" }}>Plot type</FormLabel>
                  <Select
                    test-data={"plotType"}
                    value={this.state.plotType}
                    onChange={this.onPlotTypeChange}
                  >
                    <MenuItem
                      disabled={this.isOptimizationSimulation()}
                      value="2D"
                      test-data="LinePlotT"
                    >
                      Line
                    </MenuItem>
                    {sweptVarNames.length > 1 && (
                      <MenuItem
                        disabled={this.isOptimizationSimulation()}
                        value="3D"
                      >
                        Map
                      </MenuItem>
                    )}
                    <MenuItem value="SC">Scatter</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {this.state.plotType == "SC" && (
                <Grid item xs={12}>
                  <FormControl
                    style={{ width: "80%" }}
                    test-data="scatterOptionsForm"
                    disabled={disablePlotBoxUpdate}
                  >
                    <FormLabel style={{ color: "#000000", marginTop: "15px" }}>
                      X
                    </FormLabel>
                    <Select
                      test-data={"scatterXSelect"}
                      value={this.state.scatterOptions.x}
                      onChange={e =>
                        this.onScatterOptionsChange("x", e.target.value)
                      }
                    >
                      <MenuItem value="output">Output</MenuItem>
                      {sweptVarNames.length >= 1 && (
                        <MenuItem value="sweep">Sweep</MenuItem>
                      )}
                    </Select>
                    <FormLabel style={{ color: "#000000", marginTop: "15px" }}>
                      Y
                    </FormLabel>
                    <Select
                      test-data={"scatterYSelect"}
                      value={this.state.scatterOptions.y}
                      onChange={e =>
                        this.onScatterOptionsChange("y", e.target.value)
                      }
                    >
                      <MenuItem value="output">Output</MenuItem>
                      {sweptVarNames.length >= 1 && (
                        <MenuItem value="sweep">Sweep</MenuItem>
                      )}
                    </Select>
                    <FormLabel style={{ color: "#000000", marginTop: "15px" }}>
                      Color
                    </FormLabel>
                    <Select
                      test-data={"scatterColorSelect"}
                      value={this.state.scatterOptions.color}
                      onChange={e =>
                        this.onScatterOptionsChange("color", e.target.value)
                      }
                    >
                      <MenuItem value="null">-</MenuItem>
                      <MenuItem value="output">Output</MenuItem>
                      {sweptVarNames.length >= 1 && (
                        <MenuItem value="sweep">Sweep</MenuItem>
                      )}
                    </Select>
                    {this.isOptimizationSimulation() && (
                      <>
                        <FormLabel
                          style={{ color: "#000000", marginTop: "15px" }}
                        >
                          Optimization Variables
                        </FormLabel>
                        <Select
                          test-data={"plotAllOrBestSelect"}
                          value={this.state.scatterOptions.plot_best || false}
                          onChange={e =>
                            this.onScatterOptionsChange(
                              "plot_best",
                              e.target.value
                            )
                          }
                        >
                          <MenuItem value={true}>Plot Best Value</MenuItem>
                          <MenuItem value={false}>Plot All Values</MenuItem>
                        </Select>
                      </>
                    )}
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Grid item xs={8}>
              <PlotboxFormDrilldowns
                plotType={this.state.plotType}
                scatterOptions={this.state.scatterOptions}
                filterOutputDrilldownValues={this.filterOutputDrilldownValues}
                drilldowns={drilldowns} // some fields should be disabled for certain settings combinations
                onDrilldownClose={this.onDrilldownClose}
                disabled={disablePlotBoxUpdate}
              />
            </Grid>
            <Grid item xs={2}>
              <div className={classes.plotButtons}>
                <div>
                  <Button
                    variant="contained"
                    onClick={this.onTooltipButtonClick}
                    style={{ width: "100%", marginTop: 10 }}
                    disabled={disablePlotBoxUpdate}
                  >
                    {tooltipBtnLabel}
                  </Button>
                </div>
                <PlotMenu
                  showErrorsAndWarnings={showErrorsAndWarnings}
                  tooltipError={tooltipError}
                  onUpdateButtonClick={this.onUpdateButtonClick}
                  isUpdateButtonDisabled={isUpdateButtonDisabled}
                  polling={polling}
                  status={status}
                  progress={progress}
                  disabled={disablePlotBoxUpdate}
                  plotId={id}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div style={{ width: "58%", float: "right", marginLeft: 5 }}>
          <div
            id={`plotbox_${this.props.data.id}`}
            className="plotContainer"
            test-data="plotContainer"
          >
            <div>
              <ContainerDimensions>
                {({ width, height }) => {
                  if (width < 400) {
                    width = 400;
                  }
                  if (height < 400) {
                    height = 400;
                  }
                  if (!hasData || plotType === "2D" || plotType === "SC") {
                    height = width / 2;
                  }
                  return (
                    <div
                      className={classes.plot}
                      test-data="plotbox"
                      style={{ backgroundColor: "white" }}
                    >
                      <svg
                        height={height}
                        width={width}
                        className={classes.labels}
                      >
                        {hasData && plotType === "2D" && (
                          <>
                            <text
                              x={0 - (height / 2 - 20)}
                              y="20"
                              className={classes.yLabelText}
                            >
                              {this.getAxisName(yName, yUnit)}
                            </text>
                            <text x={width / 2 - 10} y={height - 10}>
                              {this.getAxisName(xName, xUnit)}
                            </text>
                          </>
                        )}
                      </svg>

                      {!hasData && <NoDataPlot height={height} />}

                      {hasData && plotType === "2D" && (
                        <div
                          style={{
                            height: height,
                            width: width
                          }}
                        >
                          <LinePlot
                            onClick={this.props.handleResultSelection}
                            x={xData}
                            xLabel={xName}
                            xUnit={xUnit}
                            y={this.getYData(rangeValue)}
                            yLabel={yName}
                            yUnit={yUnit}
                            width={width}
                            height={height}
                            yAxisRange={yAxisRange}
                            xAxisRange={xAxisRange}
                            onGetUnwrappedOutputData={onGetUnwrappedOutputData}
                            selectedPoints={this.getPlotSelectedPoints(
                              this.props.data.id
                            )}
                            // yAxisRange={HelperUtils.getMatrixRange(yData)}
                          />
                        </div>
                      )}
                      {hasData && plotType === "3D" && (
                        <Simulation3DPlot
                          width={width}
                          xData={xData}
                          xName={xName}
                          xUnit={xUnit}
                          yData={yData}
                          yName={yName}
                          yUnit={yUnit}
                          zData={zData}
                          zName={zName}
                          zUnit={zUnit}
                          rangeValue={rangeValue}
                          handleResultSelection={
                            this.props.handleResultSelection
                          }
                          disableSideView={this.props.disableSideView}
                          xAxisRange={xAxisRange}
                          yAxisRange={yAxisRange}
                          zDataRange={zDataRange}
                          onGetUnwrappedOutputData={onGetUnwrappedOutputData}
                          selectedPoints={this.getPlotSelectedPoints(
                            this.props.data.id
                          )}
                        />
                      )}
                      {hasData && plotType === "SC" && (
                        <div
                          style={{
                            height: height,
                            width: width
                          }}
                        >
                          <ScatterPlot
                            onClick={this.props.handleResultSelection}
                            x={xData}
                            xLabel={xName}
                            xUnit={xUnit}
                            y={yData}
                            color={zData}
                            zName={zName}
                            yLabel={yName}
                            yUnit={yUnit}
                            width={width}
                            height={height}
                            yAxisRange={yAxisRange}
                            xAxisRange={xAxisRange}
                            selectedPoints={this.getPlotSelectedPoints(
                              this.props.data.id
                            )}
                            // yAxisRange={HelperUtils.getMatrixRange(yData)}
                          />
                        </div>
                      )}
                    </div>
                  );
                }}
              </ContainerDimensions>
            </div>

            {/* multiple sliders */}
            {hasData &&
              sliderData.length > 0 &&
              sliderName
                .split(",")
                .map((sName, index) => (
                  <Slider
                    rangeValue={this.state.rangeValue[index] || 1}
                    sliderData={sliderData[index]}
                    onRangeChange={val => this.onRangeChange(val, index)}
                    sliderName={sName}
                    sliderUnit={sliderUnit.split(",")[index]}
                  />
                ))}
          </div>
        </div>
      </div>
    );
  };
}

Plotbox.propTypes = {
  params: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  onPlotboxChange: PropTypes.func.isRequired,
  onRangeValueChange: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  selectedResults: SimulationSelector.getSelectedResults(state),
  selectedPoints: SimulationSelector.getSelectedPoints(state)
});

const mapDispatchToProps = dispatch => ({
  selectResultsAction: data => dispatch(SimulationAction.selectResults(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(Plotbox)));
