import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import FlareIcon from "@material-ui/icons/Flare";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Divider from "@material-ui/core/Divider";
import { MetaComponentPaths } from "MetaComponent/MetaComponent";
import SideMenuItem from "components/SideMenuItem/SideMenuItem";
import Explorer from "./components/Explorer/Explorer";
import { withRouter } from "react-router-dom";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import AssessmentIcon from "@material-ui/icons/Assessment";
import refraction from "assets/refraction.png";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
  gutter: {
    paddingTop: 0,
    paddingRight: 0
  },
  chip: {
    height: theme.spacing(2.0)
  }
});

/**
 * the content of meta component's control menu. Clicks on the menu will take the user to different pages.
 * See {@link MetaComponentPath}.
 * @author Akira Kotsugai
 */
export class MetaComponentMenu extends PureComponent {
  /**
   * it redirects the user to the empty screen when no meta component is open
   * and redirects the user to the first screen when a new simulation is opened or created
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const { metaComponentOpenId, history } = this.props,
      metaComponentOpenChanged =
        metaComponentOpenId !== prevProps.metaComponentOpenId,
      noMetaComponentOpen = metaComponentOpenId === -1,
      metaComponentWasOpened =
        metaComponentOpenChanged &&
        !noMetaComponentOpen &&
        prevProps.metaComponentOpenId !== -1,
      metaComponentWasCreated =
        metaComponentOpenChanged &&
        !noMetaComponentOpen &&
        !metaComponentWasOpened &&
        prevProps.loaded;
    if (metaComponentOpenChanged) {
      if (noMetaComponentOpen) {
        history.push(MetaComponentPaths.ROOT);
      } else if (metaComponentWasOpened || metaComponentWasCreated) {
        history.push(MetaComponentPaths.COMPONENT);
      }
      // there is another case when the open meta component changes but we should not do anything:
      // when the data has just been loaded and we are opening the user history's open meta component
      // in this case we dont do anything, because we want to use history's screen path as well
    }
  }

  render() {
    const {
        activePage,
        classes,
        saveEditings,
        metaComponentOpenId,
        loaded,
        thereAreDesignJobs,
        family_is_pdk
      } = this.props,
      noMetaComponent = metaComponentOpenId === -1;
    return (
      <div test-data="metaComponentMenu">
        <ListItem className={classes.gutter}>
          <Explorer saveEditings={saveEditings} />
        </ListItem>
        <Divider />

        <SideMenuItem
          test-data="componentItem"
          activePath={activePage}
          disabled={noMetaComponent}
          path={MetaComponentPaths.COMPONENT}
        >
          <FlareIcon className="IconColor" />
          <ListItemText primary="Component" />
        </SideMenuItem>
        <Divider />
        <SideMenuItem
          test-data="targetItem"
          activePath={activePage}
          disabled={noMetaComponent}
          path={MetaComponentPaths.TARGET}
        >
          <TrackChangesIcon className="IconColor" />
          <ListItemText primary="Target" />
        </SideMenuItem>
        <Divider />
        <SideMenuItem
          test-data="designItem"
          activePath={activePage}
          disabled={noMetaComponent}
          path={MetaComponentPaths.DESIGN}
        >
          <PlayArrow className="IconColor" />
          <ListItemText primary="Design" />
        </SideMenuItem>
        <Divider />
        <SideMenuItem
          test-data="odaItem"
          activePath={activePage}
          disabled={noMetaComponent || !thereAreDesignJobs}
          path={MetaComponentPaths.ODA}
        >
          <AssessmentIcon className="IconColor" />
          <ListItemText primary="ODA" />
        </SideMenuItem>
        <Divider />
        <SideMenuItem
          test-data="analysisItem"
          activePath={activePage}
          disabled={noMetaComponent || !thereAreDesignJobs}
          path={MetaComponentPaths.ANALYSIS}
        >
          <AssessmentIcon className="IconColor" />
          <ListItemText primary="Analysis" />
        </SideMenuItem>
        <Divider />
        <SideMenuItem
          test-data="rayTracingItem"
          activePath={activePage}
          disabled={noMetaComponent || !thereAreDesignJobs}
          path={MetaComponentPaths.RAY_TRACING_LINK}
        >
          <img src={refraction} width={20} style={{ marginRight: "5px" }} />
          <ListItemText primary=" Ray Tracing Link" />
          <Chip label="Beta" color="primary" className={classes.chip} />
        </SideMenuItem>
        <Divider />
        {loaded && noMetaComponent && (
          <DirectionSnackbar message="Open a meta component to enable navigation" />
        )}
      </div>
    );
  }
}

MetaComponentMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  activePage: PropTypes.string.isRequired,
  saveEditings: PropTypes.func.isRequired,
  metaComponentOpenId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(MetaComponentMenu));
