import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import ControlMenu from "components/ControlMenu/ControlMenu";
import StructureCanvas from "./containers/StructureCanvas/StructureCanvas";
import IncidentLightCanvas from "./containers/IncidentLightCanvas/IncidentLightCanvas";
import SimulationTargetCanvas from "./containers/SimulationTargetCanvas/SimulationTargetCanvas";
import SimulationSettingsCanvas from "./containers/SimulationSettingsCanvas/SimulationSettingsCanvas";
import SimulateCanvas from "./containers/SimulateCanvas/SimulateCanvas";
import ModeAnalysisCanvas from "./containers/ModeAnalysisCanvas/ModeAnalysisCanvas";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import StructureApi from "./api/Structure";
import GlobalParametersSelector from "./selectors/GlobalParameters";
import GlobalParametersApi from "./api/GlobalParameters";
import IncidentLightSelector from "./selectors/IncidentLight";
import IncidentLightApi from "./api/IncidentLight";
import SimulationSettingsSelector from "./selectors/SimulationSettings";
import SimulationSettingsApi from "./api/SimulationSettings";
import MaterialApi from "./api/Material";
import DirectoryExplorerSelector from "./selectors/DirectoryExplorer";
import RootCanvas from "./containers/RootCanvas/RootCanvas";
import MetaCellMenu from "MetaCell/containers/Menu/Menu";
import { setMetacellPath } from "BaseApp/actions/navigationHistory";
import UnselfishDialog from "components/UnselfishDialog/UnselfishDialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Autocomplete from "components/Autocomplete";
import Spinner from "components/Spinner/Spinner";
import Axios from "axios";
import StructureActions from "./actions/Structure";
import DirectoryExplorerApi from "MetaCell/api/DirectoryExplorer";
import UserApi from "BaseApp/api/User";
import UserSelector from "BaseApp/selectors/User";
import SimulationSelector from "MetaCell/selectors/Simulation";
import PlotboxManager from "MetaCell/components/PlotboxManager/PlotboxManager";
import SimulationApi from "MetaCell/api/Simulation";
import GenericApi from "Api";
import SimulationTargetApi from "MetaCell/api/SimulationTarget";
import OutputSettingsApi from "MetaCell/api/OutputSetting";

/**
 * internal meta cell paths
 * @author Akira Kotsugai
 * @constant
 * @typedef {Object} MetaCellPath
 * @property {String} ROOT - the path to root canvas
 * @property {String} STRUCTURE - the path to structure canvas
 * @property {String} INCIDENT_LIGHT - the path to incident light canvas
 * @property {String} SIMULATION_SETTINGS - the path to simulation settings canvas
 * @property {String} SIMULATE - the path to simulate canvas
 * @property {String} MODE_ANALYSIS - the path to the mode analysis canvas
 * @global
 */
export const metaCellPaths = Object.freeze({
  ROOT: "/meta-cell",
  STRUCTURE: "/meta-cell/structure-canvas",
  INCIDENT_LIGHT: "/meta-cell/incident-light-canvas",
  SIMULATION_SETTINGS: "/meta-cell/simulation-settings-canvas",
  SIMULATE: "/meta-cell/simulate-canvas",
  MODE_ANALYSIS: "/meta-cell/mode-analysis",
  SIMULATION_TARGET: "/meta-cell/target"
});

/**
 * The component responsible for meta cell simulations, it contains a Menu where each
 * item takes you to a different screen to insert parameters for the simulation.
 * @author Ibtihel
 */
export class MetaCell extends Component {
  simulationOpened;

  constructor() {
    super();
    this.state = {
      currentPage: "",
      isFetchingAllData: false,
      isFetchingStructureData: false,
      isFetchingIncidentLightData: false,
      isFetchingSimulationSettingsData: false,
      isFetchingSimulationTargets: true,
      materialsNotFound: [],
      dialogLoading: false,
      dialogSelectedMaterial: null
    };
  }

  /**
   * it saves unsaved editings for the current page  and fetches all data when the selected simulation changes
   * @param {Object} prevProps - the previous props
   */
  componentDidUpdate(prevProps) {
    const { simulationOpenId } = this.props;
    if (simulationOpenId !== prevProps.simulationOpenId) {
      if (simulationOpenId === -1) {
        this.setState({ currentPage: metaCellPaths.ROOT });
      } else if (this.simulationOpened || prevProps.simulationOpenId !== -1) {
        this.simulationOpened = true;
        this.setState({ currentPage: metaCellPaths.STRUCTURE });
      }
      this.fetchAllData();
    }
  }

  fetchAllData = () => {
    const {
        simulationOpenId,
        fetchGlobalParametersAction,
        fetchMaterialsAction,
        fetchLayersAction,
        fetchIncidentLightAction,
        fetchMaxDiffractionOrdersAction,
        fetchProbes,
        getSweptVariables,
        fetchParameterizedStructuresAction,
        fetchModeAnalysisJobs,
        fetchSimulationJobs,
        fetchSimulationTargetsAction,
        fetchOutputSettingsAction
      } = this.props,
      requests = [];
    this.setState({
      materialsNotFound: [],
      dialogLoading: false,
      dialogSelectedMaterial: null,
      isFetchingAllData: true
    });
    requests.push(
      fetchMaterialsAction(),
      fetchProbes(simulationOpenId),
      getSweptVariables()
    );
    if (simulationOpenId > -1) {
      requests.push(
        fetchSimulationTargetsAction(simulationOpenId),
        fetchLayersAction(simulationOpenId),
        fetchGlobalParametersAction(simulationOpenId),
        fetchIncidentLightAction(simulationOpenId),
        fetchMaxDiffractionOrdersAction(simulationOpenId),
        fetchModeAnalysisJobs(simulationOpenId),
        fetchSimulationJobs(simulationOpenId),
        fetchParameterizedStructuresAction(),
        fetchOutputSettingsAction(simulationOpenId)
      );
    }
    return Promise.all(requests).then(this.fetchingAllDataFinished);
  };

  fetchingAllDataFinished = data => {
    const materials = data[0],
      layers = data[3],
      materialsNotFound = [];
    layers &&
      layers.forEach(layer => {
        layer.usedmaterial_set &&
          layer.usedmaterial_set.forEach(usedMaterial => {
            if (!materials.some(({ id }) => usedMaterial.material === id)) {
              materialsNotFound.push({
                layerName: layer.name,
                materialId: usedMaterial.material,
                usedMaterialId: usedMaterial.id
              });
            }
          });
      });
    if (materialsNotFound.length > 0) {
      this.setState({ materials, materialsNotFound });
    }
    this.setState({
      isFetchingAllData: false,
      isFetchingSimulationTargets: false
    });
  };

  /**
   * it saves editings related to structure but also retrieves the simulation layers
   * if the global parameters were saved
   */
  saveStructureEditings = () => {
    const {
      editingGlobalParameters,
      putGlobalParametersAction,
      fetchLayersAction,
      simulationOpenId
    } = this.props;
    let requests = [];
    if (editingGlobalParameters)
      requests.push(putGlobalParametersAction(editingGlobalParameters));
    Promise.all(requests).then(() => {
      if (editingGlobalParameters) {
        fetchLayersAction(simulationOpenId, true);
      }
    });
    return;
  };

  /**
   * it saves editings related to incident light
   */
  saveIncidentLightEditings = () => {
    const {
      incidentLight,
      editingIncidentLight,
      putIncidentLightAction
    } = this.props;
    const incidentLightValue = Object.values(incidentLight.byId)[0];
    if (
      !isEqual(editingIncidentLight, incidentLightValue) &&
      editingIncidentLight.id
    ) {
      const { polarization } = editingIncidentLight || {};
      if (polarization) {
        const polarizationValues = polarization.split("/");
        if (polarizationValues.length >= 3) {
          polarizationValues.forEach((val, i) => {
            if (val === "") {
              polarizationValues[i] = "0";
            }
          });
          return putIncidentLightAction({
            ...editingIncidentLight,
            polarization: polarizationValues.join("/")
          });
        }
      }
      return putIncidentLightAction(editingIncidentLight);
    }
  };

  /**
   * it saves editings related to simulation settings
   */
  saveSimulationSettingsEditings = () => {
    const {
      maxDiffractionOrders,
      editingMaxDiffractionOrders,
      putMaxDiffractionOrdersAction,
      simulations,
      simulationOpenId,
      editingSimulationDescription,
      updateSimulationAction
    } = this.props;
    const maxDiffractionOrdersValue = Object.values(
        maxDiffractionOrders.byId
      )[0],
      simulation = simulations.byId[simulationOpenId];
    let requests = [];
    if (
      !isEqual(editingMaxDiffractionOrders, maxDiffractionOrdersValue) &&
      editingMaxDiffractionOrders.id
    )
      requests.push(putMaxDiffractionOrdersAction(editingMaxDiffractionOrders));
    if (
      editingSimulationDescription !== null &&
      !isEqual(editingSimulationDescription, simulation.description)
    )
      requests.push(
        updateSimulationAction(simulation.id, {
          description: editingSimulationDescription
        })
      );
    return Promise.all(requests);
  };

  /**
   * it should be passed as props to save possible unsaved editings related to the page being left.
   * @param {String} page - the path of the page being left
   * @callback
   */
  saveEditings = page => {
    const { simulationOpenId } = this.props,
      { currentPage } = this.state,
      pageToSaveEditings = page ? page : currentPage;
    if (simulationOpenId > -1) {
      switch (pageToSaveEditings) {
        case metaCellPaths.STRUCTURE: {
          return this.saveStructureEditings();
        }
        case metaCellPaths.INCIDENT_LIGHT: {
          return this.saveIncidentLightEditings();
        }
        case metaCellPaths.SIMULATION_SETTINGS: {
          return this.saveSimulationSettingsEditings();
        }
        default: {
          return;
        }
      }
    }
  };

  setPage = currentPage => {
    const { setMetacellPath, postUserSettings } = this.props;
    this.setState({ currentPage });
    setMetacellPath(currentPage);
    postUserSettings({ lastView: currentPage });
  };

  /**
   * it fetches data to the given meta cell page and updates the open page in the state
   * @param {String} page - the metacell pathname
   */
  fetchData = page => {
    this.setPage(page);
    const { simulationOpenId } = this.props;
    if (simulationOpenId !== -1) {
      switch (page) {
        case metaCellPaths.STRUCTURE: {
          const {
            fetchGlobalParametersAction,
            fetchMaterialsAction,
            fetchLayersAction,
            simulationOpenId,
            fetchParameterizedStructuresAction
          } = this.props;
          this.setState({ isFetchingStructureData: true });
          Promise.all([
            fetchMaterialsAction(),
            fetchLayersAction(simulationOpenId),
            fetchGlobalParametersAction(simulationOpenId),
            fetchParameterizedStructuresAction()
          ]).then(() => {
            this.setState({ isFetchingStructureData: false });
          });
          return;
        }

        case metaCellPaths.INCIDENT_LIGHT: {
          const { fetchIncidentLightAction, simulationOpenId } = this.props;
          this.setState({ isFetchingIncidentLightData: true });
          fetchIncidentLightAction(simulationOpenId).then(() => {
            this.setState({ isFetchingIncidentLightData: false });
          });
          return;
        }

        case metaCellPaths.SIMULATION_TARGET: {
          const { fetchSimulationTargetsAction, simulationOpenId } = this.props;
          this.setState({ isFetchingSimulationTargets: true });
          fetchSimulationTargetsAction(simulationOpenId).then(() => {
            this.setState({ isFetchingSimulationTargets: false });
          });
          return;
        }

        case metaCellPaths.SIMULATION_SETTINGS: {
          const {
            fetchMaxDiffractionOrdersAction,
            simulationOpenId
          } = this.props;
          this.setState({ isFetchingSimulationSettingsData: true });
          fetchMaxDiffractionOrdersAction(simulationOpenId).then(() => {
            this.setState({ isFetchingSimulationSettingsData: false });
          });
          return;
        }

        default: {
          return;
        }
      }
    }
  };

  handleClose = () => {
    const { dialogSelectedMaterial, materialsNotFound, materials } = this.state,
      material = dialogSelectedMaterial || materials[0].id,
      { usedMaterialId } = materialsNotFound[0],
      { patchUsedMaterial } = this.props;
    this.setState({ dialogLoading: true });
    Axios.patch(`${GenericApi.getBaseUrl()}/usedmaterials/${usedMaterialId}`, {
      material
    }).then(({ data }) => {
      patchUsedMaterial(data);
      materialsNotFound.shift();
      this.setState({
        materialsNotFound,
        dialogLoading: false,
        dialogSelectedMaterial: null
      });
    });
  };

  render() {
    const { simulationOpenId } = this.props,
      {
        isFetchingAllData,
        isFetchingStructureData,
        isFetchingIncidentLightData,
        isFetchingSimulationSettingsData,
        isFetchingSimulationTargets,
        materialsNotFound,
        dialogLoading,
        dialogSelectedMaterial,
        materials
      } = this.state;
    const menuContent = (
      <MetaCellMenu
        simulationOpenId={simulationOpenId}
        activePage={this.state.currentPage}
        disabled={simulationOpenId === -1}
        saveEditings={this.saveEditings}
      />
    );

    return (
      <div test-data="metaCell">
        <UnselfishDialog
          onClose={this.handleClose}
          open={materialsNotFound && materialsNotFound.length > 0}
          fullWidth
          test-data="missing-material-dialog"
        >
          {!dialogLoading && (
            <>
              <DialogContent>
                <DialogContentText>
                  Material not found for layer{" "}
                  <b>
                    {materialsNotFound &&
                      materialsNotFound[0] &&
                      materialsNotFound[0].layerName}
                  </b>
                  , please select an alternative material.
                </DialogContentText>
                {materials && materials.length > 0 && (
                  <div style={{ height: 400 }}>
                    <Autocomplete
                      name="choose-material"
                      id="choose-material"
                      test-data="choose-material"
                      options={materials.map(({ id, name }) => ({ id, name }))}
                      value={dialogSelectedMaterial}
                      initialInputValue={""}
                      onChange={newValue =>
                        this.setState({ dialogSelectedMaterial: newValue })
                      }
                    />
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Done
                </Button>
              </DialogActions>
            </>
          )}
          {dialogLoading && (
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                padding: 50
              }}
            >
              <Spinner name="Waiting" size={68} timeout={30000} />
            </div>
          )}
        </UnselfishDialog>

        <Router>
          <ControlMenu content={menuContent}>
            <Switch>
              {(isFetchingAllData || materialsNotFound.length > 0) && (
                <Route
                  path="*"
                  render={props => (
                    <RootCanvas {...props} isLoading={isFetchingAllData} />
                  )}
                />
              )}

              <Route
                path={metaCellPaths.STRUCTURE}
                render={props => (
                  <StructureCanvas
                    {...props}
                    isFetching={isFetchingStructureData}
                    setPage={this.setPage}
                    saveEditings={this.saveEditings}
                  />
                )}
              />
              <Route
                path={metaCellPaths.INCIDENT_LIGHT}
                render={props => (
                  <IncidentLightCanvas
                    {...props}
                    isFetching={isFetchingIncidentLightData}
                    setPage={this.setPage}
                    saveEditings={this.saveEditings}
                  />
                )}
              />
              <Route
                path={metaCellPaths.SIMULATION_TARGET}
                render={props => (
                  <SimulationTargetCanvas
                    {...props}
                    ready={!isFetchingSimulationTargets}
                    setPage={this.setPage}
                  />
                )}
              />
              <Route
                path={metaCellPaths.SIMULATION_SETTINGS}
                render={props => (
                  <SimulationSettingsCanvas
                    {...props}
                    isFetching={isFetchingSimulationSettingsData}
                    setPage={this.setPage}
                    saveEditings={this.saveEditings}
                    fetchAllData={this.fetchAllData}
                  />
                )}
              />
              <Route
                path={metaCellPaths.SIMULATE}
                render={props => (
                  <SimulateCanvas {...props} setPage={this.setPage} />
                )}
              />
              <Route
                path={metaCellPaths.MODE_ANALYSIS}
                render={props => (
                  <PlotboxManager
                    simulationJobId={this.props.selectedModeAnalysisJobId}
                  >
                    <ModeAnalysisCanvas {...props} setPage={this.setPage} />
                  </PlotboxManager>
                )}
              />
            </Switch>
          </ControlMenu>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedModeAnalysisJobId: SimulationSelector.getSelectedModeAnalysisJobId(
    state
  ),
  simulations: DirectoryExplorerSelector.getSimulations(state),
  simulationOpenId: DirectoryExplorerSelector.getSimulationOpenId(state),
  globalParameters: GlobalParametersSelector.get(state),
  editingGlobalParameters: GlobalParametersSelector.getEditing(state),
  incidentLight: IncidentLightSelector.get(state),
  editingIncidentLight: IncidentLightSelector.getEditing(state),
  maxDiffractionOrders: SimulationSettingsSelector.getMaxDiffractionOrders(
    state
  ),
  editingMaxDiffractionOrders: SimulationSettingsSelector.getEditingMaxDiffractionOrders(
    state
  ),
  editingSimulationDescription: SimulationSettingsSelector.getEditingSimulationDescription(
    state
  ),
  userSettings: UserSelector.getUserSettings(state)
});

const mapDispatchToProps = dispatch => {
  return {
    putGlobalParametersAction: globalParameters =>
      dispatch(GlobalParametersApi.put(globalParameters)),
    putIncidentLightAction: incidentLight =>
      dispatch(IncidentLightApi.put(incidentLight)),
    putMaxDiffractionOrdersAction: maxDiffractionOrders =>
      dispatch(
        SimulationSettingsApi.putMaxDiffractionOrders(maxDiffractionOrders)
      ),
    fetchGlobalParametersAction: simulationId =>
      dispatch(GlobalParametersApi.fetch(simulationId)),
    fetchMaterialsAction: () => dispatch(MaterialApi.fetchMaterials()),
    fetchParameterizedStructuresAction: () =>
      dispatch(StructureApi.fetchPossibleParameterizedStructures()),
    fetchLayersAction: (simulationId, alreadyOpenSimulation) =>
      dispatch(StructureApi.fetchLayers(simulationId, alreadyOpenSimulation)),
    fetchIncidentLightAction: simulationId =>
      dispatch(IncidentLightApi.fetch(simulationId)),
    fetchMaxDiffractionOrdersAction: simulationId =>
      dispatch(SimulationSettingsApi.fetchMaxDiffractionOrders(simulationId)),
    fetchSimulationTargetsAction: simulationId =>
      dispatch(
        SimulationTargetApi.fetchSimulationTargetsBySimulationId(simulationId)
      ),
    fetchOutputSettingsAction: simulationId =>
      dispatch(OutputSettingsApi.fetchOutputSettingsBySimulation(simulationId)),
    getSweptVariables: () =>
      dispatch(SimulationSettingsApi.getSweptVariables()),
    fetchProbes: simulationId =>
      dispatch(SimulationSettingsApi.getProbes(simulationId)),
    setMetacellPath: path => dispatch(setMetacellPath(path)),
    patchUsedMaterial: usedMaterial =>
      dispatch(StructureActions.patchUsedMaterial(usedMaterial)),
    updateSimulationAction: (id, properties) =>
      dispatch(DirectoryExplorerApi.updateSimulation(id, properties)),
    postUserSettings: data => dispatch(UserApi.postUserSettings(data)),
    fetchModeAnalysisJobs: simulationId =>
      dispatch(SimulationApi.getModeAnalysisJobs(simulationId)),
    fetchSimulationJobs: simulationId =>
      dispatch(SimulationApi.getSimulationJobs(simulationId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MetaCell);
