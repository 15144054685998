import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import SweepInput from "components/SweepInput/SweepInput";
import {
  Grid,
  Paper,
  Typography,
  FormLabel,
  MenuItem
} from "@material-ui/core";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import IconTooltip from "components/IconTooltip/IconTooltip";
import Polarization from "components/Polarization/Polarization";
import NumberInput from "components/NumberInput/NumberInput";
import UnselfishSelect from "components/UnselfishSelect/UnselfishSelect";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  container: {
    alignItems: "center",
    justifyContent: "center"
  },
  label: {
    color: "#0000008a",
    display: "flex",
    alignItems: "center"
  },
  labelIcon: {
    fontSize: 16,
    marginLeft: 3,
    color: "#3f51b5"
  }
});

export const azimutTooltip =
  "Angle between incident wave and x-axis (in-plane)";
export const zenitTooltip = "Angle of incident wave to surface normal";

/**
 * A class component to allow the user to insert incident light parameters for the simulation
 * @typedef {Component} IncidentLightForm
 * @author Ibtihel
 * TODO: extract the similarities it has with MetaCellGlobalParametersForm and DiffractionOrdersForm
 */
export class IncidentLightForm extends Component {
  polarizationSelect;
  /**
   * It handles a change in a field when it is being edited and calls an action
   * @param {Object} event - the change event
   * @param {String} keyName - the field being edited
   */
  handleUpdateField = (value, keyName) => {
    const { updateFieldCallback } = this.props;
    updateFieldCallback({
      [keyName]: value
    });
  };

  onAzimutChange = azimut => {
    const { updateFieldCallback } = this.props;
    updateFieldCallback({ azimut });
  };

  onZenitChange = zenit => {
    const { updateFieldCallback } = this.props;
    updateFieldCallback({ zenit });
  };

  onWavelengthChange = wavelength => {
    const { updateFieldCallback } = this.props;
    updateFieldCallback({ wavelength });
  };

  onIncidentLightTypeChange = incident_light_type => {
    const { updateFieldCallback } = this.props;
    updateFieldCallback({ incident_light_type });
  };

  onFocalSpotXChange = focal_spot_x => {
    const { updateFieldCallback } = this.props;
    updateFieldCallback({ focal_spot_x });
  };

  onFocalSpotYChange = focal_spot_y => {
    const { updateFieldCallback } = this.props;
    updateFieldCallback({ focal_spot_y });
  };

  onFocalSpotZChange = focal_spot_z => {
    const { updateFieldCallback } = this.props;
    updateFieldCallback({ focal_spot_z });
  };

  onBeamDivergenceChange = beam_divergence => {
    const { updateFieldCallback } = this.props;
    updateFieldCallback({ beam_divergence });
  };

  render() {
    const {
      classes,
      incidentLight: {
        azimut,
        zenit,
        wavelength,
        polarization,
        amplitude,
        incident_light_type,
        focal_spot_x,
        focal_spot_y,
        focal_spot_z,
        beam_divergence
      },
      updateFieldCallback,
      updatePolarizationHistory,
      polarizationHistory,
      disabled,
      isSweep,
      handleBlur,
      touched,
      errors,
      title
    } = this.props;
    return (
      <Paper className={classes.root}>
        <Grid container spacing={2}>
          {title && (
            <Grid item xs={12}>
              <Typography variant="h5" component="h3">
                {title}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <form className={classes.container} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormLabel
                    style={{ fontSize: 12 }}
                    disabled={disabled}
                    htmlFor="Y"
                  >
                    Amplitude
                  </FormLabel>
                  {isSweep ? (
                    <SweepInput
                      name="IncidentLightAmplitude"
                      test-data="amplitude"
                      value={amplitude}
                      disabled={disabled && "DISABLE_INPUT"}
                      onChange={value =>
                        this.handleUpdateField(value, "amplitude")
                      }
                      simulationId={this.props.simulationId}
                      sweptVariables={this.props.sweptVariables}
                    />
                  ) : (
                    <NumberInput
                      test-data="amplitude"
                      name="amplitude"
                      disabled={disabled}
                      value={amplitude}
                      error={touched.amplitude && errors.amplitude}
                      helperText={touched.amplitude && errors.amplitude}
                      onChange={event =>
                        this.handleUpdateField(event.target.value, "amplitude")
                      }
                      onBlur={handleBlur}
                    />
                  )}
                </Grid>

                <Grid item xs={4}>
                  <FormLabel
                    style={{ fontSize: 12 }}
                    disabled={disabled}
                    htmlFor="Y"
                  >
                    Wavelength
                  </FormLabel>
                  {isSweep ? (
                    <SweepInput
                      name="IncidentLightWavelength"
                      test-data="wavelength"
                      disabled={disabled && "DISABLE_INPUT"}
                      value={wavelength}
                      onChange={this.onWavelengthChange}
                      simulationId={this.props.simulationId}
                      sweptVariables={this.props.sweptVariables}
                    />
                  ) : (
                    <NumberInput
                      test-data="wavelength"
                      disabled={disabled}
                      value={wavelength}
                      name="wavelength"
                      error={touched.wavelength && errors.wavelength}
                      helperText={touched.wavelength && errors.wavelength}
                      onChange={event =>
                        this.handleUpdateField(event.target.value, "wavelength")
                      }
                      onBlur={handleBlur}
                    />
                  )}
                </Grid>
                {this.props.wavelengthUnitComponent}
                <Grid item xs={4}>
                  <Polarization
                    updateFieldCallback={updateFieldCallback}
                    updatePolarizationHistory={updatePolarizationHistory}
                    polarization={polarization}
                    polarizationHistory={polarizationHistory}
                    gridColumnXs={12}
                    isSweep={isSweep}
                    disabled={disabled}
                    simulationId={this.props.simulationId}
                    sweptVariables={this.props.sweptVariables}
                  />
                </Grid>
                {!this.props.hideType && (
                  <Grid item xs={6}>
                    <div>
                      <FormLabel
                        style={{ fontSize: 12 }}
                        disabled={disabled}
                        htmlFor="incident_light_type"
                      >
                        Type
                      </FormLabel>
                    </div>
                    <UnselfishSelect
                      style={{ marginTop: 4 }}
                      name="incident_light_type"
                      value={incident_light_type}
                      onChange={({ target }) =>
                        this.onIncidentLightTypeChange(target.value)
                      }
                      disabled={disabled}
                    >
                      <MenuItem name="incident_light_type_option" value="PW">
                        Plane wave
                      </MenuItem>
                      <MenuItem name="incident_light_type_option" value="GB">
                        Gausian Beam
                      </MenuItem>
                    </UnselfishSelect>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <div test-data={"azimutLabel"}>
                    <FormLabel
                      style={{ fontSize: 12 }}
                      disabled={disabled}
                      htmlFor="Y"
                    >
                      Azimuth
                    </FormLabel>
                    <IconTooltip text={azimutTooltip} />
                  </div>
                  {isSweep ? (
                    <SweepInput
                      name="IncidentLightAzimut"
                      test-data="azimut"
                      disabled={disabled && "DISABLE_INPUT"}
                      value={azimut}
                      onChange={this.onAzimutChange}
                      simulationId={this.props.simulationId}
                      sweptVariables={this.props.sweptVariables}
                    />
                  ) : (
                    <NumberInput
                      test-data="azimut"
                      disabled={disabled}
                      value={azimut}
                      name="azimut"
                      error={touched.azimut && errors.azimut}
                      helperText={touched.azimut && errors.azimut}
                      onChange={event =>
                        this.handleUpdateField(event.target.value, "azimut")
                      }
                      onBlur={handleBlur}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <div test-data={"zenitLabel"}>
                    <FormLabel
                      style={{ fontSize: 12 }}
                      disabled={disabled}
                      htmlFor="Y"
                    >
                      Zenith
                    </FormLabel>
                    <IconTooltip text={zenitTooltip} />
                  </div>
                  {isSweep ? (
                    <SweepInput
                      name="IncidentLightZenit"
                      test-data="zenit"
                      value={zenit}
                      disabled={disabled && "DISABLE_INPUT"}
                      onChange={this.onZenitChange}
                      simulationId={this.props.simulationId}
                      sweptVariables={this.props.sweptVariables}
                    />
                  ) : (
                    <NumberInput
                      test-data="zenit"
                      name="zenit"
                      disabled={disabled}
                      value={zenit}
                      error={touched.zenit && errors.zenit}
                      helperText={touched.zenit && errors.zenit}
                      onChange={event =>
                        this.handleUpdateField(event.target.value, "zenit")
                      }
                      onBlur={handleBlur}
                    />
                  )}
                </Grid>
                {incident_light_type === "GB" && (
                  <Grid item xs={4}>
                    <div test-data={"beam_divergence"}>
                      <FormLabel
                        style={{ fontSize: 12 }}
                        disabled={disabled}
                        htmlFor="beam_divergence"
                      >
                        Beam Divergence
                      </FormLabel>
                      <IconTooltip text="Half opening angle in degrees." />
                    </div>
                    {isSweep ? (
                      <SweepInput
                        name="IncidentLightBeamDivergence"
                        test-data="beam_divergence"
                        value={beam_divergence}
                        disabled={disabled && "DISABLE_INPUT"}
                        onChange={this.onBeamDivergenceChange}
                        simulationId={this.props.simulationId}
                        sweptVariables={this.props.sweptVariables}
                      />
                    ) : (
                      <NumberInput
                        test-data="beam_divergence"
                        name="beam_divergence"
                        disabled={disabled}
                        value={beam_divergence}
                        error={
                          touched.beam_divergence && errors.beam_divergence
                        }
                        helperText={
                          touched.beam_divergence && errors.beam_divergence
                        }
                        onChange={event =>
                          this.handleUpdateField(
                            event.target.value,
                            "beam_divergence"
                          )
                        }
                        onBlur={handleBlur}
                      />
                    )}
                  </Grid>
                )}
                {incident_light_type === "GB" && (
                  <Grid item xs={4}>
                    <div test-data={"focal_spot_x"}>
                      <FormLabel
                        style={{ fontSize: 12 }}
                        disabled={disabled}
                        htmlFor="focal_spot_x"
                      >
                        Focal Spot X
                      </FormLabel>
                    </div>
                    {isSweep ? (
                      <SweepInput
                        name="IncidentLightFocalSpotX"
                        test-data="focal_spot_x"
                        value={focal_spot_x}
                        disabled={disabled && "DISABLE_INPUT"}
                        onChange={this.onFocalSpotXChange}
                        simulationId={this.props.simulationId}
                        sweptVariables={this.props.sweptVariables}
                        allowNegative
                      />
                    ) : (
                      <NumberInput
                        test-data="focal_spot_x"
                        name="focal_spot_x"
                        disabled={disabled}
                        value={focal_spot_x}
                        error={touched.focal_spot_x && errors.focal_spot_x}
                        helperText={touched.focal_spot_x && errors.focal_spot_x}
                        onChange={event =>
                          this.handleUpdateField(
                            event.target.value,
                            "focal_spot_x"
                          )
                        }
                        onBlur={handleBlur}
                      />
                    )}
                  </Grid>
                )}
                {incident_light_type === "GB" && (
                  <Grid item xs={4}>
                    <div test-data={"focal_spot_y"}>
                      <FormLabel
                        style={{ fontSize: 12 }}
                        disabled={disabled}
                        htmlFor="focal_spot_y"
                      >
                        Focal Spot Y
                      </FormLabel>
                    </div>
                    {isSweep ? (
                      <SweepInput
                        name="IncidentLightFocalSpotY"
                        test-data="focal_spot_y"
                        value={focal_spot_y}
                        disabled={disabled && "DISABLE_INPUT"}
                        onChange={this.onFocalSpotYChange}
                        simulationId={this.props.simulationId}
                        sweptVariables={this.props.sweptVariables}
                        allowNegative
                      />
                    ) : (
                      <NumberInput
                        test-data="focal_spot_y"
                        name="focal_spot_y"
                        disabled={disabled}
                        value={focal_spot_y}
                        error={touched.focal_spot_y && errors.focal_spot_y}
                        helperText={touched.focal_spot_y && errors.focal_spot_y}
                        onChange={event =>
                          this.handleUpdateField(
                            event.target.value,
                            "focal_spot_y"
                          )
                        }
                        onBlur={handleBlur}
                      />
                    )}
                  </Grid>
                )}
                {incident_light_type === "GB" && (
                  <Grid item xs={4}>
                    <div test-data={"focal_spot_z"}>
                      <FormLabel
                        style={{ fontSize: 12 }}
                        disabled={disabled}
                        htmlFor="focal_spot_z"
                      >
                        Focal Spot Z
                      </FormLabel>
                    </div>
                    {isSweep ? (
                      <SweepInput
                        name="IncidentLightFocalSpotZ"
                        test-data="focal_spot_z"
                        value={focal_spot_z}
                        disabled={disabled && "DISABLE_INPUT"}
                        onChange={this.onFocalSpotZChange}
                        simulationId={this.props.simulationId}
                        sweptVariables={this.props.sweptVariables}
                        allowNegative
                      />
                    ) : (
                      <NumberInput
                        test-data="focal_spot_z"
                        name="focal_spot_z"
                        disabled={disabled}
                        value={focal_spot_z}
                        error={touched.focal_spot_z && errors.focal_spot_z}
                        helperText={touched.focal_spot_z && errors.focal_spot_z}
                        onChange={event =>
                          this.handleUpdateField(
                            event.target.value,
                            "focal_spot_z"
                          )
                        }
                        onBlur={handleBlur}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withErrorBoundary(withStyles(styles)(IncidentLightForm));
