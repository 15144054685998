import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import StructureSelector from "MetaCell/selectors/Structure";
import MaterialSelector from "MetaCell/selectors/Material";
import GlobalParametersSelector from "MetaCell/selectors/GlobalParameters";
import SimulationSettingsSelector from "MetaCell/selectors/SimulationSettings";
import GenericPlot from "components/Plot/Plot";
import HelperUtils from "MetaCell/helper/HelperUtils";
import StructureHelper from "MetaCell/helper/Structure";

const styles = {
  main: {
    position: "relative",
    width: "100%"
  },
  labels: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%"
  }
};

export class TopView extends Component {
  selectedUsedMaterial;
  width = 0;
  prevWidth = 0;

  getMaterialName = usedMaterialId => {
    let materialName = "",
      usedMaterial,
      material;
    if (usedMaterialId !== 0) {
      usedMaterial = this.props.usedMaterials.byId[usedMaterialId];
      if (usedMaterial) {
        material = this.props.materials.byId[usedMaterial.material];
        if (material && material.name) {
          materialName = material.name;
        }
      }
    }
    return materialName;
  };

  /**
   * @param {Number[]} usedMaterialsIds the ids of all used materials belonging to the selected layer
   * @returns {Object[][]} an array contaning arrays where each array is a color scale
   * the scale should go from 0 to 1. when the layer is homogeneous
   * we say that the single color is at 0 and 1 because at least 2 color scales are required
   * if there is a selected used material it fades the color of the other used materials
   */
  getColors(usedMaterialsIds, usedDiscretizedMatIds) {
    const { usedMaterials, materials, selectedUsedMaterialId } = this.props,
      isHomogeneous = usedMaterialsIds.length === 1,
      isSingleMaterialVisible =
        usedMaterialsIds.length !== 1 && usedDiscretizedMatIds.length === 1;

    function changeOpacity(str) {
      var dec = parseInt(str.substr(-2), 16);
      dec = parseInt(dec / 2);
      var hex = dec.toString(16);
      return str.substr(0, 7) + padToTwo(hex);
    }

    function padToTwo(numberString) {
      if (numberString.length < 2) {
        numberString = "0" + numberString;
      }
      return numberString;
    }

    function getColor(usedMaterialId) {
      const usedMaterial = usedMaterials.byId[usedMaterialId],
        materialId = usedMaterial.material,
        material = materials.byId[materialId];
      return material.color;
    }

    if (isHomogeneous) {
      const usedMaterialId = usedMaterialsIds[0],
        color = getColor(usedMaterialId);
      return [
        [0, color],
        [1, color]
      ];
    }
    if (isSingleMaterialVisible) {
      const usedMaterialId = usedDiscretizedMatIds[0],
        color = getColor(usedMaterialId);
      return [
        [0, color],
        [1, color]
      ];
    }

    // const UsedMatIds = [... new Set(usedDiscretizedMatIds.map(id => usedMaterials.byId[id].MatId))]
    const scales = HelperUtils.getRange(
      1,
      0,
      Math.max(...usedDiscretizedMatIds) -
        Math.min(...usedDiscretizedMatIds) +
        1
    );
    // const scales = [],
    const colorScale = usedMaterialsIds
      .filter(id => usedDiscretizedMatIds.includes(id))
      .map((id, index) => {
        let colorScaleIndex = HelperUtils.getRange(
          Math.max(...usedDiscretizedMatIds),
          Math.min(...usedDiscretizedMatIds),
          scales.length
        ).indexOf(id);
        let color = getColor(id);
        if (
          selectedUsedMaterialId &&
          selectedUsedMaterialId !== -1 &&
          selectedUsedMaterialId !== id
        ) {
          color = changeOpacity(color);
        }
        return [scales[colorScaleIndex], color];
        // return [id, color];
      });
    return colorScale;
  }

  /**
   *
   * @param {Number[][]} z - the z data
   * @returns {String[][]} - a matrix of tooltips where each tooltip is the material name
   */
  getTooltips(z) {
    return z.map(row =>
      row.map(usedMaterialId => {
        return `Material: ${this.getMaterialName(usedMaterialId)}`;
      })
    );
  }

  /**
   * @param {Number[]} axis - a min and a max value
   * @param {Number} numberOfSteps - how many steps the matrix has on the given axis
   * @returns {Number[]} - axis data containing values for each step
   */
  getAxisData(axis, numberOfSteps) {
    const isHomogeneous = numberOfSteps === 1;
    if (isHomogeneous) {
      return axis;
    }
    return HelperUtils.getRange(axis[1], axis[0], numberOfSteps);
  }

  /**
   * @param {String} variableName - the name of a variable
   * @returns {Number} the first value of the variable
   */
  getFirstVariableValue(variableName) {
    const simulationVariables = this.props.sweptVariables.filter(sv => {
      let selectedLayer = this.props.selectedLayerId;
      if (selectedLayer.toString().includes("#")) {
        selectedLayer = this.props.layers.byId[selectedLayer.split("#")[0]][0];
      } else {
        selectedLayer = this.props.layers.byId[this.props.selectedLayerId];
      }
      return sv.simulation == selectedLayer.simulation;
    });
    const sweepVariableNames = simulationVariables.map(
      variable => variable.variableName
    );
    const validVariableName = HelperUtils.extractVariableName(
      variableName,
      sweepVariableNames
    );
    if (validVariableName) {
      const variable = Object.values(simulationVariables).find(
        variable => variable.variableName === validVariableName
      );
      const validVarValue = JSON.parse(variable.values)[0];
      var variableValues = {};
      variableValues[validVariableName] = validVarValue;
      const evaluatedValue = HelperUtils.resolveMathExpression(
        variableName,
        variableValues
      );
      return evaluatedValue;
    } else {
      return 0;
    }
  }

  /**
   * @param {String} variableName - the name of a variable
   * @returns {Number} the first value of the variable
   */
  getLastVariableValue(variableName) {
    const simulationVariables = this.props.sweptVariables.filter(sv => {
      let selectedLayer = this.props.selectedLayerId;
      if (selectedLayer.toString().includes("#")) {
        selectedLayer = this.props.layers.byId[selectedLayer.split("#")[0]][0];
      } else {
        selectedLayer = this.props.layers.byId[this.props.selectedLayerId];
      }
      return sv.simulation == selectedLayer.simulation;
    });
    const sweepVariableNames = simulationVariables.map(
      variable => variable.variableName
    );
    const validVariableName = HelperUtils.extractVariableName(
      variableName,
      sweepVariableNames
    );

    if (validVariableName) {
      const variable = Object.values(simulationVariables).find(
        variable => variable.variableName === validVariableName
      );
      const validVarValues = JSON.parse(variable.values);
      const validVarValue = validVarValues[validVarValues.length - 1];

      var variableValues = {};
      variableValues[validVariableName] = validVarValue;
      const evaluatedValue = HelperUtils.resolveMathExpression(
        variableName,
        variableValues
      );
      return evaluatedValue;
    } else {
      return 0;
    }
  }

  render = () => {
    const {
        showAxis,
        classes,
        selectedLayerId,
        layers,
        globalParameters,
        selectedUsedMaterialId,
        usedMaterials
      } = this.props,
      gp = Object.values(globalParameters.byId)[0],
      axisVisible = showAxis === true;
    const selectedLayer = StructureHelper.getExpandedStaircaseLayers(
      layers
    ).find(layer => layer.id === selectedLayerId);

    let x, y, z, layer, xLabel, yLabel, row, col, imageWidth, imageHeight;

    this.selectedUsedMaterial =
      selectedLayer &&
      selectedLayer.usedmaterial_set.includes(selectedUsedMaterialId) &&
      Object.values(usedMaterials.byId).find(
        ({ id }) => selectedUsedMaterialId === id
      );

    if (gp) {
      if (gp.cellWidth && gp.cellWidth[0] === "=") {
        x = [0, 1];
        const variableName = gp.cellWidth.substring(1, gp.cellWidth.length);
        xLabel = variableName + " [" + gp.unit + "]";
        // imageWidth = this.getFirstVariableValue(variableName);
        imageWidth = this.getLastVariableValue(variableName);
      } else {
        xLabel = " [" + gp.unit + "]";
        imageWidth = Number.parseFloat(gp.cellWidth);
      }
      if (gp.cellHeight && gp.cellHeight[0] === "=") {
        y = [0, 1];
        const variableName = gp.cellHeight.substring(1, gp.cellHeight.length);
        yLabel = variableName + " [" + gp.unit + "]";
        // imageHeight = this.getFirstVariableValue(variableName);
        imageHeight = this.getLastVariableValue(variableName);
      } else {
        yLabel = " [" + gp.unit + "]";
        imageHeight = Number.parseFloat(gp.cellHeight);
      }

      x = [-(imageWidth / 2), imageWidth / 2];
      y = [-(imageHeight / 2), imageHeight / 2];

      // Limit the aspect ratio of the drawing in order to prevent browser errors
      if (imageWidth < imageHeight / 10) {
        imageWidth = imageHeight / 10;
      }

      if (imageHeight < imageWidth / 10) {
        imageHeight = imageWidth / 10;
      }
    }
    const expandedLayers = StructureHelper.getExpandedStaircaseLayers(layers);
    layer = expandedLayers.find(layer => {
      let isStaircase = Array.isArray(
        layers.byId[String(selectedLayerId).split("#")[0]]
      );
      if (!isStaircase && String(selectedLayerId).includes("#"))
        return layer.id == String(selectedLayerId).split("#")[0];
      else return layer.id === selectedLayerId;
    });
    var mat_map = layer.discretized;
    let usedDiscretizedMatIds = [];
    z = mat_map && mat_map !== "" ? JSON.parse(mat_map) : [[0]];

    // because plotly reads matrix from bottom to top we have to flip discretizations
    // built from an image because they are generated from top to bottom
    const isImageStructured = mat_map && !layer.structure;
    if (isImageStructured) {
      z = z.reverse();
    }

    for (row = 0; row < z.length; row++) {
      for (col = 0; col < z[row].length; col++) {
        z[row][col] = layer.usedmaterial_set[z[row][col]];
      }
    }

    if (z) {
      usedDiscretizedMatIds = Array.from(new Set(z.flat()));
    }

    if (
      this.props.width !== this.width &&
      this.props.width !== this.prevWidth
    ) {
      this.prevWidth = this.width;
      this.width = this.props.width;
    }
    let canvasWidth, canvasHeight;
    canvasWidth = this.width;
    canvasHeight = (imageHeight * this.width) / imageWidth;

    return x && y && z ? (
      <div className={classes.main} test-data="topView">
        <GenericPlot
          data={[
            {
              z: z,
              x: this.getAxisData(x, z[0].length),
              y: this.getAxisData(y, z.length),
              type: "heatmap",
              hoverongaps: false,
              showscale: false,
              colorscale: this.getColors(
                layer.usedmaterial_set,
                usedDiscretizedMatIds
              ),
              hoverinfo: "text",
              text: this.getTooltips(z)
            }
          ]}
          layout={{
            width: canvasWidth,
            height: canvasHeight,
            xaxis: {
              title: {
                text: xLabel
              },
              visible: axisVisible
            },
            yaxis: {
              title: {
                text: yLabel
              },
              visible: axisVisible
            },
            margin: {
              l: 50,
              r: 50,
              b: 50,
              t: 50,
              pad: 4
            }
          }}
        />
      </div>
    ) : null;
  };
}

TopView.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  selectedLayerId: PropTypes.number,
  selectedUsedMaterialId: PropTypes.number,
  showAxis: PropTypes.bool
};

const mapState = state => ({
  usedMaterials: StructureSelector.getUsedMaterials(state),
  layers: StructureSelector.getLayers(state),
  materials: MaterialSelector.getMaterials(state),
  globalParameters: GlobalParametersSelector.get(state),
  sweptVariables: SimulationSettingsSelector.getSweptVariables(state)
});

export default connect(mapState, null)(withStyles(styles)(TopView));
