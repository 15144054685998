import React, { PureComponent } from "react";
import UnselfishDialog from "components/UnselfishDialog/UnselfishDialog";
import PropTypes from "prop-types";
import ReactJson from "react-json-view";
import DialogContent from "@material-ui/core/DialogContent";

/**
 * @author Mihai
 */
export default class JsonDialog extends PureComponent {
  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { open, data } = this.props;
    return (
      <UnselfishDialog
        fullWidth
        open={open}
        onClose={this.handleClose}
        maxWidth="sm"
      >
        <DialogContent>
          <ReactJson
            style={{ height: 444 }}
            theme="bright:inverted"
            src={data}
            collapsed={2}
          />
        </DialogContent>
      </UnselfishDialog>
    );
  }
}

JsonDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired
};
