import React, { PureComponent } from "react";
import { withStyles, TextField, Input, Tooltip } from "@material-ui/core";
import EnhancedMaterialTable from "components/EnhancedMaterialTable/EnhancedMaterialTable";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import SweepInput from "components/SweepInput/SweepInput";
import Polarization from "components/Polarization/Polarization";
import NumberInput from "components/NumberInput/NumberInput";
import { row } from "mathjs";

const styles = {
  main: {
    width: "100%",
    boxSizing: "border-box",
    paddingRight: "20px"
  },
  inputTooltip: {
    whiteSpace: "pre-line"
  }
};

export const getColumns = (optimizer_parameters, formErrors) => {
  return optimizer_parameters
    .filter(attribute => attribute.is_visible)
    .map((attribute, index) => {
      return {
        title: (
          <Tooltip title={attribute.description ?? ""}>
            <span>{attribute.label}</span>
          </Tooltip>
        ),
        field: attribute.name,
        editComponent: props => {
          if (attribute.is_sweepable) {
            return (
              <SweepInput
                name={attribute.name}
                disabled={attribute.is_disabled}
                value={props.value ? props.value : ""}
                onChange={props.onChange}
              />
            );
          } else {
            if (attribute.attribute_type === "string") {
              return (
                <TextField
                  name={attribute.name}
                  disabled={attribute.is_disabled}
                  value={props.value ? props.value : ""}
                  onChange={e => props.onChange(e.target.value)}
                />
              );
            } else if (attribute.attribute_type === "number") {
              return (
                <NumberInput
                  name={attribute.name}
                  disabled={attribute.is_disabled}
                  value={props.value ? props.value : ""}
                  onChange={e => props.onChange(e.target.value)}
                  helperText={attribute.description}
                />
              );
            } else if (attribute.attribute_type === "list") {
              return (
                <TextField
                  error={formErrors[attribute.name] != null}
                  name={attribute.name}
                  disabled={attribute.is_disabled}
                  value={props.value ? props.value : ""}
                  onChange={e => props.onChange(e.target.value.split(","))}
                  helperText={
                    formErrors[attribute.name] != null
                      ? formErrors[attribute.name]
                      : attribute.description
                  }
                />
              );
            } else {
              // handle other cases in future if needed
              return (
                <TextField
                  name={attribute.name}
                  disabled={attribute.is_disabled}
                  value={props.value ? props.value : ""}
                  onChange={e => props.onChange(e.target.value)}
                />
              );
            }
          }
        },
        render: rowData => {
          if (attribute.attribute_type === "list") {
            return <span>{JSON.stringify(rowData[attribute.name])}</span>;
          } else {
            return <span>{rowData[attribute.name]}</span>;
          }
        }
      };
    });
};

export class OptimizerParametersTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {}
    };
  }

  onSaveOptimizerConfiguration = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      const { onSaveOptimizerConfiguration, optimizerParameters } = this.props;
      var errors = null;
      this.setState({ formErrors: {} }, () => {
        Object.keys(newData).forEach(key => {
          const optimizerParameter = optimizerParameters.find(
            parameter => parameter.name === key
          );
          if (optimizerParameter.js_validator != null) {
            const parsed_regex = new RegExp(optimizerParameter.js_validator);
            if (optimizerParameter.attribute_type === "list") {
              let newList = [];
              newData[key].every((element, index) => {
                if (!parsed_regex.test(element)) {
                  errors[key] = `Invalid value at position ${index +
                    1} in list`;
                  // interript checking
                  return false;
                }
                // continue checking
                return true;
              });
              newData[key] = newList;
            } else {
              if (!parsed_regex.test(newData[key])) {
                errors[key] = "Invalid value";
              }
            }
          }
        });
      });

      if (errors) {
        this.setState({ formErrors: errors }, reject());
      } else {
        onSaveOptimizerConfiguration(newData)
          .then(data => {
            resolve();
          })
          .catch(e => {
            reject(e);
          });
      }
    });
  };

  render = () => {
    const { classes, optimizerParameters, optimizerConfiguration } = this.props;
    const { formErrors } = this.state;
    return (
      <div>
        <EnhancedMaterialTable
          options={{
            search: false,
            paging: false,
            sorting: false,
            draggable: false,
            maxBodyHeight: 350,
            actionsColumnIndex: -1
          }}
          title="Optimization Parameters"
          columns={getColumns(optimizerParameters, formErrors)}
          data={[optimizerConfiguration]}
          editable={{
            // onRowAdd: this.onRowAdd,
            onRowUpdate: this.onSaveOptimizerConfiguration
            // onRowDelete: this.onRowDelete
          }}
        />
      </div>
    );
  };
}
export default withErrorBoundary(withStyles(styles)(OptimizerParametersTable));
