import Axios from "axios";
import store from "store";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";
import HelperUtils from "MetaCell/helper/HelperUtils";

/**
 * A generic class to be used by Api services
 * @author Akira Kotsugai
 */
export default class GenericApi {
  static getBaseUrl = () => {
    return process.env.REACT_APP_API_URL || `${window.location.origin}/api`;
  };

  /**
   * @returns the report bug endpoint url
   */
  static getReportBugUrl = () => `${GenericApi.getBaseUrl()}/logs/report_bug/`;

  static arbitraryFileUploadUrl = () =>
    `${GenericApi.getBaseUrl()}/arbitrary/get_file_upload_url`;
  /**
   * it runs an api call promise but asks the user if he wants to report the bug if the promise fails.
   * in this case, a popup is shown with the a generic message, or a custom message if it was passed.
   * @param {Promise} apiCall - a promise to make the api call
   * @param {String} errorMessage - the optional error message
   * @returns {Promise} the response promise
   */
  static runApiCall = (apiCall, errorMessage) => {
    return apiCall.catch(error => {
      const dialogTitle =
        error.response.status === 503
          ? error.response.data.message
            ? error.response.data.message
            : error.response.data
          : errorMessage
          ? errorMessage
          : "Server error";
      var dialogMessage = "Would you like to report this?";
      var extraInfo = "";
      if (error.response?.data?.extra_info) {
        extraInfo += `${error.response.data.extra_info}`;
      }
      if (error.response?.data?.disable_reporting) {
        dialogMessage = extraInfo;
      } else {
        dialogMessage = extraInfo + dialogMessage;
      }
      let dialogConfirmAction = () => {};
      if (!error.response?.data?.disable_reporting) {
        dialogConfirmAction = () => this.reportBug(error);
      }
      store.dispatch(
        ConfirmDialogAction.show(
          dialogTitle,
          dialogMessage,
          dialogConfirmAction,
          () => {},
          false
        )
      );
      return Promise.reject(error);
    });
  };

  /***
   * it reports the error to the backend endpoint
   * @param {Object} error - the error object
   * @param {String} userEmail - the email adress of the user who reported the bug
   * @param {String} description - the description of the bug given by the user
   * @param {File} file - a json file given by the user
   */
  static reportBug = (error, userEmail, description, file) => {
    const reduxState = store.getState(),
      simulations = DirectoryExplorerSelector.getSimulations(reduxState),
      openSimulationId = DirectoryExplorerSelector.getSimulationOpenId(
        reduxState
      ),
      openSimulation = simulations.byId[openSimulationId],
      data = {
        simulationId: openSimulationId,
        simulation: openSimulation ? openSimulation.name : "none",
        message: error?.message,
        stacks: [error?.stack],
        userEmail,
        description,
        file
      };
    return Axios.post(
      this.getReportBugUrl(),
      HelperUtils.getFormData(data)
    ).then(response => response.data);
  };

  static getObjectUploadUrl = fileName => {
    return Axios.get(this.arbitraryFileUploadUrl() + `?fileName=${fileName}`);
  };
}
