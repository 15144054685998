import React, { PureComponent } from "react";
import GenericPlot from "components/Plot/Plot";
import HelperUtils from "MetaCell/helper/HelperUtils";
import CustomizeHeatmapColorscale from "./components/CustomizeScatterColorscale";
import { cloneDeep } from "lodash";

/**
 * a generic Scatter component
 * @author Harshal M
 */
export default class ScatterPlot extends PureComponent {
  state = {
    customxAxisRange: null,
    customyAxisRange: null,
    customzAxisRange: null,
    openScatterColorscaleDialog: false,
    customScatterColorscale: null
  };

  handleCustomRange = (xAxisRange, yAxisRange, zAxisRange) => {
    this.setState({
      customxAxisRange: xAxisRange,
      customyAxisRange: yAxisRange,
      customzAxisRange: zAxisRange
    });
  };

  scaleAxisRange = axisRange => {
    let rangeStart = axisRange[0];
    let rangeEnd = axisRange[1];
    if (rangeStart > 0) rangeStart = rangeStart * 0.8;
    else rangeStart = rangeStart * 1.2;

    if (rangeEnd > 0) rangeEnd = rangeEnd * 1.2;
    else rangeEnd = rangeEnd * 0.8;

    return [rangeStart, rangeEnd];
  };

  getAxisRange = dataArray => {
    return [Math.min(...dataArray), Math.max(...dataArray)];
  };

  handleScatterColorscaleDialogOpen = () => {
    this.setState({ openScatterColorscaleDialog: true });
  };

  handleScatterColorscaleDialogClose = () => {
    this.setState({ openScatterColorscaleDialog: false });
  };

  handleCustomScatterColorScale = colorscale => {
    this.setState({ customScatterColorscale: colorscale });
  };

  filterCustomColorRange = (colorsArr, normalise = false) => {
    return this.state.customzAxisRange
      ? HelperUtils.filterColorRange(
        this.state.customzAxisRange[0],
        this.state.customzAxisRange[1],
        colorsArr,
        normalise
      )
      : colorsArr;
  };

  getZmin = () => {
    return this.state.customzAxisRange
      ? Math.min(...this.state.customzAxisRange)
      : this.props.color
        ? Math.min(...this.props.color)
        : 0;
  };

  getZmax = () => {
    return this.state.customzAxisRange
      ? Math.max(...this.state.customzAxisRange)
      : this.props.color
        ? Math.max(...this.props.color)
        : 0;
  };

  getColors = (fill = true) => {
    const { x, y, color, selectedPoints } = this.props;
    var customColors = null;
    if (color)
      if (fill)
        customColors = this.filterCustomColorRange(cloneDeep(color), true);
      else
        customColors = Array(Math.max(x.length, y.length)).fill("transparent");
    else {
      if (fill)
        customColors = Array(Math.max(x.length, y.length)).fill("#1f77b4");
      else
        customColors = Array(Math.max(x.length, y.length)).fill("transparent");
    }
    if (selectedPoints) {
      selectedPoints.forEach(point => {
        var pointIndex = null;
        for (var i = 0; i < x.length; i++) {
          if (x[i] == point.x && y[i] == point.y) {
            pointIndex = i;
            break;
          }
        }
        if (pointIndex !== null && pointIndex !== undefined)
          customColors[pointIndex] = "black";
      });
    }

    return customColors;
  };

  render() {
    const isColors = this.props.color;
    const defaultColor = "Jet";
    return (
      <>
        {isColors ? (
          <GenericPlot
            onClick={this.props.onClick}
            handleCustomRange={this.handleCustomRange}
            data={[
              {
                x: this.props.x,
                y: this.props.y,
                // z: this.filterCustomColorRange(this.props.color),
                z: this.props.color,
                type: "scatter",
                text: this.props.color?.map(val => `z=${val}`),
                mode: "markers",
                hoverongaps: false,
                zmin: this.getZmin(),
                zmax: this.getZmax(),
                marker: {
                  colorscale:
                    this.state.customScatterColorscale || defaultColor,
                  // color: this.filterCustomColorRange(this.props.color, true),
                  color: this.props.color,
                  line: {
                    color: this.getColors(false),
                    width: 2
                  },
                  cmin: this.getZmin(),
                  cmax: this.getZmax(),
                  colorbar: this.props.zName
                    ? {
                      title: this.props.zName,
                      titleside: "right"
                    }
                    : null
                }
              }
            ]}
            layout={{
              width: this.props.width,
              height: this.props.height,
              xaxis: {
                autorange: this.props.xAxisRange || this.state.customxAxisRange,
                title: {
                  text: HelperUtils.getAxisName(
                    this.props.xLabel,
                    this.props.xUnit
                  )
                },
                range: this.state.customxAxisRange
                  ? this.state.customxAxisRange
                  : this.props.xAxisRange
                    ? this.props.xAxisRange
                    : this.getAxisRange(this.props.x)
              },
              yaxis: {
                autorange: this.props.yAxisRange || this.state.customyAxisRange,
                title: {
                  text: HelperUtils.getAxisName(
                    this.props.yLabel,
                    this.props.yUnit
                  )
                },
                range: this.state.customyAxisRange
                  ? this.state.customyAxisRange
                  : this.props.yAxisRange
                    ? this.props.yAxisRange
                    : this.getAxisRange(this.props.y)
              },
              margin: {
                l: 50,
                r: 50,
                b: 50,
                t: 50,
                pad: 4
              }
            }}
            morePlotlyModeBarButtonsToAdd={[
              {
                name: "Change Scatter Plot Color",
                icon: {
                  width: 1792,
                  height: 1792,
                  path:
                    "M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z",
                  transform: "matrix(1 0 0 1 0 1)"
                },
                click: this.handleScatterColorscaleDialogOpen
              }
            ]}
          />
        ) : (
          <GenericPlot
            onClick={({ event, points }) => {
              var modifiedPoints = [...points];
              delete modifiedPoints[0]["marker.color"];
              this.props.onClick({ event, points: modifiedPoints });
            }}
            handleCustomRange={this.handleCustomRange}
            data={[
              {
                x: this.props.x,
                y: this.props.y,
                z: this.props.color,
                type: "scatter",
                text: this.props.color?.map(val => `z=${val}`),
                mode: "markers",
                hoverongaps: false,
                marker: {
                  // size: 5,
                  colorscale: this.props.color,
                  color: this.props.color,
                  line: {
                    color: this.getColors(false),
                    width: 2
                  },
                  colorbar: this.props.zName
                    ? {
                      title: this.props.zName,
                      titleside: "right"
                    }``
                    : null
                }
              }
            ]}
            layout={{
              width: this.props.width,
              height: this.props.height,
              xaxis: {
                autorange: this.props.xAxisRange || this.state.customxAxisRange,
                title: {
                  text: HelperUtils.getAxisName(
                    this.props.xLabel,
                    this.props.xUnit
                  )
                },
                range: this.state.customxAxisRange
                  ? this.state.customxAxisRange
                  : this.props.xAxisRange
                    ? this.props.xAxisRange
                    : this.getAxisRange(this.props.x)
              },
              yaxis: {
                autorange: this.props.yAxisRange || this.state.customyAxisRange,
                title: {
                  text: HelperUtils.getAxisName(
                    this.props.yLabel,
                    this.props.yUnit
                  )
                },
                range: this.state.customyAxisRange
                  ? this.state.customyAxisRange
                  : this.props.yAxisRange
                    ? this.props.yAxisRange
                    : this.getAxisRange(this.props.y)
              },
              margin: {
                l: 50,
                r: 50,
                b: 50,
                t: 50,
                pad: 4
              }
            }}
          />
        )}
        <CustomizeHeatmapColorscale
          handleCustomScatterColorScale={this.handleCustomScatterColorScale}
          open={this.state.openScatterColorscaleDialog}
          handleClose={this.handleScatterColorscaleDialogClose}
        ></CustomizeHeatmapColorscale>
      </>
    );
  }
}
