import {
  plotOutputParams,
  plotOutputParamLabels,
  plotOutputParamDescriptions
} from "MetaCell/containers/SimulateCanvas/components/Plotbox";
import SimulationApi from "MetaCell/api/Simulation";

/**
 * A helper class to help components that are related to plotboxes.
 * @author Akira Kotsugai
 */
export default class PlotboxHelper {
  /**
   * it splits the output param values and create a drilldown for each.
   * if the drilldown still has no value, it creates a default value where only the polarization varies.
   * @param {String} name - which plot param it is
   * @param {String} outputValues - the output values separated by slashes
   * @param {String} polarization - the polarization used in the simulation job
   * @returns {Object[]} the drilldowns
   */
  static createOutputDrillDowns = (name, outputValues, polarization) => {
    const usingJonesOrStokes = polarization.includes("/");
    const defaultValues = [
        "T",
        "0,0",
        usingJonesOrStokes ? "TE" : polarization,
        "power_coeff",
        "Abs"
      ],
      fieldValues = outputValues ? outputValues.split("/") : defaultValues;
    return plotOutputParams.map((param, index) => ({
      name,
      label: plotOutputParamLabels[param],
      value:
        fieldValues[index] === "None"
          ? defaultValues[index]
          : fieldValues[index],
      data: null,
      error: "",
      isSweep: false,
      description: plotOutputParamDescriptions[param],
      disabled: false
    }));
  };

  /**
   * it assigns the right axis to the output drilldowns and unassign the y axis from
   * the sweep drilldowns if a 2D plot was selected
   * @param {Object[]} drilldowns - the drilldown values
   * @param {*} newPlotType - the new plot type
   */
  static convertDrilldownsPlotType(drilldowns, newPlotType, scatterOptions) {
    const outputConvertedDrilldowns = this.convertDrilldownsOutputValues(
      drilldowns,
      newPlotType
    );
    return outputConvertedDrilldowns.map(drilldown => {
      if (drilldown.isSweep && newPlotType === "2D") {
        return {
          ...drilldown,
          value:
            drilldown.value === "y" ||
            drilldown.value === "all" ||
            drilldown.value === "color"
              ? ""
              : drilldown.value
        };
      }
      if (drilldown.isSweep && newPlotType === "3D") {
        return {
          ...drilldown,
          value:
            drilldown.value === "all" || drilldown.value === "color"
              ? ""
              : drilldown.value
        };
      }
      if (drilldown.isSweep && newPlotType === "SC") {
        if (
          (drilldown.value === "y" && scatterOptions.y === "output") ||
          (drilldown.value === "x" && scatterOptions.x === "output") ||
          (drilldown.value === "color" && scatterOptions.color === "null")
        ) {
          return { ...drilldown, error: "", value: "" };
        }
      }
      return drilldown;
    });
  }

  /**
   * it assigns the right axis to the output drilldowns
   * @param {Object[]} drilldowns - the drilldown values
   * @param {*} newPlotType - the new plot type
   */
  static convertDrilldownsOutputValues(drilldowns, newPlotType) {
    if (newPlotType === "SC") {
      let newDrillDowns = [
        ...drilldowns
          .filter(drilldown => !drilldown.isSweep)
          .map(drilldown => {
            return { ...drilldown, name: "output_x" };
          }),
        ...drilldowns
          .filter(drilldown => !drilldown.isSweep)
          .map(drilldown => {
            return { ...drilldown, name: "output_y" };
          }),
        ...drilldowns
          .filter(drilldown => !drilldown.isSweep)
          .map(drilldown => {
            return { ...drilldown, name: "output_color" };
          })
      ];
      return [
        ...newDrillDowns,
        ...drilldowns
          .filter(drilldown => drilldown.isSweep)
          .map(drilldown => {
            return { ...drilldown, error: "", value: "all" };
          })
      ];
    } else {
      let scDrilldowns = [];
      if (newPlotType === "2D") {
        scDrilldowns = drilldowns.filter(drilldown =>
          drilldown.name.includes("output_x")
        );
      } else if (newPlotType === "3D") {
        scDrilldowns = drilldowns.filter(drilldown =>
          drilldown.name.includes("output_y")
        );
      }
      let convertedScDrilldowns = scDrilldowns.map(drilldown => {
        return {
          ...drilldown,
          name: newPlotType === "3D" ? "z" : "y"
        };
      });
      return [
        ...convertedScDrilldowns,
        ...drilldowns
          .filter(drilldown => !drilldown.name.includes("output_"))
          .map(drilldown => {
            const isResultAxis =
              (newPlotType === "3D" && drilldown.name === "y") ||
              (newPlotType === "2D" && drilldown.name === "z");
            return isResultAxis
              ? {
                  ...drilldown,
                  name: newPlotType === "3D" ? "z" : "y"
                }
              : drilldown;
          })
      ];
    }
  }

  /**
   * it rotates a matrix clockwise
   * @param {Number[][]} matrix - the matrix
   * @returns the new rotate matrix
   */
  static rotateMatrixAntiCWandFlip = matrix => {
    let result = [];
    for (let i = 0; i < matrix[0].length; i++) {
      let row = matrix.map(e => e[i]);
      result.push(row);
    }
    return result;
  };

  /**
   * it returns the max size if the x range is greater than y range
   * otherwise it returns the proportional size limited by a 2x1 aspect ratio
   * @param {Number} maxSize - the max dimension size of the heatmap
   * @param {Number[]} xData - the x data array
   * @param {Number[]} yData - the y data array
   * @returns the heatmap width
   */
  static getHeatmapWidth = (maxSize, xData, yData) => {
    const xRange = xData[xData.length - 1] - xData[0],
      yRange = yData[yData.length - 1] - yData[0];
    if (xRange > yRange) {
      return maxSize;
    } else {
      const proportionalWidth = (xRange * maxSize) / yRange,
        minimumSize = (maxSize / 4) * 3;
      return proportionalWidth > minimumSize ? proportionalWidth : minimumSize;
    }
  };

  /**
   * it returns the max size if the y length is greater than x length
   * otherwise it returns the proportional size limited by a 2x1 aspect ratio
   * @param {Number} maxSize - the max dimension size of the heatmap
   * @param {Number} xData - the x data array
   * @param {Number} yData - the y data array
   * @returns the heatmap height
   */
  static getHeatmapHeight = (maxSize, xData, yData) => {
    const xRange = xData[xData.length - 1] - xData[0],
      yRange = yData[yData.length - 1] - yData[0];
    if (yRange > xRange) {
      return maxSize;
    } else {
      const proportionalHeight = (yRange * maxSize) / xRange,
        minimumSize = (maxSize / 4) * 3;
      return proportionalHeight > minimumSize
        ? proportionalHeight
        : minimumSize;
    }
  };

  /**
   * it scraps the plot data to find the variables and its values
   * @param {Number} clickedX - the x value clicked
   * @param {*} clickedY - the y value clicked
   * @param {*} plotData - the plot data
   * @returns {Object} - an object where the keys are the variable names and the values are the variable values
   */
  static getSweepVariableValues = async (
    clickedX,
    clickedY,
    clickedPointIndex,
    plotData
  ) => {
    const {
      id,
      plotType,
      xName,
      yName,
      sliderData,
      rangeValue,
      sliderName,
      fixed,
      scatterOptions
    } = plotData;
    let sweepVariableValues = {};
    if (plotType === "SC") {
      const scatterPointApiResult = await SimulationApi.getJobPlotScatterPoint(
        id,
        clickedPointIndex
      );
      sweepVariableValues = scatterPointApiResult?.data;
    } else {
      sweepVariableValues = {
        [xName]: clickedX
      };
      // when it is a 2D plot the y data is the results
      if (plotType === "3D") {
        sweepVariableValues[yName] = clickedY;
      }
    }
    if (sliderData && sliderData.length > 0) {
      sliderName.split(",").map((sName, sIndex) => {
        sweepVariableValues[sName] = sliderData[sIndex][rangeValue[sIndex] - 1];
      });
    }
    if (fixed && fixed.length > 0) {
      fixed.forEach(fixedValue => {
        const [name, value] = fixedValue.split("/");
        sweepVariableValues[name] = parseFloat(value);
      });
    }
    return sweepVariableValues;
  };

  /**
   * @param {Object} sweepVariableValues - object in which keys are variable names and values are values.
   * @returns {String} formatted text
   */
  static formatSweepVariableValues = sweepVariableValues => {
    const formattedSweepVariableValues = sweepVariableValues
      ? Object.entries(sweepVariableValues).map(
          entry => `${entry[0]} = ${entry[1]}`
        )
      : [];
    return `${formattedSweepVariableValues.join("; ")};`;
  };
}
