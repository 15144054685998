import React, { PureComponent } from "react";

/**
 * it shows the pdf tutorial
 * @author Akira Kotsugai
 */
export class DocumentViewer extends PureComponent {
  render() {
    return (
      <iframe
        test-data="document_frame"
        src={this.props.content}
        frameborder="1"
        scrolling="auto"
        height="100%"
        width="100%"
      ></iframe>
    );
  }
}

export default DocumentViewer;
