import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import EnhancedMaterialTable from "components/EnhancedMaterialTable/EnhancedMaterialTable";
import HelperUtils from "MetaCell/helper/HelperUtils";
import { MenuItem, Select } from "@material-ui/core";

const styles = theme => ({
  title: {
    float: "left"
  }
});

export class TargetsView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected_optimizer: null
    };
  }

  /**
   * it checks if the focused design target exists
   */
  getSelectedEntityId() {
    const { targetsList, focusedSimulationTargetId } = this.props;
    return targetsList.some(target => target.id === focusedSimulationTargetId)
      ? focusedSimulationTargetId
      : -1;
  }

  getColums = () => {
    const { availableOptimizers } = this.props;
    const columns = [
      {
        title: "Name",
        field: "name",
        customSort: (a, b) => HelperUtils.caseInsensitiveSort(a.name, b.name)
      },
      {
        title: "Optimizer",
        field: "optimizer",
        editComponent: props => {
          return (
            <Select
              value={props.value || null}
              inputProps={{
                name: "OptimizerOptions"
              }}
              onChange={e => {
                if (e.target.value === null) {
                  props.onChange(null);
                  return;
                }
                this.setState({
                  selected_optimizer: availableOptimizers.find(
                    availableOptimizer =>
                      availableOptimizer.id == e.target.value
                  )
                });
                props.onChange(e.target.value);
              }}
            >
              <MenuItem key={null} value={null}>
                <em>Sweep Validation</em>
              </MenuItem>
              {availableOptimizers.map(optimizer => (
                <MenuItem
                  key={optimizer.id}
                  value={optimizer.id}
                  name="OptimizerSelectOptions"
                >
                  {optimizer.name}
                </MenuItem>
              ))}
            </Select>
          );
        },
        render: rowData => {
          const text = rowData.optimizer
            ? availableOptimizers.find(
                optimizer => optimizer.id == rowData.optimizer
              )?.name
            : "Sweep Validation";
          return <div>{text}</div>;
        }
      }
    ];
    return columns;
  };

  render() {
    const {
      targetsList,
      focusOnSimulationTarget,
      onSimulationTargetUpdate
    } = this.props;
    return (
      <EnhancedMaterialTable
        title=""
        selectedEntityId={this.getSelectedEntityId()}
        selectEntity={focusOnSimulationTarget}
        disableDoubleClick
        columns={this.getColums()}
        data={targetsList}
        options={{
          search: false,
          paging: false,
          sorting: false,
          draggable: false,
          toolbar: false,
          actionsColumnIndex: -1
        }}
        editable={{
          // onRowAdd: this.onRowAdd,
          onRowUpdate: onSimulationTargetUpdate
          // onRowDelete: this.onRowDelete
        }}
      />
    );
  }
}

export default withStyles(styles)(TargetsView);
