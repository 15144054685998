import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@material-ui/core";
import HeatmapSide from "./components/HeatmapSide";
import HeatmapCrossSection from "./components/HeatmapCrossSection";
import HeatmapTop from "./components/HeatmapTop";
import HelperUtils from "MetaCell/helper/HelperUtils";
import CustomizeHeatmapColorscale from "./components/CustomizeHeatmapColorscale";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
/**
 * a generic heatmap component
 * @author Akira Kotsugai
 */
export default class GenericHeatmap extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSideView: false,
      showCrossSection: false,
      customxAxisRange: null,
      customyAxisRange: null,
      customzAxisRange: null,
      openHeatmapColorscaleDialog: false,
      customHeatmapColorscale: null
    };
  }

  // getZDataRange(zData) {
  //   debugger;
  //   let maxValue = Number.NEGATIVE_INFINITY;
  //   let minValue = Number.POSITIVE_INFINITY;
  //   for (const matrix of zData) {
  //     const matrixRange = HelperUtils.getMatrixRange(matrix);
  //     const currentMin = matrixRange[0];
  //     const currentMax = matrixRange[1];
  //     minValue = currentMin < minValue ? currentMin : minValue;
  //     maxValue = currentMax > maxValue ? currentMax : maxValue;
  //   }
  //   return [minValue, maxValue];
  // }

  handleCustomRange = (xAxisRange, yAxisRange, zAxisRange) => {
    this.setState({
      customxAxisRange: xAxisRange,
      customyAxisRange: yAxisRange,
      customzAxisRange: zAxisRange
    });
  };

  getAxisRange = dataArray => {
    return [Math.min(...dataArray), Math.max(...dataArray)];
  };

  getLayout = () => {
    return {
      width: this.props.width,
      height: this.props.height,
      title: this.props.title ? this.props.title : false,
      xaxis: {
        autorange: this.props.xAxisRange || this.state.customxAxisRange,
        title: {
          text: this.props.xLabel
        },
        range: this.state.customxAxisRange
          ? this.state.customxAxisRange
          : this.props.xAxisRange
          ? this.props.xAxisRange
          : this.getAxisRange(this.props.x)
      },
      yaxis: {
        autorange: this.props.yAxisRange || this.state.customyAxisRange,
        title: {
          text: this.props.yLabel
        },
        range: this.state.customyAxisRange
          ? this.state.customyAxisRange
          : this.props.yAxisRange
          ? this.props.yAxisRange
          : this.getAxisRange(this.props.y)
      },
      margin: !this.props.title
        ? {
            l: 50,
            r: 50,
            b: 50,
            t: 50,
            pad: 4
          }
        : undefined,
      legend: {
        x: 1.05,
        y: 1.05
      },
      annotations: this.props.annotations
    };
  };

  handleHeatmapColorscaleDialogOpen = () => {
    this.setState({ openHeatmapColorscaleDialog: true });
  };

  handleHeatmapColorscaleDialogClose = () => {
    this.setState({ openHeatmapColorscaleDialog: false });
  };

  handleCustomHeatmapColorScale = colorscale => {
    this.setState({ customHeatmapColorscale: colorscale });
  };

  getData = heatmapType => {
    const defaultColorScale = "Jet";
    return [
      {
        z: this.props.z,
        x: this.props.x,
        y: this.props.y,
        type: heatmapType,
        hoverongaps: false,
        colorscale: this.state.customHeatmapColorscale || defaultColorScale,
        showlegend: this.props.showLegend || false,
        name: this.props.valueUnit || "",
        zmin: this.state.customzAxisRange
          ? this.state.customzAxisRange[0]
          : this.props.zmin
          ? this.props.zmin
          : HelperUtils.getMatrixRange(this.props.z)[0],
        zmax: this.state.customzAxisRange
          ? this.state.customzAxisRange[1]
          : this.props.zmax
          ? this.props.zmax
          : HelperUtils.getMatrixRange(this.props.z)[1]
      }
    ];
  };

  render() {
    const {
      disableSideView,
      enableCrossSection,
      crossSectionRowsCount,
      crossSectionColumnsCount,
      crossSectionOrientation,
      crossSectionIndex,
      handleCrossSection,
      hideHeatMapCrossSectionOrientationLabel
    } = this.props;
    const { showSideView, showCrossSection } = this.state;
    return (
      <>
        <Grid container spacing={0} direction="row" justify="center">
          {!disableSideView && (
            <Grid item xs={5}>
              <Button
                test-data="toggleSideView"
                variant="contained"
                style={{ paddingLeft: "6px", paddingRight: "6px" }}
                onClick={() => this.setState({ showSideView: !showSideView })}
                disabled={showCrossSection}
              >
                {showSideView ? "Show top" : "Show side"}
              </Button>
            </Grid>
          )}
          {enableCrossSection && (
            <Grid item xs={7}>
              <Button
                test-data="toggleCrossSection"
                variant="contained"
                style={{ paddingLeft: "5px", paddingRight: "5px" }}
                onClick={() => {
                  this.setState({ showCrossSection: !showCrossSection });
                  if (this.props.toggleProbeSliderVisibility) {
                    this.props.toggleProbeSliderVisibility();
                  }
                }}
              >
                Cross Section
                {showCrossSection ? (
                  <VisibilityOff style={{ marginLeft: "5px" }} />
                ) : (
                  <Visibility style={{ marginLeft: "10px" }} />
                )}
              </Button>
            </Grid>
          )}
        </Grid>
        {showCrossSection ? (
          <HeatmapCrossSection
            rowsCount={crossSectionRowsCount}
            columnsCount={crossSectionColumnsCount}
            handleCrossSection={handleCrossSection}
            crossSectionOrientation={crossSectionOrientation}
            crossSectionIndex={crossSectionIndex}
            width={this.props.width}
            height={this.props.height}
            xLabel={this.props.xLabel}
            yLabel={this.props.yLabel}
            crossSection3DUnit={this.props.crossSection3DUnit}
            crossSectionColorbarUnit={this.props.crossSectionColorbarUnit}
            hideHeatMapCrossSectionOrientationLabel={
              hideHeatMapCrossSectionOrientationLabel
            }
            xData={this.props.x}
            yData={this.props.y}
          />
        ) : showSideView ? (
          <HeatmapSide
            handleCustomRange={this.handleCustomRange}
            getData={this.getData}
            layout={this.getLayout()}
          />
        ) : (
          <div>
            <HeatmapTop
              onHeatmapClick={this.props.onHeatmapClick}
              getData={this.getData}
              layout={this.getLayout()}
              handleCustomRange={this.handleCustomRange}
              onGetUnwrappedOutputData={this.props.onGetUnwrappedOutputData}
              morePlotlyModeBarButtonsToAdd={[
                {
                  name: "Change heatmap color",
                  icon: {
                    width: 1792,
                    height: 1792,
                    path:
                      "M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z",
                    transform: "matrix(1 0 0 1 0 1)"
                  },
                  click: this.handleHeatmapColorscaleDialogOpen
                }
              ]}
            />
            <CustomizeHeatmapColorscale
              handleCustomHeatmapColorScale={this.handleCustomHeatmapColorScale}
              open={this.state.openHeatmapColorscaleDialog}
              handleClose={this.handleHeatmapColorscaleDialogClose}
            ></CustomizeHeatmapColorscale>
          </div>
        )}
      </>
    );
  }
}

GenericHeatmap.propTypes = {
  /**
   * whether the side view button should be hidden
   */
  disableSideView: PropTypes.bool,
  /**
   * the heatmap z data
   */
  z: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  /**
   * the heatmap x data
   */
  x: PropTypes.arrayOf(PropTypes.number).isRequired,
  /**
   * the heatmap y data
   */
  y: PropTypes.arrayOf(PropTypes.number).isRequired,
  /**
   * the heatmap width
   */
  width: PropTypes.number.isRequired,
  /**
   * the heatmap height
   */
  height: PropTypes.number.isRequired,
  /**
   * the heatmap title
   */
  title: PropTypes.string.isRequired,
  /**
   * the heat map x axis label
   */
  xLabel: PropTypes.string.isRequired,
  /**
   * the heat map y axis label
   */
  yLabel: PropTypes.string.isRequired
};
