/**
 * @constant
 * @typedef {Object} IncidentLightActionType
 * @property {String} SET_INCIDENT_LIGHT_PARAMETERS - tells the reducer to set incident light
 * @property {String} CHANGE_EDITING_FIELD - tells the reducer to change the field value of the editing state
 * @property {String} RESET_INCIDENT_LIGHT  - tells the reducer to set the default state
 * @global
 * it defines incident light's type of actions.
 */
export const actionType = Object.freeze({
  SET_INCIDENT_LIGHT_PARAMETERS: "SET_INCIDENT_LIGHT_PARAMETERS",
  CHANGE_INCIDENT_LIGHT_EDITING_FIELD: "CHANGE_INCIDENT_LIGHT_EDITING_FIELD",
  RESET_INCIDENT_LIGHT: "RESET_INCIDENT_LIGHT",
  UPDATE_INCIDENT_LIGHT_PARAMETER: "UPDATE_INCIDENT_LIGHT_PARAMETER",
  UPDATE_POLARIZATION_HISTORY: "UPDATE_POLARIZATION_HISTORY"
});

/**
 * an action creator class for incident light to be dispatched to redux
 * It is already tested with incident light reducer, so it does not need to be unit tested.
 * It consists of a instruction {@link IncidentLightActionType} and some data.
 * @author Akira Kotsugai
 */
export default class IncidentLightAction {
  static set = value => ({
    type: actionType.SET_INCIDENT_LIGHT_PARAMETERS,
    payload: value
  });

  static changeEditingField = value => ({
    type: actionType.CHANGE_INCIDENT_LIGHT_EDITING_FIELD,
    payload: value
  });

  static reset = () => ({
    type: actionType.RESET_INCIDENT_LIGHT,
    payload: {}
  });

  static update = obj => ({
    type: actionType.UPDATE_INCIDENT_LIGHT_PARAMETER,
    payload: obj
  });

  static updatePolarizationHistory = obj => ({
    type: actionType.UPDATE_POLARIZATION_HISTORY,
    payload: obj
  });
}
