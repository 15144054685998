import React, { PureComponent } from "react";
import { withStyles, Tooltip, Typography } from "@material-ui/core";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import HelpIcon from "@material-ui/icons/Help";
import SweepInput from "components/SweepInput/SweepInput";
import { cloneDeep } from "lodash";
import Proptypes from "prop-types";
import EnhancedMaterialTable from "components/EnhancedMaterialTable/EnhancedMaterialTable";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import StructureCombination from "./components/StructureCombination/StructureCombination";
import LoadingOverlay from "components/LoadingOverlay/LoadingOverlay";
import LayerUsedMaterials from "../LayerUsedMaterials/LayerUsedMaterials";

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    position: "relative"
  },
  helpIcon: {
    fontSize: 16,
    marginLeft: 3,
    color: "#3f51b5"
  },
  helpMessage: {
    maxWidth: 500,
    whiteSpace: "pre-line"
  },
  parameter: {
    display: "flex",
    alignItems: "center",
    textAlign: "left"
  },
  globalParametersTable: {
    marginBottom: "10px"
  }
});

/**
 * A component to show a layer's parameterized structure
 * @author Akira Kotsugai
 */

export class ParameterizedStructure extends PureComponent {
  constructor(props) {
    super(props);
    this.globalParameters = ["num_steps"];
    const { classes } = props;
    this.state = {
      saving: false,
      selectedItemIndex: 0,
      error: null,
      data: null,
      columns: [
        {
          title: "Parameter",
          field: "name",
          editable: "never",
          render: rowData => {
            return (
              <div className={classes.parameter}>
                <span>{rowData.label}</span>
                <Tooltip
                  title={rowData.description}
                  placement="top"
                  classes={{ tooltip: classes.helpMessage }}
                >
                  <HelpIcon className={classes.helpIcon} />
                </Tooltip>
              </div>
            );
          }
        },
        {
          title: "Value",
          field: "value",
          editComponent: props => {
            const ref = React.createRef(),
              { value, rowData } = props,
              isArray = value instanceof Array,
              isCoordinates = rowData.name.includes("coord"),
              isAngle = rowData.name.includes("angle"),
              isOffset = rowData.name === "ver_offset";
            return isArray ? (
              <div className={classes.parameter}>
                <span style={{ marginRight: 5 }}>{"x:"}</span>
                <SweepInput
                  value={"" + value[0]}
                  onChange={newValue => props.onChange([newValue, value[1]])}
                  allowNegative={isCoordinates || isAngle}
                  handleSave={() => this.onRowUpdate(props.rowData)}
                  autoFocus
                />
                <span style={{ marginRight: 5 }}>{"y:"}</span>
                <SweepInput
                  value={"" + value[1]}
                  onChange={newValue => props.onChange([value[0], newValue])}
                  allowNegative={isCoordinates || isAngle}
                  handleSave={() => this.onRowUpdate(props.rowData)}
                />
              </div>
            ) : (
              <SweepInput
                value={"" + props.value}
                onChange={newValue => props.onChange(newValue)}
                allowNegative={isAngle || isOffset}
                handleSave={() => this.onRowUpdate(props.rowData)}
                autoFocus
              />
            );
          },
          render: rowData => {
            const { value } = rowData,
              isArray = value instanceof Array;
            return (
              <div>
                <span>{isArray ? `x: ${value[0]} y: ${value[1]}` : value}</span>
              </div>
            );
          }
        }
      ],
      originalParameters: {}
    };
  }

  /**
   * it sets the component state with the parameters as soon as it mounts if there are parameters.
   */
  componentDidMount() {
    const { parameters } = this.props;
    if (parameters) {
      const parsedParameters = JSON.parse(parameters);
      this.setState({
        originalParameters: parsedParameters
      });
    }
  }

  getStructureItems = () => {
    const combinationItems = this.getCombinationItems();
    const structureItems = combinationItems.filter(
      item =>
        !this.props.combinationOperators.map(({ name }) => name).includes(item)
    );
    return structureItems;
  };

  selectStructure = selectedItemIndex => {
    this.setState({ selectedItemIndex });
  };

  getCombinationItems = () => {
    const { structure } = this.props;
    if (structure) {
      const combinationWithSeparators = this.props.combinationOperators
        .map(({ name }) => name)
        .reduce((acc, operator) => {
          acc = acc.length
            ? acc.replaceAll(operator, `#${operator}#`)
            : structure.replaceAll(operator, `#${operator}#`);
          return acc;
        }, "");
      return combinationWithSeparators.split("#");
    }
    return null;
  };

  /**
   * everytime the layer parameters change, we change the state of this component
   * with a parsed manipulable data, becomes the layer parameters is just a string.
   * we also set a state that will be used for editing the parameter values.
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const prevParameters = prevProps.parameters,
      parameters = this.props.parameters;
    if (prevParameters !== parameters) {
      const parsedParameters = JSON.parse(parameters);
      this.setState({
        originalParameters: parsedParameters
      });
    }
  }

  /**
   * it calls the passed callback to handle parameter updates
   * and resolve or reject the promise based on the response.
   * parameters are updated either it is single or a combination of multiple.
   * it is ensured that all members of the combination have the same resolution.
   */
  onRowUpdate = newData =>
    new Promise((resolve, reject) => {
      const parameters = cloneDeep(this.state.originalParameters);
      const selectedStructureIndex = this.getSelectedStructureIndex(
        this.state.selectedItemIndex
      );
      const isSingleStructure = selectedStructureIndex === -1;
      if (isSingleStructure) {
        parameters[newData.name].value = newData.value;
      } else {
        if (newData.name === "num_steps") {
          for (let i = 0; i < parameters.length; i++) {
            parameters[i]["num_steps"].value = newData.value;
          }
        } else {
          parameters[selectedStructureIndex][newData.name].value =
            newData.value;
        }
      }
      const { layerId, onUpdateParameters } = this.props;
      onUpdateParameters(layerId, parameters)
        .then(() => resolve())
        .catch(error => {
          this.setState({ error: null }, () =>
            this.setState({ error: error.response.data })
          );
          reject();
        });
    });

  /**
   * @param {String} newStructure - string of new combination of structures
   * @param {Object[]} newParameters - an object of parameters for each structure
   * @returns {Promise} the request promise
   */
  updateCombination = (newStructure, newParameters) => {
    const { layerId, onUpdateCombination } = this.props;
    this.setState({ saving: true });
    return onUpdateCombination(
      layerId,
      newStructure.join(""),
      newParameters
    ).then(() => this.setState({ saving: false }));
  };

  getSelectedStructureIndex = itemIndex => {
    const { originalParameters } = this.state;
    if (Array.isArray(originalParameters)) {
      const combinationItems = this.getCombinationItems();
      let numberOfOperatorsBeforeSelectedStructure = 0;
      for (let i = 0; i < itemIndex; i++) {
        if (
          this.props.combinationOperators
            .map(({ name }) => name)
            .includes(combinationItems[i])
        ) {
          numberOfOperatorsBeforeSelectedStructure += 1;
        }
      }
      const selectedStructureIndex =
        itemIndex - numberOfOperatorsBeforeSelectedStructure;
      return selectedStructureIndex;
    }
    return -1;
  };

  /**
   * because the original parameters is a json object,
   * we make an array out of it containing all parameters to pass to the material-table
   * @returns {Object[]} - the array of parameters
   */
  getDisplayableData = () => {
    const { originalParameters } = this.state;
    if (originalParameters) {
      const selectedStructureIndex = this.getSelectedStructureIndex(
        this.state.selectedItemIndex
      );
      const selectedParameters =
        selectedStructureIndex === -1
          ? originalParameters
          : originalParameters[selectedStructureIndex];
      const parameterNames = Object.keys(selectedParameters || {}),
        displayableData = parameterNames.map((name, index) => {
          const parameter = selectedParameters[name];
          return {
            id: index,
            name,
            label: parameter.label,
            value: parameter.value,
            description: parameter.description
          };
        });
      return displayableData;
    }
    return [];
  };

  render = () => {
    const { columns } = this.state,
      { classes, parameters, parameterizedStructures } = this.props;
    const fullDisplayableData = this.getDisplayableData();
    const globalDisplayableData = fullDisplayableData.filter(item =>
      this.globalParameters.includes(item.name)
    );
    const selectedStructureDisplayableData = fullDisplayableData.filter(
      item => !this.globalParameters.includes(item.name)
    );
    return (
      parameters && (
        <div className={classes.root}>
          {this.state.saving && <LoadingOverlay />}
          <Typography variant="h6" style={{ marginLeft: 24 }}>
            Parameterized Structures
          </Typography>
          <div className={classes.globalParametersTable}>
            <EnhancedMaterialTable
              slim
              disableSelectionHighlight
              title={null}
              columns={columns}
              options={{
                header: false,
                search: false,
                paging: false,
                actionsColumnIndex: 2,
                toolbar: false
              }}
              className={classes.globalParametersTable}
              data={globalDisplayableData}
              editable={{
                isEditable: rowData => !isNaN(this.props.layerId),
                onRowUpdate: this.onRowUpdate
              }}
            />
          </div>
          {this.props.isCombination && (
            <StructureCombination
              combinationUsedMaterial={this.props.usedMaterialsProps.layerUsedMaterials[this.state.selectedItemIndex / 2]}
              layer={this.props.usedMaterialsProps.layer}
              combinationItems={this.getCombinationItems()}
              parameterizedStructures={parameterizedStructures}
              operatorOptions={this.props.combinationOperators}
              selectedItemIndex={this.state.selectedItemIndex}
              showParams={this.selectStructure}
              parameters={this.state.originalParameters}
              updateCombination={this.updateCombination}
              getSelectedStructureIndex={this.getSelectedStructureIndex}
            />
          )}
          {this.props.usedMaterialsProps &&
            <div style={{ marginBottom: 24 }}>
              <LayerUsedMaterials
                {...{
                  ...this.props.usedMaterialsProps,
                  layerUsedMaterials: [this.props.usedMaterialsProps.layerUsedMaterials[this.state.selectedItemIndex / 2]],
                  title: 'Combination used material'
                }
                }
                updateCombination={
                  async () => {
                    this.setState({
                      saving: true
                    })
                    await this.onRowUpdate(selectedStructureDisplayableData[0])
                    this.setState({
                      saving: false
                    })
                  }
                }
              />
            </div>
          }
          <EnhancedMaterialTable
            slim
            title={null}
            columns={columns}
            options={{
              header: false,
              search: false,
              paging: false,
              actionsColumnIndex: 2,
              toolbar: false
            }}
            data={selectedStructureDisplayableData}
            editable={{
              isEditable: rowData => !isNaN(this.props.layerId),
              onRowUpdate: this.onRowUpdate
            }}
          />
          {this.state.error && <DirectionSnackbar message={this.state.error} />}
        </div>
      )
    );
  };
}

ParameterizedStructure.propTypes = {
  /**
   * which layer the parameterized structure belongs to
   */
  layerId: Proptypes.number.isRequired,
  /**
   * the layer parameters
   */
  parameters: Proptypes.string.isRequired,
  /**
   * the callback function that handles the update of a parameter
   */
  onUpdateParameters: Proptypes.func.isRequired,
  /**
   * possible parameterized structures
   */
  parameterizedStructures: Proptypes.arrayOf(Proptypes.string).isRequired,
  /**
   * the list of possible operators
   */
  combinationOperators: Proptypes.arrayOf(Proptypes.obj).isRequired,
  /**
   * whether it is a combination of structures
   */
  isCombination: Proptypes.bool.isRequired
};

export default withErrorBoundary(withStyles(styles)(ParameterizedStructure));
