import React, { PureComponent } from "react";
import { MetaComponentPaths } from "MetaComponent/MetaComponent";
import { Button, Typography, LinearProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import DesignSelector from "MetaComponent/selectors/Design";
import DesignApi from "MetaComponent/api/Design";
import DesignJobActions from "./components/DesignJobActions/DesignJobActions";
import DesignJobProgress from "./components/DesignJobProgress/DesignJobProgress";
import DesignJobDropdown from "./components/DesignJobDropdown/DesignJobDropdown";
import JsonDialog from "components/JsonDialog/JsonDialog";
import debounce from "lodash.debounce";
import { isEqual } from "lodash";
import Helper from "MetaComponent/helper/Design";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import DesignAction from "MetaComponent/actions/Design";
import Spinner from "components/Spinner/Spinner";
import DesignTargetSelector from "MetaComponent/selectors/DesignTarget";
import SelectedDesignTargetSelector from "MetaComponent/selectors/SelectedDesignTarget";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import HelperUtils from "MetaCell/helper/HelperUtils";
import { RequestFabiractionDialog } from "./components/RequestFabricationDialog/RequestFabricationDialog";
import UserSelector from "BaseApp/selectors/User";

export const styles = {
  buttonWrapper: {
    position: "relative",
    marginTop: 20
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    paddingTop: 50
  },
  progress: {
    maxWidth: 500,
    margin: "auto",
    textAlign: "center",
    width: "100%"
  },
  right: {
    display: "flex",
    flexDirection: "column",
    width: "200px",
    textAlign: "center",
    padding: "0 30px",
    position: "fixed",
    right: 0,
    top: 150
  }
};

/**
 * A component created to be the content for Meta Cell component's design.
 * @author Akira Kotsugai
 * @param {Object} props - the props passed by parent components
 */
export class DesignCanvas extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showJsonDialog: false,
      jsonToShow: null,
      jobStatus: null,
      polling: true,
      currentDesignJob: null,
      message: "",
      disabledActions: "",
      loadingActions: [],
      showRequestFabricationDialog: false,
      snackbar: {
        message: "",
        visibile: false
      }
    };
  }

  /**
   *
   * handle the returned api error
   */
  handleError(error) {
    if (error.status === 403 || error.status === 400) {
      this.setState({
        message: error.status === 403 ? error.data.detail : error.data,
        polling: false
      });
    } else if (error.status === 503) {
      this.setState({ polling: false, message: null });
    }
  }

  /**
   * it sets the open page for this component as soon as the component mounts
   */
  componentDidMount() {
    this.props.setPage(MetaComponentPaths.DESIGN);
    this.disableExportButtons();
    this.getDesignJobProgress();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { designJobs, selectedJobId } = this.props;
    if (!isEqual(prevProps.designJobs.allIds, designJobs.allIds))
      this.getDesignJobProgress();
    if (!isEqual(prevProps.selectedJobId, selectedJobId) && selectedJobId) {
      this.disableExportButtons();
    }
  }

  disableExportButtons() {
    const { designJobs, selectedJobId } = this.props;
    if (
      selectedJobId &&
      (designJobs.byId[selectedJobId].status === "ERROR" ||
        designJobs.byId[selectedJobId].status === "FAILED" ||
        designJobs.byId[selectedJobId].status === "STOPPED")
    ) {
      const status = designJobs.byId[selectedJobId].status;
      this.setState({ disabledActions: `${status},report,mask,memberMap` });
    } else {
      this.setState({ disabledActions: "" });
    }
  }

  componentWillUnmount() {
    this.getDesignJobProgressWithDelay.cancel();
    this.getMaskStatusWithDelay.cancel();
    this.getReportStatusWithDelay.cancel();
  }

  /**
   * it gets the design job status from the backend endpoint and depending on the response
   * it decides whether it should call itself again, but with delay for the next call.
   */
  getDesignJobProgress = async () => {
    const { polling } = this.state,
      { designJobs, getDesignJob, openMetaComponentId } = this.props;
    const designJob = Helper.getMostRecentJob(
      Object.values(designJobs.byId).filter(
        job => job.meta_component === openMetaComponentId
      )
    );
    if (designJob) {
      return DesignApi.getDesignJobProgress(designJob.id)
        .then(({ data }) => {
          if (designJob.progress !== data.progress) {
            this.setState(
              {
                polling: false
              },
              () => {
                this.setState({
                  polling: true
                });
              }
            );
          }
          this.setState({ currentDesignJob: data });
          if (
            data.status === "ERROR" ||
            data.status === "DONE" ||
            data.status === "STOPPED" ||
            data.status === "FAILED"
          ) {
            this.setState({ polling: false });
            getDesignJob(data.id);
          } else if (polling) {
            this.getDesignJobProgressWithDelay();
          }
          return Promise.resolve();
        })
        .catch(() => {
          this.setState({ polling: false });
        });
    } else {
      this.setState({ polling: false });
    }
  };

  /**
   * it's not a component method, but an object. it calls the status getter with a 5s delay
   * when it is invoked.
   */
  getDesignJobProgressWithDelay = debounce(() => {
    this.getDesignJobProgress();
  }, 5000);

  /**
   * it opens the json dialog with errors and warnings of the selected design job
   */
  onShowResultsErrorsAndWarnings = () => {
    const { selectedJobId, designJobs } = this.props;
    this.setState({
      jsonToShow: {
        errors: designJobs.byId[selectedJobId].errors,
        warnings: designJobs.byId[selectedJobId].warnings
      }
    });
    this.showJsonDialog();
  };

  showJsonDialog = () => {
    this.setState({ showJsonDialog: true });
  };

  hideJsonDialog = () => {
    this.setState({ showJsonDialog: false });
  };

  /**
   * it's not a component method, but an object. it calls the status getter with a 5s delay
   * when it is invoked.
   */
  getMaskStatusWithDelay = debounce(() => {
    this.getMaskStatus();
  }, 5000);

  /**
   * it's not a component method, but an object. it calls the status getter with a 5s delay
   * when it is invoked.
   */
  getReportStatusWithDelay = debounce(() => {
    this.getReportStatus();
  }, 5000);

  getMemberMapStatusWithDelay = debounce(() => {
    this.getMemberMapStatus();
  }, 5000);

  /**
   * it keeps checking the status of a mask generation until it is finished
   */
  getMaskStatus() {
    const { selectedJobId } = this.props;
    return DesignApi.getDesignMaskStatus(selectedJobId)
      .then(resp => resp.data.status)
      .then(status => {
        if (status === "QUEUED" || status === "RUNNING") {
          return this.getMaskStatusWithDelay();
        } else if (status === "DONE") {
          return this.getMask();
        } else if (status === "ERROR") {
          this.setState({
            message: "Failed to generate mask"
          });
          this.setState({ disabledActions: "", loadingActions: [] });
        }
      })
      .catch(e => {
        this.setState({
          message: "Failed to generate mask"
        });
        this.setState({ disabledActions: "", loadingActions: [] });
      })
      .catch(e => {
        this.setState({
          message: "Failed to generate mask"
        });
        this.setState({ disabledActions: "", loadingActions: [] });
      });
  }

  /**
   * it keeps checking the status of a report generation until it is finished
   */
  getReportStatus() {
    const { selectedJobId } = this.props;
    return DesignApi.getDesignReportStatus(selectedJobId)
      .then(resp => resp.data.status)
      .then(status => {
        if (status === "QUEUED" || status === "RUNNING") {
          return this.getReportStatusWithDelay();
        } else if (status === "DONE") {
          return this.getReport();
        } else if (status === "ERROR") {
          this.setState({
            message: "Failed to generate report"
          });
          this.setState({ disabledActions: "", loadingActions: [] });
        }
      })
      .catch(e => {
        this.setState({
          message: "Failed to generate report"
        });
        this.setState({ disabledActions: "", loadingActions: [] });
      })
      .catch(e => {
        this.setState({
          message: "Failed to generate report"
        });
        this.setState({ disabledActions: "", loadingActions: [] });
      });
  }

  getMemberMapStatus() {
    const { selectedJobId } = this.props;
    return DesignApi.getDesignMemberMapStatus(selectedJobId)
      .then(resp => resp.data.status)
      .then(status => {
        if (status === "QUEUED" || status === "RUNNING") {
          return this.getMemberMapStatusWithDelay();
        } else if (status === "DONE") {
          return this.getMemberMap();
        }
      });
  }

  startMaskGeneration = () => {
    const { selectedJobId } = this.props;
    this.setState({ disabledActions: "mask", loadingActions: ["mask"] });
    return DesignApi.startDesignMask(selectedJobId).then(() =>
      this.getMaskStatusWithDelay()
    );
  };

  startReportGeneration = () => {
    const { selectedJobId } = this.props;
    this.setState({ disabledActions: "report", loadingActions: ["report"] });
    return DesignApi.startDesignReport(selectedJobId).then(d =>
      this.getReportStatusWithDelay()
    );
  };

  startMemberMapGeneration = () => {
    const { selectedJobId } = this.props;
    this.setState({
      disabledActions: "memberMap",
      loadingActions: ["memberMap"]
    });
    return DesignApi.startMemberMapJob(selectedJobId).then(() =>
      this.getMemberMapStatusWithDelay()
    );
  };

  /**
   * it retrieves a mask gds file.
   */
  getMask = async () => {
    const { selectedJobId, metaComponents, openMetaComponentId } = this.props;
    DesignApi.getDesignMask(selectedJobId).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${metaComponents.byId[openMetaComponentId].name}_design_mask.gds.zip`
      );
      link.click();
      window.URL.revokeObjectURL(url);
      this.setState({ disabledActions: "", loadingActions: [] });
    });
  };

  /**
   * it retrieves a report pdf file.
   */
  getReport = async () => {
    const { selectedJobId, metaComponents, openMetaComponentId } = this.props;
    DesignApi.getDesignReport(selectedJobId).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${metaComponents.byId[openMetaComponentId].name}_design_report.pdf`
      );
      link.click();
      window.URL.revokeObjectURL(url);
      this.setState({ disabledActions: "", loadingActions: [] });
    });
  };

  getMemberMap = async () => {
    const { selectedJobId, metaComponents, openMetaComponentId } = this.props;
    DesignApi.getDesignMemberMapJson(selectedJobId).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${metaComponents.byId[openMetaComponentId].name}_design_membermap.json.zip`
      );
      link.click();
      window.URL.revokeObjectURL(url);
      this.setState({ disabledActions: "", loadingActions: [] });
    });
  };

  /**
   * @param {Object[]} targetsMissingData - design target objects
   * @param {String} missingDataLabel - name of field
   * @returns {Boolean}
   */
  handleTargetMissingData(targetsMissingData, missingDataLabel) {
    const targetNames = targetsMissingData.map(target => target.name);
    const multiple = targetsMissingData.length > 1;
    const message = `${HelperUtils.joinWords(targetNames, true)} ${
      multiple ? "don't" : "doesn't"
    } have a specified ${missingDataLabel}.`;
    this.setState({ message });
  }

  /**
   * checks whether there is sufficient wavefront data
   */
  async validate() {
    const { designTargets, selectedDesignTargets } = this.props;
    let targetsMissingWavefront = [];
    let targetsMissingSetPoint = [];
    for (const selectedDesignTarget of Object.values(
      selectedDesignTargets.byId
    )) {
      const designTarget =
        designTargets.byId[selectedDesignTarget.design_target];
      if (!designTarget) {
        continue;
      }
      const doesntHaveFFWF = !designTarget?.FFWFTarget;
      if (doesntHaveFFWF) {
        // we get a fresh design target because its wavefront may have changed
        let nfwf = null;
        nfwf = await DesignTargetApi.requestNFWFTargetWavefrontShape(
          designTarget.id
        );
        if (!nfwf) {
          targetsMissingWavefront.push(designTarget);
        }
      }
      if (!selectedDesignTarget.set_point) {
        targetsMissingSetPoint.push(designTarget);
      }
    }

    if (targetsMissingWavefront.length) {
      this.handleTargetMissingData(targetsMissingWavefront, "wavefront");
      return false;
    }
    if (targetsMissingSetPoint.length) {
      this.handleTargetMissingData(targetsMissingSetPoint, "set point");
      return false;
    }
    this.setState({ message: "" });
    return true;
  }

  /**
   * start the design phase for the meta component
   */
  startDesign = async () => {
    const valid = await this.validate();
    if (valid) {
      const { openMetaComponentId, startDesign } = this.props;
      startDesign(openMetaComponentId, this.handleError.bind(this));
      this.setState({ polling: true, currentDesignJob: null, message: "" });
    }
  };

  /**
   * @returns {Object} the open meta component entity
   */
  getOpenMetaComponent = () => {
    const { openMetaComponentId, metaComponents } = this.props;
    return metaComponents.byId[openMetaComponentId];
  };

  onShowNewJobDialog = () => {
    const { showConfirmDialog } = this.props;

    const title = "New design job";
    const message = `Are you sure you want to start a new design job?`;
    const confirmAction = this.startDesign;
    showConfirmDialog(title, message, confirmAction, undefined, false);
  };

  onShowRequestFabricationDialog = () => {
    this.setState({
      showRequestFabricationDialog: true
    });
  };

  closeRequestFabricationDialog = () => {
    // possibly reset fields
    this.setState({
      showRequestFabricationDialog: false
    });
  };

  getJobsList = designJobs => {
    const { openMetaComponentId } = this.props;
    return Object.values(designJobs.byId).filter(
      job =>
        job.meta_component === openMetaComponentId &&
        (job.status === "ERROR" ||
          job.status === "DONE" ||
          job.status === "STOPPED" ||
          job.status === "FAILED")
    );
  };

  /**
   * it stops a running design job
   * @param {Number} jobId - the job id
   */
  stopDesignJob = jobId => {
    DesignApi.stopDesignJob(jobId)
      .then(response => {
        this.getDesignJobProgress();
      })
      .catch(error => console.log("Design API: failed to stop design job"));
  };

  setSnackbar = message => {
    this.setState({
      snackbar: {
        message,
        visible: true
      }
    });
  };

  resetSnackbar = () => {
    this.setState({
      snackbar: {
        message: "",
        visibile: false
      }
    });
  };

  render() {
    const { classes, designJobs, user } = this.props;
    const {
      jsonToShow,
      showJsonDialog,
      polling,
      currentDesignJob,
      message,
      disabledActions,
      loadingActions,
      showRequestFabricationDialog
    } = this.state;
    const jobsList = this.getJobsList(designJobs);
    const openMetaComponent = this.getOpenMetaComponent();
    return (
      <div className={classes.wrapper}>
        {polling && (
          <div className={classes.progress}>
            {currentDesignJob && (
              <Typography>Design status: {currentDesignJob.status}</Typography>
            )}

            <div className={classes.wrapper}>
              <Spinner name="Waiting" size={68} timeout={180000} />
            </div>
            <div className={classes.wrapper}>
              {currentDesignJob && (
                <Typography>
                  {currentDesignJob.progress && currentDesignJob.progress[0]} %
                </Typography>
              )}
            </div>
            <br />
            {currentDesignJob && (
              <LinearProgress
                name="SimulationProgress"
                variant="determinate"
                value={
                  currentDesignJob.progress && currentDesignJob.progress[0]
                }
              />
            )}
            <br />
            {currentDesignJob && polling && (
              <div>
                <Typography>{currentDesignJob.progress_message}</Typography>
              </div>
            )}
            {currentDesignJob && polling && (
              <div
                className={classes.buttonWrapper}
                style={{ textAlign: "center" }}
              >
                <Button
                  test-data="stopBtn"
                  name="StopDesignButton"
                  className={classes.buttonMargin}
                  variant="contained"
                  onClick={this.stopDesignJob.bind(this, currentDesignJob.id)}
                >
                  Stop Design
                </Button>
              </div>
            )}
          </div>
        )}

        {message && <DirectionSnackbar message={message} />}

        {!polling && (
          <div>
            {designJobs.loaded && jobsList.length === 0 && (
              <div className={classes.buttonWrapper}>
                <Button
                  name="StartDesign"
                  variant="contained"
                  color="primary"
                  disabled={polling}
                  onClick={this.startDesign}
                >
                  Start Design
                </Button>
              </div>
            )}

            {designJobs.loaded && jobsList.length > 0 && (
              <div>
                <DesignJobProgress />

                <div className={classes.right}>
                  <DesignJobDropdown designJobs={jobsList} />
                  <DesignJobActions
                    disabledActions={disabledActions.split(",")}
                    loadingActions={loadingActions}
                    showResultsErrorsAndWarnings={
                      this.onShowResultsErrorsAndWarnings
                    }
                    showNewJobDialog={this.onShowNewJobDialog}
                    getMask={this.startMaskGeneration}
                    getReport={this.startReportGeneration}
                    getMemberMap={this.startMemberMapGeneration}
                    allowRequestFabrication={openMetaComponent.family_is_pdk}
                    onRequestFabricationDialog={
                      this.onShowRequestFabricationDialog
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <JsonDialog
          open={showJsonDialog}
          data={jsonToShow}
          onClose={this.hideJsonDialog}
        />

        <RequestFabiractionDialog
          open={showRequestFabricationDialog}
          onClose={this.closeRequestFabricationDialog}
          user={user}
          design_job={currentDesignJob}
          setSnackbar={this.setSnackbar}
          resetSnackbar={this.resetSnackbar}
        />
        {this.state.snackbar.visible ? (
          <DirectionSnackbar
            persistent={true}
            message={this.state.snackbar.message}
            anchor={{ vertical: "center", horizontal: "center" }}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    openMetaComponentId: DirectoryExplorerSelector.getMetaComponentOpenId(
      state
    ),
    metaComponents: DirectoryExplorerSelector.getMetaComponents(state),
    designJobs: DesignSelector.getDesignJobs(state),
    selectedJobId: DesignSelector.getSelectedJobId(state),
    designTargets: DesignTargetSelector.getDesignTargets(state),
    selectedDesignTargets: SelectedDesignTargetSelector.getSelectedDesignTargets(
      state
    ),
    user: UserSelector.getUser(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDesignJobs: openMetaComponentId =>
      dispatch(DesignApi.getDesignJobs(openMetaComponentId)),
    getDesignJob: jobId => dispatch(DesignApi.getDesignJob(jobId)),
    startDesign: (metaComponentId, errorHandler) =>
      dispatch(DesignApi.run(metaComponentId, errorHandler)),
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      ),
    resetJobs: () => dispatch(DesignAction.resetJobs())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DesignCanvas));
