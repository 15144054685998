import React, { PureComponent } from "react";
import { withStyles, TextField, Input } from "@material-ui/core";
import EnhancedMaterialTable from "components/EnhancedMaterialTable/EnhancedMaterialTable";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import SweepInput from "components/SweepInput/SweepInput";
import Polarization from "components/Polarization/Polarization";

const styles = {
  main: {
    width: "100%",
    boxSizing: "border-box",
    paddingRight: "20px"
  },
  inputTooltip: {
    whiteSpace: "pre-line"
  }
};

const getColumns = () => [
  {
    title: "Name",
    field: "name"
  },
  {
    title: "Azimut",
    field: "azimut",
    editComponent: props => {
      return (
        <SweepInput
          name="IncidentLightAzimut"
          disabled={false}
          value={props.value ? props.value : ""}
          onChange={props.onChange}
        />
      );
    }
  },
  {
    title: "Zenith",
    field: "zenit",
    editComponent: props => {
      return (
        <SweepInput
          name="IncidentLightZenit"
          disabled={false}
          value={props.value ? props.value : ""}
          onChange={props.onChange}
        />
      );
    }
  },
  {
    title: "Amplitude",
    field: "amplitude",
    editComponent: props => {
      return (
        <SweepInput
          name="IncidentLightAmplitude"
          disabled={false}
          value={props.value ? props.value : ""}
          onChange={props.onChange}
        />
      );
    }
  },
  {
    title: "Wavelength",
    field: "wavelength",
    editComponent: props => {
      return (
        <SweepInput
          name="IncidentLightWavelength"
          disabled={false}
          value={props.value ? props.value : ""}
          onChange={props.onChange}
        />
      );
    }
  },
  {
    title: "Polarization",
    field: "polarization",
    editComponent: props => {
      return (
        <Polarization
          updateFieldCallback={({ polarization }) =>
            props.onChange(polarization)
          }
          // updatePolarizationHistory={updatePolarizationHistory}
          polarization={props.value}
          // polarizationHistory={polarizationHistory}
          gridColumnXs={12}
          hideLabel
        />
      );
    }
  }
];

export class IncidentLightsTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedIncidentLightId: null
    };
  }

  selectIncidentLight = id => {
    const { focusOnIncidentLight } = this.props;
    this.setState({
      selectedIncidentLightId: id
    });
    focusOnIncidentLight(id);
  };

  getSelectedEntityId() {
    const { selectedIncidentLightId } = this.state;
    return selectedIncidentLightId;
  }

  onIncidentLightUpdate = newData => {
    return new Promise((resolve, reject) => {
      const { saveIncidentLight, fetchIncidentLightsAction } = this.props;
      saveIncidentLight(newData)
        .then(() => {
          fetchIncidentLightsAction(newData.simulation).then(() => resolve());
        })
        .catch(() => {
          reject();
        });
    });
  };

  render = () => {
    const { classes, incidentLights } = this.props;

    return (
      <div>
        <EnhancedMaterialTable
          options={{
            search: false,
            paging: false,
            sorting: false,
            draggable: false,
            maxBodyHeight: 350,
            actionsColumnIndex: -1
          }}
          title="Incident Light Parameters"
          columns={getColumns()}
          data={incidentLights}
          selectEntity={this.selectIncidentLight}
          selectedEntityId={this.getSelectedEntityId()}
          editable={{
            // onRowAdd: this.onRowAdd,
            onRowUpdate: this.onIncidentLightUpdate
            // onRowDelete: this.onRowDelete
          }}
        />
      </div>
    );
  };
}
export default withErrorBoundary(withStyles(styles)(IncidentLightsTable));
