import React, { PureComponent } from "react";
// import MaterialTable from "material-table";
import MaterialTable from "components/MaterialTable/src/index";
import { IconButton, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import MaterialSelector from "MetaCell/selectors/Material";
import MaterialApi from "MetaCell/api/Material";
import Paper from "@material-ui/core/Paper";
import UserSelector from "BaseApp/selectors/User";
import MaterialVisualization from "./components/MaterialVisualization";
import domtoimage from "dom-to-image";
import MaterialHelper from "MetaCell/helper/Material";
import Import from "components/Import";
import ImportMaterialPreview from "./components/ImportMaterialPreview";
import {
  MaterialSimpleFieldEditing,
  MaterialColorEditing,
  MaterialFileEditing
} from "./components/MaterialEditComponents";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import DeleteIcon from "@material-ui/icons/Delete";
import { ChevronRight } from "@material-ui/icons";
import HelperUtils from "MetaCell/helper/HelperUtils";

const styles = {
  main: {
    width: "100%",
    boxSizing: "border-box"
  },
  uploadButtonInvalid: {
    borderColor: "red"
  },
  uploadButtonValid: {
    borderColor: "black"
  }
};

/**
 * fields that must be filled in before submission
 */
export const requiredFields = ["name", "color", "fileName"];

/**
 * A component to show all materials the user has permission to see.
 * @author Akira Kotsugai
 */
export class Materials extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      columns: [
        {
          title: "Name",
          field: "name",
          customSort: (a, b) => HelperUtils.caseInsensitiveSort(a.name, b.name),
          editComponent: props => {
            return (
              <MaterialSimpleFieldEditing
                field={"name"}
                value={props.value}
                onChange={(field, value) => {
                  this.updateInvalidFields(field, value);
                  props.onChange(value);
                }}
                getRequiredStyle={this.getRequiredStyle}
                isFieldInvalid={this.isFieldInvalid}
              />
            );
          }
        },
        {
          title: "Color",
          field: "color",
          searchable: false,
          render: rowData => {
            return (
              <Paper
                style={{
                  width: 25,
                  height: 25,
                  backgroundColor: rowData.color
                }}
              />
            );
          },
          editComponent: props => {
            return (
              <MaterialColorEditing
                color={props.value}
                onChange={(field, value) => {
                  this.updateInvalidFields(field, value);
                  props.onChange(value);
                }}
                getRequiredStyle={this.getRequiredStyle}
                isFieldInvalid={this.isFieldInvalid}
              />
            );
          }
        },
        {
          title: "Comment",
          field: "comment",
          searchable: false,
          editComponent: props => {
            return (
              <MaterialSimpleFieldEditing
                field={"comment"}
                value={props.value}
                onChange={(field, value) => {
                  this.updateInvalidFields(field, value);
                  props.onChange(value);
                }}
              />
            );
          }
        },
        {
          title: "File name",
          field: "fileName",
          searchable: false,
          editComponent: props => {
            return (
              <div>
                <MaterialFileEditing
                  classes={{ root: this.getUploadButtonStyle() }}
                  fileName={props.value}
                  onClick={this.openImportDialog}
                  getRequiredStyle={this.getRequiredStyle}
                />
                <Import
                  open={this.state.importDialogOpen}
                  loading={false}
                  onClose={this.closeImportDialog}
                  onDrop={this.handleMaterialFileDrop}
                  message="Drag and drop a material json, csv or txt file here, or click here to select a file"
                  error={this.state.importDialogError}
                  accept=".csv, application/json, text/csv, text/plain"
                  postImportComponent={
                    this.state.importPreviewData ? (
                      <ImportMaterialPreview
                        data={MaterialHelper.buildMaterialGraphData(
                          this.state.importPreviewData.wavelength,
                          this.state.importPreviewData.refractiveIndex,
                          this.state.importPreviewData.absorptionCoeff
                        )}
                        onCancel={this.openImportDialog}
                        onConfirm={() =>
                          this.handleConfirmImportPreview(
                            props.onRowDataChange,
                            props.rowData
                          )
                        }
                      />
                    ) : null
                  }
                />
              </div>
            );
          }
        },
        {
          title: "Owner",
          field: "owner",
          editable: false,
          searchable: false,
          render: rowData => {
            const { user } = this.props;
            return (
              <span>
                {rowData === undefined || rowData.owner === user.id
                  ? user.username
                  : ""}
              </span>
            );
          }
        }
      ],
      importDialogOpen: false,
      importDialogError: "",
      importPreviewData: null,
      invalidFields: [],
      selectedEntityId: -1,
      selectedEntityEvent: null
    };
    this.tableRef = React.createRef();
  }

  /**
   * it returns a style for the upload button depending on the field validation status
   * @returns {String} a classname generated by MUI
   */
  getUploadButtonStyle = () => {
    const { classes } = this.props;
    return this.isFieldInvalid("fileName")
      ? classes.uploadButtonInvalid
      : classes.uploadButtonValid;
  };

  /**
   * it calls the select entity callback if the selected item is managed by the parent
   * otherwise it selects the entity internally
   * @param {Number} entityId - the entity to select
   */
  selectEntity = entityId => {
    this.setState({ selectedEntityId: entityId });
  };

  /**
   * @param {Object} event - the click event
   * @param {Object} rowData - the entity row data
   * it selects an entity when the row is clicked only if the table is not on edit mode
   */
  handleRowClick = (event, rowData) => {
    this.selectEntity(rowData.id);
  };

  /**
   * it checks whether the given field exists in the state for invalid fields
   * @param {String} field - the material field
   * @returns {Boolean} whether it is invalid
   */
  isFieldInvalid = field => {
    const { invalidFields } = this.state;
    return invalidFields.indexOf(field) !== -1;
  };

  /**
   * it returns a style for the * sign in required fields
   * by checking their validation status
   * @param {String} field - the field to be checked
   */
  getRequiredStyle = field => {
    return {
      color: this.isFieldInvalid(field) ? "red" : "black"
    };
  };

  /**
   * @param {String} field - the material field being changed
   * @param {String} value - the new value
   */
  updateInvalidFields = (field, value) => {
    if (requiredFields.includes(field)) {
      const { invalidFields } = this.state;
      const newInvalidFields = value
        ? invalidFields.filter(invalidField => invalidField !== field)
        : invalidFields.concat(field);
      this.setState({ invalidFields: newInvalidFields });
    }
  };

  /**
   * it can change the state for material dialog open
   * @param {Boolean} open - whether it is open
   */
  setImportDialogOpen = open => {
    this.setState({ importDialogOpen: open });
  };

  /**
   * it can change the state for import dialog error
   * @param {String} error - the error message
   */
  setImportDialogError = error => {
    this.setState({ importDialogError: error });
  };

  /**
   * it changes the state for import preview data
   * @param {Object} previewData - the material preview data
   */
  setImportPreviewData = previewData => {
    this.setState({ importPreviewData: previewData });
  };

  /**
   * it cleans the import dialog's error message and the preview data and opens the dialog
   */
  openImportDialog = () => {
    this.setImportPreviewData(null);
    this.setImportDialogError("");
    this.setImportDialogOpen(true);
  };

  /**
   * it closes the import dialog
   */
  closeImportDialog = () => {
    this.setImportDialogOpen(false);
  };

  /**
   * It selects the material graph in the dom, takes a picture and download it.
   * @param {Number} id - the material id
   */
  downloadGraphImage = id => {
    const { materials } = this.props;
    const { name } = materials.byId[id];
    const element = document.getElementById(`material_plot_${id}`);
    domtoimage.toPng(element, { quality: 1 }).then(dataUrl => {
      var link = document.createElement("a");
      link.download = `${name}_material_graph.png`;
      link.href = dataUrl;
      link.click();
    });
  };

  /**
   * It downloads a material json file generated in the backend
   * @param {Number} id - the material id
   */
  downloadJson = id => {
    const { materials } = this.props;
    const { name } = materials.byId[id];
    MaterialApi.exportMaterial(id).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `${name}_material_data.json`;
      link.click();
    });
  };

  /**
   * it fetches the materials as soon as the component mounts if they were not loaded already.
   */
  componentDidMount() {
    const { materials, fetchMaterialsAction } = this.props;
    if (materials.loaded === false) fetchMaterialsAction();
  }

  /**
   * it submits the form data and resets it if there are no missing required fields otherwise
   * it updates the invalid fields and does not submit anything.
   */
  onRowUpdate = newData => {
    const { updateMaterialAction } = this.props;
    return this.submitMaterial(updateMaterialAction, newData);
  };

  /**
   * it submits the form data and resets it if there are no missing required fields otherwise
   * it updates the invalid fields and does not submit anything.
   */
  onRowAdd = newData => {
    const { createMaterialAction } = this.props;
    return this.submitMaterial(createMaterialAction, newData);
  };

  /**
   * @param {Function} action - what action to execute on the material
   * @param {*} material - the material
   * @returns {Promise} - the promise to submit the material
   */
  submitMaterial = (action, material) => {
    return new Promise((resolve, reject) => {
      const missingRequiredFields = MaterialHelper.getMissingRequiredProperties(
        material,
        requiredFields
      );
      if (missingRequiredFields.length === 0) {
        action(material);
        this.setState({ invalidFields: [] });
        resolve();
      } else {
        this.setState({ invalidFields: missingRequiredFields });
        reject();
      }
    });
  };

  /**
   * @callback
   * @param {Object} material - the material being deleted
   * @returns {Promise} a promise that calls the delete material action
   */
  onRowDelete = material => {
    const { deleteMaterialAction } = this.props;
    return new Promise((resolve, reject) => {
      deleteMaterialAction(material.id)
        .then(() => {
          resolve();
          window.location.reload();
        })
        .catch(() => reject());
    });
  };

  /**
   * it creates the two necessary lines for plotting the material fields. if materials still don't have
   * the necessary values for plotting it retrieves in the api.
   * @param {Number} id - the material id;
   * @return {Object[]} - the list of lines
   */
  getLineData = id => {
    const { materials, fetchMaterialsAction } = this.props;
    const material = materials.byId[id];
    const { wavelength, refractiveIndex, absorptionCoeff } = material;
    if (
      wavelength === undefined &&
      refractiveIndex === undefined &&
      absorptionCoeff === undefined
    ) {
      fetchMaterialsAction(id);
    } else {
      return MaterialHelper.buildMaterialGraphData(
        wavelength,
        refractiveIndex,
        absorptionCoeff
      );
    }
    return [];
  };

  /**
   * it builds a list of lists where each inner list is a csv row.
   * each row contains the wavelength, the refractive index and the absoption coefficient
   * there is also a list with headers and a list with a comment.
   * @param {Number} id - the material id
   * @returns {Object[][]} - the csv data
   */
  getCsvData = id => {
    const { materials, user } = this.props;
    const material = materials.byId[id];
    const { wavelength, refractiveIndex, absorptionCoeff } = material;
    if (
      wavelength !== undefined &&
      refractiveIndex !== undefined &&
      absorptionCoeff !== undefined
    ) {
      let csvData = [];
      csvData.push(
        MaterialHelper.getMaterialCsvComment(
          material.name,
          user.username,
          new Date().toString()
        )
      );
      const headers = [
        "wavelength",
        "refractive index",
        "absorption coefficient"
      ];
      csvData.push(headers);
      csvData.push(
        ...MaterialHelper.getMaterialCsvData(
          wavelength,
          refractiveIndex,
          absorptionCoeff
        )
      );
      return csvData;
    }
    return [[]];
  };

  /**
   * it is supposed to be passed to the import component. it handles a system file selection
   * by reading the file and validating it.
   * @param {File[]} acceptedFiles - the files selected by the system
   * @callback
   */
  handleMaterialFileDrop = async acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const readMaterialResult = await MaterialHelper.readMaterialFile(file);
      if (typeof readMaterialResult === "string") {
        this.setImportDialogError(readMaterialResult);
      } else {
        const materialIsValid = MaterialHelper.validateMaterialCurve(
          readMaterialResult
        );
        if (materialIsValid) {
          MaterialHelper.setMaterialFileName(file, readMaterialResult);
          this.setImportPreviewData(readMaterialResult);
        } else {
          const error =
            HelperUtils.get_possible_import_destination(readMaterialResult) ||
            "The given material does not have the correct properties to generate a curve.";
          this.setImportDialogError(error);
        }
      }
    }
  };

  /**
   * it focus on the entity above or below the selected entity.
   * @param {String} arrow - the pressed arrow
   */
  handlePressedArrow = arrow => {
    const { materials } = this.props,
      selectedEntityId = this.props.selectedEntityId
        ? this.props.selectedEntityId
        : this.state.selectedEntityId;
    const data = Object.values(materials.byId);
    const index = data.findIndex(entity => entity.id === selectedEntityId);
    let entityToFocusOnId;
    if (arrow === "ArrowUp" && index !== 0)
      entityToFocusOnId = data[index - 1].id;
    else if (arrow === "ArrowDown" && data[index + 1] !== undefined)
      entityToFocusOnId = data[index + 1].id;
    if (entityToFocusOnId !== undefined) {
      this.selectEntity(entityToFocusOnId);
    }
  };

  /**
   * it calls the row key down handler from the parent if any was given and calls the pressed
   * arrow handler if a vertical arrow was pressed and prevents the main page from scrolling
   * or clicks on the save button if the key pressed from an editing row was enter
   * @param {Object} event - the keydown event
   * @callback
   */
  handleRowKeyDown = event => {
    const { key } = event;
    if (key === "ArrowUp" || key === "ArrowDown") {
      event.preventDefault();
      this.handlePressedArrow(key);
    } else if (key === "Enter") {
      // const detailButton = [
      //   ...event.currentTarget.getElementsByTagName("button")
      // ].reverse()[0];
      // detailButton.click();
      const detailButton = document.getElementById("active-chevron")
        ?.parentElement;
      if (detailButton) detailButton.click();
    }
  };

  /**
   * it starts editing the entity that was double clicked
   * @param {Object} event - the double click event
   * @callback
   */
  handleRowDoubleClick = event => {
    const detailButton = [
      ...event.currentTarget.getElementsByTagName("button")
    ].reverse()[0];
    detailButton.click();
  };

  /**
   * it updates the material to upsert with the import preview data, resets the preview data and closes
   * the import dialog.
   */
  handleConfirmImportPreview = (onRowDataChange, rowData) => {
    const { importPreviewData } = this.state;
    const newData = {
      ...rowData,
      ...importPreviewData
    };
    onRowDataChange(newData);
    this.setState({
      invalidFields: []
    });
    this.setImportPreviewData(null);
    this.closeImportDialog();
  };

  render = () => {
    const {
        columns,
        importDialogOpen,
        importDialogError,
        importPreviewData,
        selectedEntityId
      } = this.state,
      { classes, materials } = this.props;
    if (!materials.loaded) {
      return null;
    }
    return (
      <>
        <div className={classes.main}>
          <MaterialTable
            onRowClick={this.handleRowClick}
            onRowKeyDown={this.handleRowKeyDown}
            onRowDoubleClick={this.handleRowDoubleClick}
            tableRef={this.tableRef}
            detailPanel={[
              rowData => {
                return {
                  icon: () => (
                    <ChevronRight
                      id={
                        rowData.id === selectedEntityId ? "active-chevron" : ""
                      }
                    />
                  ),
                  render: rowData => (
                    <div style={{ width: "100%", height: "70vh" }}>
                      <MaterialVisualization
                        id={rowData.id}
                        data={this.getLineData(rowData.id)}
                        exportImage={this.downloadGraphImage}
                        exportJson={this.downloadJson}
                        csvData={this.getCsvData(rowData.id)}
                        name={rowData.name}
                      />
                    </div>
                  )
                };
              }
            ]}
            options={{
              addRowPosition: "first",
              actionsColumnIndex: -1,
              search: true,
              pageSize: 10,
              pageSizeOptions: [10, 20, 30, 40, 50],
              detailPanelColumnAlignment: "right",
              rowStyle: rowData => {
                return {
                  backgroundColor:
                    rowData.id === selectedEntityId ? "#967cf9" : "#ffffff"
                };
              }
            }}
            title="Materials"
            columns={columns}
            data={Object.values(this.props.materials.byId)}
            // TODO: uncomment this code when we implement CRUD for materials.
            editable={{
              isEditable: rowData => rowData.owner === this.props.user.id,
              isDeletable: rowData => rowData.owner === this.props.user.id,
              onRowAdd: this.onRowAdd,
              onRowUpdate: this.onRowUpdate,
              onRowDelete: this.onRowDelete
            }}
            localization={{
              body: {
                editRow: {
                  deleteText: "Are you sure you want to delete this material?"
                }
              },
              pagination: {
                labelRowsSelect: "materials"
              },
              header: {
                actions: ""
              }
            }}
          />
        </div>
      </>
    );
  };
}

const mapState = state => ({
  materials: MaterialSelector.getMaterials(state),
  user: UserSelector.getUser(state)
});

const mapDispatch = dispatch => ({
  fetchMaterialsAction: id => dispatch(MaterialApi.fetchMaterials(id)),
  createMaterialAction: material =>
    dispatch(MaterialApi.createMaterial(material)),
  deleteMaterialAction: materialId =>
    dispatch(MaterialApi.deleteMaterial(materialId)),
  updateMaterialAction: material =>
    dispatch(MaterialApi.updateMaterial(material))
});

export default connect(
  mapState,
  mapDispatch
)(withErrorBoundary(withStyles(styles)(Materials)));
