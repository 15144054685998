import React, { PureComponent } from "react";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import {
  withStyles,
  DialogContent,
  DialogTitle,
  AppBar,
  Toolbar,
  IconButton,
  InputLabel,
  Grid,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { sendMessage } from "workerPool";
import { Validator } from "jsonschema";
import DrilldownInput from "components/DrilldownInput";
import { GenericWavefront } from "components/GenericWavefront/GenericWavefront";
import CodeEditor from "./components/CodeEditor/CodeEditor";
import Actions from "./components/Actions/Actions";
import DesignTargetHelper from "MetaComponent/helper/DesignTarget";
import HelperUtils from "MetaCell/helper/HelperUtils";
import Script2DSelector from "MetaComponent/selectors/Script2D";
import Script2DApi from "MetaComponent/api/Script2D";
import { connect } from "react-redux";
import UnselfishDialog from "components/UnselfishDialog/UnselfishDialog";
import Wavefront from "MetaComponent/containers/TargetCanvas/components/Wavefront/Wavefront";
import DirectionSnackbar from "components/Snackbar/Snackbar";

const matrixSchema = {
  type: "array",
  items: {
    type: ["Float64Array", "array"],
    items: {
      type: "number"
    }
  }
};

const styles = {
  dialogTitleBar: {
    padding: 0
  },
  appBar: {
    position: "relative"
  },
  title: {
    flex: 1
  }
};

export class WavefrontGenerator extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      wavefront: null,
      scriptRunning: false,
      error: null,
      disableSave: false,
      selectedSampleId: 0,
      lastScriptRun: "",
      snackbar: {
        message: "",
        visible: false
      }
    };
    this.code = props.script2Ds.byId[props.scriptId].script;
    this.actionCodeUpdater = () => {};
  }

  bindActionCodeUpdater = actionCodeUpdater => {
    this.actionCodeUpdater = actionCodeUpdater;
  };

  /**
   * a code updater to be passed down because we also want to keep the code in this
   * component. We are not using state because it rerenders the component every time
   * a key is pressed causing a slowness in the wavefront component
   * @callback
   */
  updateCode = code => {
    this.code = code;
    this.actionCodeUpdater(code);
  };

  /**
   * it calls the confirmation callback with the wavefront data and updates the script
   * and closes the dialog
   */
  handleConfirm = () => {
    this.props.updateScript(this.props.scriptId, this.code);
    this.props.onConfirm(this.state.wavefront);
    this.handleClose();
  };

  /**
   * it resets the wavefront and error state and calls the passed close callback
   */
  handleClose = () => {
    this.setState({ wavefront: null, error: null });
    this.props.onClose();
  };

  validateMatrix(matrix) {
    const validator = new Validator();
    try {
      return validator.validate(matrix, matrixSchema);
    } catch (exception) {
      return {
        errors: [exception]
      };
    }
  }
  /**
   * it checks whether the matrix is in the correct shape depending on the required types.
   * @param {Number[][]} wavefront - the wavefront data
   * @returns {String} an error message
   */
  validateWavefront(wavefront) {
    const { onlyAmplitude } = this.props,
      amplBadShapeMsg = "Amplitude must be a matrix of valid number values",
      phaseBadShapeMsg = "Phase must be a matrix of valid number values",
      amplitude =
        onlyAmplitude && wavefront.length !== 2 ? wavefront : wavefront[0],
      amplitudeErrors = this.validateMatrix(amplitude).errors;
    if (amplitudeErrors.length) {
      return amplBadShapeMsg;
    }
    if (!onlyAmplitude) {
      const phase = wavefront[1],
        phaseErrors = this.validateMatrix(phase).errors;
      if (phaseErrors.length) {
        return phaseBadShapeMsg;
      }
    }
    return null;
  }

  /**
   * it sets the error message to the state if the worker returns an exception
   * else it validates if the wavefront has the correct matrix shape.
   * in case the data is valid, it is set to the state.
   * @callback
   * @param {*} data - the worker response
   */
  handleScriptResponse = data => {
    let newState = { scriptRunning: false, disableSave: false };
    if (data instanceof Error) {
      // only the text after the splitter below is informative to the user.
      let error = data.message.split(/File "<unknown>",|File \"<exec>\",/)[1];
      error = error ? error : data.message;
      newState["error"] = error;
      const localExceptions = ["_ArrayMemoryError"];
      if (localExceptions.some(exception => !error.includes(exception))) {
        newState["disableSave"] = true;
      }
    } else {
      const parsedData = JSON.parse(data);
      const error = this.validateWavefront(parsedData);
      newState["error"] = error;
      newState["disableSave"] = true;
      if (!error) {
        newState["wavefront"] = parsedData;
        newState["lastScriptRun"] = this.code;
      }
    }
    this.setState(newState);
  };

  /**
   * it checks whether the required variables are present in the code
   * @param {String} code - python code
   * @returns {String} an error message;
   */
  validateCode(code) {
    const { onAsyncGenerate } = this.props;
    if (onAsyncGenerate && code.includes("import")) {
      return "additional imports are not allowed";
    }
    if (!code.includes("amplitude=") && !code.includes("amplitude =")) {
      return "amplitude variable is required";
    } else if (
      !this.props.onlyAmplitude &&
      !code.includes("phase=") &&
      !code.includes("phase =")
    ) {
      return "phase variable is required";
    }
    return null;
  }

  /**
   * it validates the code and if it is pass
   * in the validation it sends the code to the worker for execution
   * @callback
   */
  generate = async () => {
    const error = this.validateCode(this.code);
    if (error) {
      this.setState({ error });
    } else {
      this.setState({ scriptRunning: true, error: null });
      await sendMessage(
        {
          workerId: "PYODIDE",
          action: "GET_WAVEFRONT",
          params: {
            code: this.code,
            dimensions: this.props.fixedShape
              ? {
                  componentWidth: DesignTargetHelper.getComparableDimension(
                    this.props.componentWidth,
                    this.props.componentUnit
                  ),
                  componentHeight: DesignTargetHelper.getComparableDimension(
                    this.props.componentHeight,
                    this.props.componentUnit
                  ),
                  cellWidth: DesignTargetHelper.getComparableDimension(
                    this.props.cellWidth,
                    this.props.cellUnit
                  ),
                  cellHeight: DesignTargetHelper.getComparableDimension(
                    this.props.cellHeight,
                    this.props.cellUnit
                  )
                }
              : undefined
          }
        },
        data => {
          this.handleScriptResponse(data);
        }
      );
    }
  };

  generateServerSide = () => {
    const { onAsyncGenerate } = this.props;
    const error = this.validateCode(this.code);
    if (error) {
      this.setState({ error });
    } else {
      this.setState({
        scriptRunning: true,
        error: null,
        snackbar: { message: "", visible: false }
      });
      onAsyncGenerate(
        {
          code: this.code,
          dimensions: this.props.fixedShape
            ? {
                componentWidth: DesignTargetHelper.getComparableDimension(
                  this.props.componentWidth,
                  this.props.componentUnit
                ),
                componentHeight: DesignTargetHelper.getComparableDimension(
                  this.props.componentHeight,
                  this.props.componentUnit
                ),
                cellWidth: DesignTargetHelper.getComparableDimension(
                  this.props.cellWidth,
                  this.props.cellUnit
                ),
                cellHeight: DesignTargetHelper.getComparableDimension(
                  this.props.cellHeight,
                  this.props.cellUnit
                )
              }
            : undefined
        },
        () => {
          this.props.updateScript(this.props.scriptId, this.code);
          this.setState({
            scriptRunning: false
          });
          this.handleClose();
        },
        error_message => {
          this.setState({
            snackbar: {
              visible: true,
              message: error_message
            },
            scriptRunning: false
          });
        }
      );
    }
  };

  /**
   * @returns {Object[]} options created from the sample scripts
   */
  getDrilldownOptions() {
    return [
      Object.values(this.props.sampleScripts).map(sample => ({
        text: sample.name,
        isSelected: false
      }))
    ];
  }

  /**
   * it sets the selected sample id in the state
   * it is supposed to be passed as an "on change callback" to the drilldown component.
   * @param {String} selectedOptionIndex - the index of the selected option
   * @callback
   */
  onSampleSelect = selectedOptionIndex => {
    const selectedSample = this.props.sampleScripts[selectedOptionIndex];
    this.setState({
      selectedSampleId: selectedSample.id,
      code: selectedSample.script
    });
  };

  render = () => {
    const {
        classes,
        open,
        sampleScripts,
        componentWidth,
        componentHeight,
        componentUnit,
        cellWidth,
        cellHeight,
        cellUnit,
        wfWidth,
        wfHeight,
        script2Ds,
        scriptId
      } = this.props,
      {
        scriptRunning,
        error,
        selectedSampleId,
        wavefront,
        disableSave
      } = this.state;

    let limitedWavefront = null;
    try {
      limitedWavefront = wavefront
        ? this.props.onlyAmplitude
          ? wavefront.length === 2
            ? [HelperUtils.limitDecimals(wavefront[0], 4)]
            : [HelperUtils.limitDecimals(wavefront, 4)]
          : HelperUtils.limitDecimals(wavefront, 4)
        : null;
    } catch (e) {
      if (this.props.onlyAmplitude) {
        this.setState({
          error:
            "amplitude should be of datatype ‘float’, we suspect this issue arises from using complex values and suggest to end with amplitude=np.abs(amplitude) to solve this issue"
        });
      } else {
        try {
          HelperUtils.limitDecimals(wavefront[1], 4);
        } catch (e) {
          this.setState({
            error:
              "phase should be of datatype ‘float’, we suspect this issue arises from using complex values and suggest to end with phase=np.abs(phase) to solve this issue"
          });
        }
        try {
          HelperUtils.limitDecimals(wavefront[0], 4);
        } catch (e) {
          this.setState({
            error:
              "amplitude should be of datatype ‘float’, we suspect this issue arises from using complex values and suggest to end with amplitude=np.abs(amplitude) to solve this issue"
          });
        }
      }
    }

    const width = wfWidth ? wfWidth : componentWidth;
    const height = wfHeight ? wfHeight : componentHeight;

    const mWidth = DesignTargetHelper.getComparableDimension(
      componentWidth,
      componentUnit
    );

    const mHeight = DesignTargetHelper.getComparableDimension(
      componentHeight,
      componentUnit
    );

    const mCellWidth = DesignTargetHelper.getComparableDimension(
      cellWidth,
      cellUnit
    );

    const mCellHeight = DesignTargetHelper.getComparableDimension(
      cellHeight,
      cellUnit
    );

    const totalMetaCells = (mWidth * mHeight) / mCellWidth / mCellHeight;
    const isLargerMetaCells = totalMetaCells > 4000000 ? true : false;

    return (
      <UnselfishDialog
        open={open}
        onClose={this.handleClose}
        fullScreen={!this.props.onlyAmplitude}
        maxWidth={this.props.onlyAmplitude ? "lg" : undefined}
        onEnter={() => {}}
      >
        <DialogTitle
          onClose={this.handleClose}
          disableTypography={true}
          className={classes.dialogTitleBar}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                test-data="closeBtn"
                edge="start"
                color="inherit"
                onClick={this.handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent>
          <div style={{ overflow: "hidden" }}>
            <InputLabel style={{ fontSize: 12 }}>Samples</InputLabel>
            <DrilldownInput
              marginTop={0}
              float={"left"}
              value={
                selectedSampleId
                  ? sampleScripts.find(sample => sample.id === selectedSampleId)
                      .name
                  : null
              }
              options={this.getDrilldownOptions()}
              onSelect={this.onSampleSelect}
              showCount={false}
            />
          </div>
          <div style={{ width: this.props.onlyAmplitude ? 1000 : undefined }}>
            <Grid container spacing={2}>
              <Grid item xs={this.props.onlyAmplitude ? 6 : 4}>
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: this.props.fixedShape ? 20 : undefined
                  }}
                >
                  {this.props.fixedShape && (
                    <>
                      <Typography
                        test-data={"componentSize"}
                        variant="subtitle1"
                        component="h4"
                      >
                        {`Meta component size: ${componentWidth}${componentUnit} x ${componentHeight}${componentUnit}`}
                      </Typography>
                      <Typography
                        test-data={"cellSize"}
                        variant="subtitle1"
                        component="h4"
                      >
                        {`Meta cell size: ${cellWidth}${cellUnit} x ${cellHeight}${cellUnit}`}
                      </Typography>
                      <Typography
                        test-data={"matrixExplanation"}
                        variant="caption"
                      >
                        {`There are two predefined variables based on the meta component's shape: x and y.
                      They are matrices that contain as many values as the number of meta cells in the meta component.
                      Every row in the matrix represented by x goes from -${componentWidth /
                        2} to ${componentWidth / 2} whereas every column 
                      in the matrix represented by y goes from -${componentHeight /
                        2} to ${componentHeight / 2}.`}
                      </Typography>
                    </>
                  )}
                  <>
                    <Typography test-data={"asyncTitle"} variant="subtitle1">
                      {"General instructions:"}
                    </Typography>
                    <Typography
                      test-data={"asyncExplanation1"}
                      variant="caption"
                    >
                      {"Import statement(s) are not allowed."}
                    </Typography>
                    <br />
                    <Typography
                      test-data={"asyncExplanation2"}
                      variant="caption"
                    >
                      {
                        "Inbuilt modules such as itertools and math as well as numpy (as np) and scipy are already imported and ready to use."
                      }
                    </Typography>
                    <br />
                    <Typography
                      test-data={"asyncExplanation4"}
                      variant="caption"
                    >
                      {
                        "Functions should be declared as global before definition for generating wavefront. For example, if you are using a function named 'my_function', declare it as 'global my_function' before defining it with 'def my_function'."
                      }
                    </Typography>
                    <br />
                    <Typography
                      test-data={"asyncExplanation3"}
                      variant="caption"
                    >
                      {"Preview for larger components may freeze your browser."}
                    </Typography>
                  </>
                </div>
                <CodeEditor
                  script={
                    selectedSampleId
                      ? sampleScripts.find(({ id }) => id === selectedSampleId)
                          .script
                      : script2Ds.byId[scriptId].script
                  }
                  updateCode={this.updateCode}
                />
              </Grid>
              {limitedWavefront && (
                <Grid item xs={this.props.onlyAmplitude ? 6 : 8}>
                  {this.props.onlyAmplitude ? (
                    <GenericWavefront
                      hidePhase
                      wavefront={limitedWavefront}
                      wfWidth={width}
                      wfHeight={height}
                      unit={this.props.wfUnit}
                    />
                  ) : (
                    <Wavefront
                      wavefront={limitedWavefront}
                      wfWidth={width}
                      wfHeight={height}
                      unit={this.props.wfUnit}
                      showLegend
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </div>
        </DialogContent>
        <Actions
          scriptRunning={scriptRunning}
          error={error}
          disableSave={disableSave}
          wavefront={wavefront}
          handleClose={this.handleClose}
          handleConfirm={this.handleConfirm}
          generate={this.generate}
          isLargerMetaCells={isLargerMetaCells}
          generateAsync={
            this.props.onAsyncGenerate ? this.generateServerSide : null
          }
          bindActionCodeUpdater={this.bindActionCodeUpdater}
        />
        {this.state.snackbar.visible && (
          <DirectionSnackbar message={this.state.snackbar.message} />
        )}
      </UnselfishDialog>
    );
  };
}

const mapStateToProps = state => ({
  script2Ds: Script2DSelector.getScript2Ds(state)
});

const mapDispatchToProps = dispatch => {
  return {
    updateScript: (id, properties) =>
      dispatch(Script2DApi.updateScript2D(id, properties))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(WavefrontGenerator)));
